import React, { Component } from "react";

import DashboardTimeline from "./DashboardTimeline";
import DashboardCharts from "./DashboardCharts";

import "./Dashboard.css";
import { Skeleton } from "@mui/material";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conditions: [],
      procedures: [],
    };
  }

  getConditions(conditions) {
    let condArray = [];
    for (let i = 0; i < conditions.length; i++) {
      if (!condArray.includes(conditions[i].DESCRIPTION)) {
        condArray.push(conditions[i].DESCRIPTION);
      }
    }

    this.setState({ conditions: condArray });
  }

  getProcedures(conditions) {
    let condArray = [];
    for (let i = 0; i < conditions.length; i++) {
      if (
        !condArray.includes(
          conditions[i].DESCRIPTION.replace(" (procedure)", "")
        )
      ) {
        condArray.push(conditions[i].DESCRIPTION.replace(" (procedure)", ""));
      }
    }

    this.setState({ procedures: condArray });
  }

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return "-";
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data &&
      this.props.data.conditions !== undefined
    ) {
      this.getConditions(this.props.data.conditions.data);
    }

    if (
      prevProps.data !== this.props.data &&
      this.props.data.procedures !== undefined
    ) {
      this.getProcedures(this.props.data.procedures.data);
    }
  }

  componentDidMount() {
    this.props.whichLocationWhenMounted();
    if (this.props.data.conditions !== undefined) {
      this.getConditions(this.props.data.conditions.data);
    }

    if (this.props.data.procedures !== undefined) {
      this.getProcedures(this.props.data.procedures.data);
    }
  }

  render() {
    const styleList = {
      fontWeight: 400,
    };

    var showPatData = false;

    if (this.props.data.patient !== undefined) {
      if (Object.keys(this.props.data.patient.data).length !== 0) {
        showPatData = true;
      }
    }

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          overflow: "hidden",
          height: "82vh",
        }}
      >
        <div style={{ width: "60%", height: "100%", overflow: "hidden" }}>
          <div
            className="box-dashboard"
            style={{
              height: "57%",
              marginBottom: "1%",
              textAlign: "left",
              padding: "15px",
              overflowY: "auto",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ minWidth: "24vw" }}>
                <p style={{ fontSize: "15px", color: "var(--primary-color)" }}>
                  Patient Info
                </p>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    paddingLeft: "5px",
                  }}
                >
                  <ul>
                    <li>
                      <span style={styleList}>Name:</span>{" "}
                      {showPatData && this.props.data.patient.data.FIRST},{" "}
                      {showPatData && this.props.data.patient.data.LAST}
                    </li>
                    <li>
                      <span style={styleList}>Gender:</span>{" "}
                      {showPatData && this.props.data.patient.data.GENDER}
                    </li>
                    <li>
                      <span style={styleList}>Birthday:</span>{" "}
                      {showPatData &&
                        this.formatDate(this.props.data.patient.data.BIRTHDATE)}
                    </li>
                    <li>
                      <span style={styleList}>Deathday:</span>{" "}
                      {showPatData &&
                        this.formatDate(this.props.data.patient.data.DEATHDATE)}
                    </li>
                    <li>
                      <span style={styleList}>Age (at end of simulation):</span>{" "}
                      {showPatData && this.props.data.patient.data.AGE.years}
                    </li>
                    <li>
                      <span style={styleList}>Race:</span>{" "}
                      {showPatData && this.props.data.patient.data.RACE}
                    </li>
                    <li>
                      <span style={styleList}>Ethnicity:</span>{" "}
                      {showPatData && this.props.data.patient.data.ETHNICITY}
                    </li>
                    <li>
                      <span style={styleList}>Birthplace:</span>{" "}
                      {showPatData && this.props.data.patient.data.BIRTHPLACE}
                    </li>
                    <li>
                      <span style={styleList}>Address:</span>{" "}
                      {showPatData && this.props.data.patient.data.ADDRESS},{" "}
                      {showPatData && this.props.data.patient.data.CITY}
                    </li>
                    <li>
                      <span style={styleList}>Healthcare Expense:</span>{" "}
                      {showPatData &&
                        this.props.data.patient.data.HEALTHCARE_EXPENSES}
                    </li>
                    <li>
                      <span style={styleList}>Healthcare Coverage:</span>{" "}
                      {showPatData &&
                        this.props.data.patient.data.HEALTHCARE_COVERAGE}
                    </li>
                  </ul>
                </div>
                <div></div>
              </div>
              <div style={{ marginLeft: "15px", flexGrow: 1 }}>
                <p style={{ fontSize: "15px", color: "var(--primary-color)" }}>
                  Conditions
                </p>
                {this.props.displayedDataProcessed ? (
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      paddingLeft: "15px",
                    }}
                  >
                    <ul style={{ listStyleType: "disc" }}>
                      {this.state.conditions.map((con) => (
                        <li>{con}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    <Skeleton height={"4vh"} />
                    <Skeleton height={"4vh"} />
                    <Skeleton height={"4vh"} />
                    <Skeleton height={"4vh"} />
                    <Skeleton height={"4vh"} />
                    <Skeleton height={"4vh"} />
                  </>
                )}
              </div>
            </div>
            <p
              style={{
                fontSize: "15px",
                color: "var(--primary-color)",
                marginTop: "10px",
              }}
            >
              Procedures
            </p>
            {this.props.displayedDataProcessed ? (
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 300,
                  paddingLeft: "5px",
                }}
              >
                {this.state.procedures.join(", ")}
              </div>
            ) : (
              <>
                <Skeleton height={"4vh"} />
                <Skeleton height={"4vh"} />
                <Skeleton height={"4vh"} />
              </>
            )}
          </div>
          <div
            className="box-dashboard"
            style={{ height: "40%", padding: "10px" }}
          >
            <DashboardCharts
              data={this.props.data}
              displayedDataProcessed={this.props.displayedDataProcessed}
            />
          </div>
        </div>
        <div
          className="box-dashboard"
          style={{ width: "40%", overflowY: "scroll" }}
        >
          <DashboardTimeline
            data={this.props.data}
            matchEncounter={this.props.matchEncounter}
            openEncounter={this.props.openEncounter}
            displayedDataProcessed={this.props.displayedDataProcessed}
          />
        </div>
      </div>
    );
  }
}
