import React, { Component } from "react";
import iccasLogo from "../data/iccas_logo_light_grey.png";
import nfdi4 from "../data/NFDI4DS_Logo_light_grey.png";
import { Divider } from "@mui/material";
import {
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Collapse,
  TextField,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import { Link } from "react-router-dom";

import "./Header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsedInput: false,
      open: false,
      valueSelect: "Dataset dashboard",
      showPatViewNav: false,
    };
  }

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        isCollapsedInput: !prevState.isCollapsedInput,
      }),
      () => {
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ open: true });
          }, 260);
      }
    );
  };

  handleChange = (event) => {
    var linkToNavigate = "";
    if (event.target.value === "Dataset dashboard") {
      linkToNavigate = "/syn-viewer/overview-dataset";
    } else if (event.target.value === "Patient records") {
      linkToNavigate = "/syn-viewer/patient-records/overview";
    } else if (event.target.value === "Population analysis") {
      linkToNavigate = "/syn-viewer/population-analysis";
    }

    this.props.navigate(linkToNavigate);
    this.setState({ valueSelect: event.target.value, open: false });
  };

  onBlur = () => {
    this.setState({ open: false, isCollapsedInput: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, isCollapsedInput: true });
    document.getElementById("select-view").blur();
  };

  whichPage(navItem) {
    if (navItem === this.props.location.pathname) {
      return "header-navigation-item-active";
    } else {
      return "header-navigation-item";
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      const parts = this.props.location.pathname.split("/");
      var newView = "";
      var showPatViewNav = false;

      if (parts.includes("overview-dataset")) {
        newView = "Dataset dashboard";
      } else if (parts.includes("patient-records")) {
        newView = "Patient records";
      } else if (parts.includes("population-analysis")) {
        newView = "Population analysis";
      }

      if (parts.includes("patient-view")) {
        showPatViewNav = true;
      }

      this.setState({ valueSelect: newView, showPatViewNav: showPatViewNav });
    }
  }

  componentDidMount() {
    const parts = this.props.location.pathname.split("/");
    var newView = "";
    var showPatViewNav = false;

    if (parts.includes("overview-dataset")) {
      newView = "Dataset dashboard";
    } else if (parts.includes("patient-records")) {
      newView = "Patient records";
    } else if (parts.includes("population-analysis")) {
      newView = "Population analysis";
    }

    if (parts.includes("patient-view")) {
      showPatViewNav = true;
    }

    this.setState({ valueSelect: newView, showPatViewNav: showPatViewNav });
  }

  render() {
    return (
      <div className="header-main-box">
        <div
          style={{
            display: "flex",
            height: "8vh",
            alignItems: "center",
            paddingLeft: "0.2vw",
          }}
        >
          <Link to="/" className="link-header" style={{ cursor: "pointer" }}>
            <div className="flexItem">SV</div>
            <Collapse
              orientation="vertical"
              in={this.props.sHeaderDisplay === "standard" ? true : false}
            >
              <Collapse
                orientation="horizontal"
                in={this.props.sHeaderDisplay === "standard" ? true : false}
              >
                <p
                  style={{
                    marginLeft: "0.4vw",
                    padding: 0,
                    fontSize: "14px",
                    fontWeight: 320,
                  }}
                >
                  {this.props.openDB}
                </p>
              </Collapse>
            </Collapse>
          </Link>
          <Divider
            flexItem
            orientation="vertical"
            variant="middle"
            sx={{
              backgroundColor: "white",
              marginLeft: "1vw",
            }}
          />

          <div className="openDatabase">
            <Collapse
              orientation="horizontal"
              in={this.props.sHeaderDisplay === "dense" ? true : false}
            >
              <p style={{ margin: "0", padding: 0 }}>{this.props.openDB}</p>
              <FormControl variant="standard" fullWidth>
                <Select
                  id="select-view"
                  value={this.state.valueSelect}
                  open={this.state.open} // Öffne das Dropdown-Menü basierend auf dem Zustand
                  onClose={this.handleClose} // Schließe das Dropdown-Menü, wenn es geschlossen wird
                  onOpen={this.handleOpen}
                  onChange={this.handleChange}
                  onBlur={this.onBlur}
                  disableUnderline
                  bluronselect="true"
                  sx={{
                    marginTop: "4px",
                    height: "20px", // Ändere die Mindesthöhe nach Bedarf
                    fontSize: "16px",
                    width: "fit-content",
                    "& .MuiSelect-icon": {
                      color: "white", // Farbe des Icons
                    },
                    "& .MuiSelect-select": {
                      borderBottom: "none", // Entfernt die untere Linie
                      color: "white", // Farbe des Texts
                      padding: 0,
                    },
                  }}
                >
                  <MenuItem
                    value="Dataset dashboard"
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Dataset dashboard
                  </MenuItem>

                  <MenuItem
                    value="Patient records"
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Patient record
                  </MenuItem>

                  <MenuItem
                    value="Population analysis"
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Population analysis
                  </MenuItem>
                </Select>
              </FormControl>
            </Collapse>
          </div>
        </div>

        <Collapse
          orientation="horizontal"
          in={this.props.sHeaderDisplay === "standard" ? true : false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "25vw",
              marginTop: "0.5vh",
            }}
          >
            <Link
              to="/syn-viewer/overview-dataset"
              className={this.whichPage("/syn-viewer/overview-dataset")}
            >
              <p>Dashboard</p>
            </Link>
            <Link
              to="/syn-viewer/patient-records/overview"
              className={this.whichPage("/syn-viewer/patient-records/overview")}
            >
              <p>Patient Records</p>
            </Link>
            <Link
              to="/syn-viewer/population-analysis/pop-ana-dashboard"
              className={this.whichPage(
                "/syn-viewer/population-analysis/pop-ana-dashboard"
              )}
            >
              <p>Population Analysis</p>
            </Link>
          </div>
        </Collapse>
        <div
          style={{
            display: "flex",
            height: "8vh",
            alignItems: "center",
            paddingRight: "15px",
          }}
        >
          <Link to="/syn-viewer/module-diagram" style={{ marginRight: "15px" }}>
            <div
              className={
                this.props.location.pathname === "/syn-viewer/module-diagram"
                  ? "icon-head-active"
                  : "icon-head"
              }
            >
              <AccountTreeIcon />
            </div>
          </Link>
          <Link
            to="/syn-viewer/datasets/datasets-overview"
            style={{ marginRight: "15px" }}
          >
            <div
              className={
                this.props.location.pathname ===
                "/syn-viewer/datasets/datasets-overview"
                  ? "icon-head-active"
                  : "icon-head"
              }
            >
              <DatasetLinkedIcon />
            </div>
          </Link>

          <div
            className={
              this.props.location.pathname === "/syn-viewer/datasets/settings"
                ? "icon-head-active"
                : "icon-head"
            }
            onClick={this.props.openSettings}
            style={{ marginRight: "1vw" }}
          >
            <SettingsIcon />
          </div>

          <Divider
            flexItem
            orientation="vertical"
            variant="middle"
            sx={{
              backgroundColor: "white",
              marginRight: "0.2vw",
            }}
          />
          <img src={nfdi4} alt="" className="logo-nfdi-head" />
          <img src={iccasLogo} alt="" className="logo-iccas-head"></img>
        </div>
      </div>
    );
  }
}
