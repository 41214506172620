import React from "react";

import Papa from "papaparse";
import axios from "axios";

import patientsCsvDemo from "./data/csv/patients.csv";
import conditionsCsvDemo from "./data/csv/conditions.csv";
import encountersCsvDemo from "./data/csv/encounters.csv";
import medicationsCsvDemo from "./data/csv/medications.csv";
import observationsCsvDemo from "./data/csv/observations.csv";
import proceduresCsvDemo from "./data/csv/procedures.csv";
import allergiesCsvDemo from "./data/csv/allergies.csv";
import careplansCsvDemo from "./data/csv/careplans.csv";

import StartPage from "./StartPage";
import SyntheaView from "./SyntheaView";
import PopulationAnalysis from "./PopulationAnalysis/PopulationAnalysis";
import Overview from "./PatientRecords/Overview";
import PatientView from "./PatientRecords/PatientView";
import Datasets from "./Datasets/Datasets";
import DatasetDetails from "./Datasets/DatasetDetails";
import PatientHistory from "./PatientRecords/PatientHistory";
import PatientTimeline from "./PatientRecords/PatientTimeline";
import Charts from "./PatientRecords/Charts/Charts";
import PatientDataview from "./PatientRecords/PatientDataview";
import OverviewDatset from "./OverviewDataset/OverviewDatset";
import Dashboard from "./PatientRecords/Dashboard/Dashboard";
import Settings from "./Settings/Settings";
import PopAnaDashboard from "./PopulationAnalysis/Dashboard/PopAnaDashboard";
import SurvivalContainer from "./PopulationAnalysis/Survival/SurvivalContainer";

import { Button } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Overlay from "./Overlay/Overlay";

import { Route, Routes, Outlet } from "react-router-dom";

import { withRouter } from "./utils/withRouter";

import db from "./utils/DexieDatabase/db";
import createNewDb from "./utils/DexieDatabase/createNewDb";

import "./App.css";
import "./styles/tailwind.css"; // Importiere Tailwind CSS
import ModuleDataHandler from "./ModuleDiagram/ModuleDataHandler";
import DialogPopUp from "./DialogPopUp/DialogPopUp";
import Troubleshooting from "./Troubleshooting";
const theme = createTheme({
  typography: {
    fontFamily: "Spline Sans, sans-serif", // Setze die Schriftart für alle Komponenten
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
  },
  palette: {
    primary: {
      main: "#4361ee", // Hier kannst du die neue Primärfarbe festlegen
    },
    greyBtn: { main: "rgba(0, 0, 0, 0.54)" },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 1.0,
      //loading datastates for sub components
      loadedData: { csvFiles: { data: [], loaded: false } },
      dataSource: "indexedDB",
      savedFilesDone: [],
      parseLoadingStatus: 0,
      parsedSize: 0,
      addingDataStatus: 0,
      filesFullLoaded: [], //[{name: ..., loaded: ...}]
      savingLocation: "index", //index for indexedDb or mongo for mongoDB
      totalEntries: 0,
      creatingDBProgress: 0,

      //csv loading states
      csvFiles: [],
      csvDataArray: [],
      newDBName: undefined,
      csvDataObj: {},
      csvDateKeys: {},
      dataFromSaveId: "",
      openBackdrop: false,
      showPage: false,
      openPatient: {},
      patientInfoVisible: false,
      demoDatasetLoaded: true,
      demoDatasetLoadingMessage: "",
      loadingProgressSimple: {
        paraseCsvDone: true,
        creatingDBDone: true,
        writingInDBDone: true,
      },

      //PatOverview component States
      numbPatients: 0,
      overviewData: [],
      overviewDataLoaded: false,
      fileList: [],
      fileListLoaded: false,
      boringDiagnoses: [
        160968000, 224299000, 741062008, 73595000, 706893006, 160903007,
        160904001, 422650009, 224295006, 424393004, 423315002, 80583007,
        5251000175109, 266934004, 713458007, 73438004, 266948004, 361055000,
        105531004, 446654005,
      ],
      filterOverview: {},
      filteredIdsO: {},
      filterLoading: false,
      searchValue: "",
      allPatOverviewData: [],
      loadingMessagePatOverview: "",
      loadingTimePatOverviewStart: null,
      loadingTimePatOverviewDuration: 0,
      overviewRequestId: 0,

      //PatientView component states
      patientViewData: {}, //contains all data of pat
      allPatDatLoaded: {}, // contains the information if all data for pat is loaded
      displayedData: {}, // contains all data of pat that is supposed to be displayed -> filtered
      displayedDataProcessed: false,
      clickedEncounter: "",
      clickedDataViewEncounter: "",
      lastOpenView: "", //important for the potion of the custom scrollbar in navigation
      patViewFilter: {}, //safes the last filter in pat view so when component gets reopened the filter loads
      collapsesSave: { nav: false, info: false }, //safes if nav and pat info are collapsed or not -> so stays the same when reopen
      emptyPat: {},
      loadingMessagePatView: "",
      loadingTimePatientStart: null,
      loadingTimePatientDuration: 0,

      //datasets component states
      detailedDbName: "",
      detailedDBLocation: "index",
      loadingProgress: 100,

      //OverviewDataset states (dashboard)
      dashboardInfo: {},

      //open dataset states
      openDB: "",
      openDbSavingLocation: "index",
      dataStructureVersion: 0,
      updateDataStructure: false,

      //settings states
      settingsOpen: false,
      sHeaderDisplay: "standard", //"dense" or "standard"

      //snackbar states
      snackbarMessage: { variant: "", text: "" },

      //dialog states
      showDialogStates: {
        dashboard: { show: true, title: "Dashboard" },
        patientRecordOverview: { show: true, title: "Patient Records" },
        patientRecordPatView: {
          show: true,
          title: "Patient Records - Patient View",
        },
        moduleViewer: { show: true, title: "Module Viewer" },
        datasetManager: { show: true, title: "Dataset Manager" },
      },
      showDialogStatesKey: "",
      showPopUp: false,
      popUpTitle: "",

      //population analyses states
      popAnaDashboardData: {
        conditions: { data: [], labels: [], processed: false },
        procedures: { data: [], labels: [], processed: false },
        encounters: { data: [], labels: [], processed: false },
        observations: { data: [], labels: [], processed: false },
      },
      allReasonCodes: { data: [], loaded: false },

      //survival (pop ana) states
      startPointData: [],
      startPointDataLoaded: false,

      //mongodb states
      connectionToMongoDB: { trying: true, connected: false },
      mongoDatabases: { data: [], loaded: false },
      mongoBDFilesLoaded: {},
    };

    this.containerRefStartParent = React.createRef();
    this.containerRefStart = React.createRef();

    this.wsUploadMongo = null; //gives back the progress of loading data in mongo db

    this.nextPatient = this.nextPatient.bind(this);
    this.prevPatient = this.prevPatient.bind(this);
    this.openCloseBD = this.openCloseBD.bind(this);
    this.setPatientInfoVisible = this.setPatientInfoVisible.bind(this);
    this.getFilterFormOverview = this.getFilterFormOverview.bind(this);
    this.setDetailedDbName = this.setDetailedDbName.bind(this);
    this.openNewDb = this.openNewDb.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.onClickEncounter = this.onClickEncounter.bind(this);
    this.callBackCreateDB = this.callBackCreateDB.bind(this);
    this.callBackProgress = this.callBackProgress.bind(this);
    this.getAllEntries = this.getAllEntries.bind(this);
    this.parseCSVData = this.parseCSVData.bind(this);
  }

  startTiming = (key) => {
    this.setState({ [key]: new Date().getTime() });
  };

  stopTiming = (key, stateKey) => {
    const endTime = new Date().getTime();
    const startTime = this.state[key];
    if (startTime) {
      const duration = endTime - startTime;
      this.setState({
        [stateKey]: duration,
        [key]: null,
      });
    }
  };

  matchEncounter(data) {
    var matchedEncounters = [];
    var copyData = { ...data };

    if (data.encounters !== undefined) {
      for (let i = 0; i < data.encounters.data.length; i++) {
        var encounterPlus = { encounter: data.encounters.data[i] };
        var plus = {};

        for (const key in copyData) {
          if (key !== "encounters" && key !== "patient") {
            var eventsArray = [];
            for (
              let index = copyData[key].data.length - 1;
              index >= 0;
              index--
            ) {
              if (
                data.encounters.data[i].Id ===
                copyData[key].data[index].ENCOUNTER
              ) {
                eventsArray.push(copyData[key].data[index]); //<------------------------------------------------------------------mit Johannes angucken
                //copyData[key].data.splice(index, 1);
              }
            }
            plus[key] = eventsArray;
          }
        }
        encounterPlus["events"] = plus;
        matchedEncounters.push(encounterPlus);
      }
    }

    const sortedEncounters = matchedEncounters.sort((a, b) => {
      const dateA = new Date(a.encounter.START);
      const dateB = new Date(b.encounter.START);
      return dateA - dateB;
    });

    return sortedEncounters;
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Population analyses functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  getPopAnDashboardData(dbName, savingLocation) {
    const objStorages = [
      "conditions",
      "procedures",
      "encounters",
      "observations",
    ];

    for (let i = 0; i < objStorages.length; i++) {
      if (savingLocation === "index") {
        this.getPopAnDashboardDataDexie(dbName, objStorages[i]);
      } else if (savingLocation === "mongo") {
        this.getPopAnDashboardDataMongo(dbName, objStorages[i]);
      }
    }
  }

  getPopAnDashboardDataDexie(dbName, objStorage) {
    this.getFrequenciesOfIndex(dbName, objStorage, "DESCRIPTION")
      .then((frequencies) => {
        let labels = [];
        let data = [];

        for (let i = 0; i < frequencies.length; i++) {
          labels.push(this.removeSubstrings(frequencies[i][0]));
          data.push(frequencies[i][1]);
        }

        this.setState((prevState) => ({
          popAnaDashboardData: {
            ...prevState.popAnaDashboardData,
            [objStorage]: {
              data: data,
              labels: labels,
              processed: true,
            },
          },
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  removeSubstrings(input) {
    // Definiere die Substrings, die entfernt werden sollen
    if (input !== null) {
      const substringsToRemove = ["(finding)", "(procedure)", "(situation)"];

      // Iteriere über jedes Substring und ersetze es im Input-String
      substringsToRemove.forEach((substring) => {
        input = input.replace(substring, "");
      });

      // Entferne eventuelle führende oder nachgestellte Leerzeichen
      return input.trim();
    } else {
      return input;
    }
  }

  getFrequenciesOfIndex(databaseName, storeName, indexName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(databaseName);

      request.onerror = (event) => {
        reject(`Error opening database: ${event.target.errorCode}`);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        if (db.objectStoreNames.contains(storeName)) {
          const transaction = db.transaction(storeName, "readonly");
          const store = transaction.objectStore(storeName);
          const index = store.index(indexName);

          const frequencies = {};
          const cursorRequest = index.openCursor();

          cursorRequest.onerror = (event) => {
            reject(`Error opening cursor: ${event.target.errorCode}`);
          };

          cursorRequest.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
              const value = cursor.value[indexName];
              if (frequencies[value]) {
                frequencies[value]++;
              } else {
                frequencies[value] = 1;
              }
              cursor.continue();
            } else {
              // Frequencies are collected, now sort them
              const sortedFrequencies = Object.entries(frequencies).sort(
                (a, b) => b[1] - a[1]
              );
              resolve(sortedFrequencies);
            }
          };
        }
      };

      request.onupgradeneeded = (event) => {
        // Handle database upgrade if necessary
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          const objectStore = db.createObjectStore(storeName, {
            keyPath: "id",
          });
          objectStore.createIndex(indexName, indexName, { unique: false });
        }
      };
    });
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Dialog functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  dontShowAgain = () => {
    if (this.state.showDialogStates !== "") {
      this.setState(
        (prevState) => ({
          showDialogStates: {
            ...prevState.showDialogStates,
            [this.state.showDialogStatesKey]: {
              ...prevState.showDialogStates[this.state.showDialogStatesKey],
              show: false,
            },
          },
        }),
        () => {
          this.saveDialog(this.state.showDialogStates);
        }
      );
    }
  };

  saveDialog = (dialogObj) => {
    const stringObj = JSON.stringify(dialogObj);
    localStorage.setItem("dialog", stringObj);
  };

  loadDialog = () => {
    var jsonString = localStorage.getItem("dialog");
    var json;

    if (jsonString === null) {
      json = {
        dashboard: { show: true, title: "Dashboard" },
        patientRecordOverview: { show: true, title: "Patient Records" },
        patientRecordPatView: {
          show: true,
          title: "Patient Records - Patient View",
        },
        moduleViewer: { show: true, title: "Module Viewer" },
        datasetManager: { show: true, title: "Dataset Manager" },
      };
    } else {
      try {
        json = JSON.parse(jsonString);
      } catch (error) {
        console.error("Fehler beim Parsen des JSON-Strings:", error);
        json = {
          dashboard: { show: true, title: "Dashboard" },
          patientRecordOverview: { show: true, title: "Patient Records" },
          patientRecordPatView: {
            show: true,
            title: "Patient Records - Patient View",
          },
          moduleViewer: { show: true, title: "Module Viewer" },
          datasetManager: { show: true, title: "Dataset Manager" },
        };
      }
    }

    return json;
  };

  resetDialog = () => {
    const showDialogStates = {
      dashboard: { show: true, title: "Dashboard" },
      patientRecordOverview: { show: true, title: "Patient Records" },
      patientRecordPatView: {
        show: true,
        title: "Patient Records - Patient View",
      },
      moduleViewer: { show: true, title: "Module Viewer" },
      datasetManager: { show: true, title: "Dataset Manager" },
    };

    this.setState({ showDialogStates: showDialogStates });
    this.saveDialog(showDialogStates);
  };

  closeDialog = () => {
    this.setState({ showPopUp: false });
  };

  showPopUp(location) {
    var showDialogStatesKey = "";
    if (location === "/syn-viewer/overview-dataset") {
      showDialogStatesKey = "dashboard";
    } else if (location === "/syn-viewer/patient-records/overview") {
      showDialogStatesKey = "patientRecordOverview";
    } else if (
      location ===
        "/syn-viewer/patient-records/patient-view/patient-dashboard" ||
      location ===
        "/syn-viewer/patient-records/patient-view/patient-dataview" ||
      location === "/syn-viewer/patient-records/patient-view/patient-history" ||
      location ===
        "/syn-viewer/patient-records/patient-view/patient-timeline" ||
      location === "/syn-viewer/patient-records/patient-view/patient-charts"
    ) {
      showDialogStatesKey = "patientRecordPatView";
    } else if (location === "/syn-viewer/module-diagram") {
      showDialogStatesKey = "moduleViewer";
    } else if (location === "/syn-viewer/datasets/datasets-overview") {
      showDialogStatesKey = "datasetManager";
    }
    if (showDialogStatesKey !== "") {
      if (this.state.showDialogStates[showDialogStatesKey].show) {
        this.setState((prevState) => ({
          showDialogStates: {
            ...prevState.showDialogStates,
            [showDialogStatesKey]: {
              ...prevState.showDialogStates[showDialogStatesKey],
              show: false,
            },
          },
          showPopUp: true,
          popUpTitle:
            this.state.showDialogStates[showDialogStatesKey].title +
            " information",
          showDialogStatesKey: showDialogStatesKey,
        }));
      }
    }
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Snackbar functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  newSnackbar = (variant, text) => {
    this.setState({ snackbarMessage: { variant: variant, text: text } });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Scroll functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  smoothScrollTo(element, to, duration) {
    const start = element.scrollTop;
    const difference = to - start;
    const startTime = performance.now();

    function scroll(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);
      element.scrollTop = start + difference * progress;

      if (currentTime < duration) {
        window.requestAnimationFrame(scroll);
      }
    }

    window.requestAnimationFrame(scroll);
  }

  scrollToTop = () => {
    if (
      this.containerRefStart !== undefined &&
      this.containerRefStartParent !== undefined
    ) {
      const child = this.containerRefStart.current;
      const parent = this.containerRefStartParent.current;
      if (parent !== null) {
        const parentRect = parent.getBoundingClientRect();
        const parentViewableArea = {
          height: parent.clientHeight,
          width: parent.clientWidth,
        };

        const childRect = child.getBoundingClientRect();
        const isViewable =
          childRect.top >= parentRect.top &&
          childRect.bottom <= parentRect.top + parentViewableArea.height;

        if (!isViewable) {
          const scrollTop = childRect.top - parentRect.top;
          const scrollBot = childRect.bottom - parentRect.bottom;
          const scrollAmount =
            Math.abs(scrollTop) < Math.abs(scrollBot) ? scrollTop : scrollBot;

          this.smoothScrollTo(parent, parent.scrollTop + scrollAmount, 400); // Hier 500 ist die Dauer der Scrollbewegung in Millisekunden
        }
      }
    }
  };

  scrollToTopFast = () => {
    if (
      this.containerRefStart !== undefined &&
      this.containerRefStartParent !== undefined
    ) {
      const child = this.containerRefStart.current;
      const parent = this.containerRefStartParent.current;
      if (parent !== null) {
        // Where is the parent on page
        var parentRect = parent.getBoundingClientRect();
        // What can you see?
        var parentViewableArea = {
          height: parent.clientHeight,
          width: parent.clientWidth,
        };

        // Where is the child
        var childRect = child.getBoundingClientRect();
        // Is the child viewable?
        var isViewable =
          childRect.top >= parentRect.top &&
          childRect.bottom <= parentRect.top + parentViewableArea.height;

        // if you can't see the child try to scroll parent
        if (!isViewable) {
          // Should we scroll using top or bottom? Find the smaller ABS adjustment
          const scrollTop = childRect.top - parentRect.top;
          const scrollBot = childRect.bottom - parentRect.bottom;
          if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
            // we're near the top of the list
            parent.scrollTop += scrollTop;
          } else {
            // we're near the bottom of the list
            parent.scrollTop += scrollBot;
          }
        }
      }
    }
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Settings functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  openSettings = () => {
    this.setState((prevState) => ({
      settingsOpen: !prevState.settingsOpen,
    }));
  };

  changeSHeaderDisplay = (settings) => {
    this.setState({ sHeaderDisplay: settings });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------DATASETS functions------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  setDetailedDbName = (dbName, detailedDBLocation) => {
    this.setState({
      detailedDbName: dbName,
      detailedDBLocation: detailedDBLocation,
    });
  };

  openNewDb = (dbName, openDbSavingLocation) => {
    this.setState(
      {
        openDB: dbName,
        openDbSavingLocation: openDbSavingLocation,
      },
      () => {
        this.props.navigate("/syn-viewer/overview-dataset");
      }
    );
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------PATIENT VIEW functions------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  setOpenPatient = (patient) => {
    //sets open patient as state and saves the pat in local storage so when page is reloaded the patient can be opend again

    const localPatFile = patient.JSONFile;
    localPatFile["database"] = this.state.openDB;
    const stringPat = JSON.stringify(localPatFile);

    localStorage.setItem("lastOpenPatient", stringPat);

    var link = "/syn-viewer/patient-records/patient-view/patient-dashboard";

    if (this.state.lastOpenView !== "") {
      link = this.state.lastOpenView;
    }

    this.setState({ openPatient: patient.JSONFile }, this.props.navigate(link));
  };

  setPatientInfoVisible = (visible) => {
    this.setState({ patientInfoVisible: visible });
  };

  preparePatientHistory(openPatient1, fileList, dbName, savingLocation) {
    //gets all entries from all files that can be matched to the opened pat -> is needed to display patientView
    //start timing
    this.startTiming("loadingTimePatientStart");

    //set the allLoaded State to false and empty patView state
    this.setState(
      {
        allPatDatLoaded: this.emptyPatLoadingObject(fileList),
        displayedDataProcessed: false,
        patientViewData: this.state.emptyPat,
        displayedData: this.state.emptyPat,
        loadingMessagePatView:
          "Loading patient '" +
          openPatient1.LAST.replace(/\d+/g, "") +
          ", " +
          openPatient1.FIRST.replace(/\d+/g, "") +
          "'",
        loadingTimePatientDuration: 0,
      },
      () => {
        var openPatient = structuredClone(openPatient1);
        delete openPatient.CONDITIONS;

        for (const key in fileList) {
          if (key === "patient") {
            this.setState(
              (prevState) => ({
                patientViewData: {
                  ...prevState.patientViewData,
                  [key]: { typ: key, data: openPatient },
                },
              }),
              () => {
                this.setState((prevState) => ({
                  allPatDatLoaded: {
                    ...prevState.allPatDatLoaded,
                    [key]: true,
                  },
                }));
              }
            );
          } else {
            if (savingLocation === "index") {
              this.patHistoryDexie(dbName, key, openPatient.Id);
            } else if (savingLocation === "mongo") {
              this.patHistoryMongo(dbName, key, openPatient.Id);
            }
          }
        }
      }
    );
  }

  patHistoryDexie(dbName, file, patID) {
    const dbAll = new db(dbName);
    dbAll
      .getEntriesByIndex(file, "PATIENT", patID)
      .then((results) => {
        this.setState(
          (prevState) => ({
            patientViewData: {
              ...prevState.patientViewData,
              [file]: { typ: file, data: results },
            },
          }),
          () => {
            this.setState((prevState) => ({
              allPatDatLoaded: {
                ...prevState.allPatDatLoaded,
                [file]: true,
              },
            }));
          }
        );
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  }
  setDisplayDataProcessed = (trueOrFalse) => {
    this.setState((prevState) => ({
      displayedDataProcessed: trueOrFalse,
    }));
  };

  prevPatient = () => {
    this.setState({ showPage: false });
    var indexOfPatient = this.state.overviewData.findIndex(
      (o) => o.JSONFile.Id === this.state.openPatient.Id
    );

    var newIndex = 0;
    if (indexOfPatient > 0) {
      newIndex = indexOfPatient - 1;
    } else {
      return;
    }

    const newOpenPatient = this.state.overviewData[newIndex];
    this.setState({ openPatient: newOpenPatient.JSONFile }, () => {
      this.setState({ showPage: true });
    });
  };

  nextPatient = () => {
    this.setState({ showPage: false });
    var indexOfPatient = this.state.overviewData.findIndex(
      (o) => o.JSONFile.Id === this.state.openPatient.Id
    );

    var newIndex = 0;
    if (indexOfPatient < this.state.overviewData.length - 1) {
      newIndex = indexOfPatient + 1;
    } else {
      return;
    }

    const newOpenPatient = this.state.overviewData[newIndex];
    this.setState({ openPatient: newOpenPatient.JSONFile }, () => {
      this.setState({ showPage: true });
    });
  };

  getFilteredPatData = (filteredData) => {
    this.setState({ displayedData: filteredData }, () => {
      this.stopTiming("loadingTimePatientStart", "loadingTimePatientDuration");
      this.setState({
        displayedDataProcessed: true,
        loadingMessagePatView:
          "'" +
          this.state.openPatient.LAST.replace(/\d+/g, "") +
          ", " +
          this.state.openPatient.FIRST.replace(/\d+/g, "") +
          "' " +
          " loading time:",
      });
    });
  };

  onClickEncounter = (encounterId) => {
    this.setState(
      {
        lastOpenView:
          "/syn-viewer/patient-records/patient-view/patient-history",
        clickedEncounter: encounterId,
      },
      () => {
        this.props.navigate(
          "/syn-viewer/patient-records/patient-view/patient-history"
        );
      }
    );
  };

  resetClickedEncounter = () => {
    this.setState({ clickedEncounter: "" });
  };

  setDataViewEncounterNull = () => {
    this.setState({ clickedDataViewEncounter: "" });
  };

  whichLocationWhenMounted = () => {
    this.setState({ lastOpenView: this.props.location.pathname });
  };

  setPatViewFilter = (state) => {
    this.setState({ patViewFilter: state });
  };

  emptyPatLoadingObject = (fileList) => {
    var emptyObj = {};

    for (const key in fileList) {
      emptyObj[key] = false;
    }

    return emptyObj;
  };

  changePatViewCollapses = (collapses) => {
    this.setState({ collapsesSave: collapses });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------Overview functions---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  prepareDataForOverview(displayedPatientList, conditions) {
    //adds to every patient object a key "CONDITIONS" that show all conditions of a patient, as long as they are not in boring diagnosis

    if (conditions !== undefined) {
      var copyConditions = [...conditions];

      for (let i = displayedPatientList.length - 1; i >= 0; i--) {
        var conditionsOfPatient = [];
        for (let j = copyConditions.length - 1; j >= 0; j--) {
          var isConditionBoring = this.state.boringDiagnoses.findIndex(
            (o) => o === copyConditions[j].CODE
          );
          if (isConditionBoring === -1) {
            if (displayedPatientList[i].Id === copyConditions[j].PATIENT) {
              conditionsOfPatient.push(copyConditions[j]);
              copyConditions.splice(j, 1); // Entferne das Element aus copyConditions
            }
          } else {
            copyConditions.splice(j, 1);
          }
        }

        displayedPatientList[i]["CONDITIONS"] = conditionsOfPatient;
      }
    }

    return displayedPatientList;
  }

  overviewSearchValue = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  searchObjectsByTerm(searchTerm, arrayOfObjects) {
    return arrayOfObjects.filter((obj) => {
      return Object.values(obj).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        } else if (typeof value === "number") {
          return value.toString().includes(searchTerm);
        } else if (value instanceof Date) {
          const date = value; // Erstelle ein Date-Objekt (kann auch ein vorhandenes sein)

          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Monat von 0 bis 11, daher +1
          const day = String(date.getDate()).padStart(2, "0");

          const dateString = `${year}-${month}-${day}`;
          return dateString.includes(searchTerm);
        }
        return false;
      });
    });
  }

  returnConditionStr(conditions) {
    const noOfConditions = 4;
    let displayedConditionsArray = [];
    var displayedConditions = "";
    var noOfDisplayedConditions = 0;

    for (let i = 0; i < conditions.length; i++) {
      if (noOfDisplayedConditions <= noOfConditions) {
        var string1 = conditions[i].DESCRIPTION;
        var code = conditions[i].CODE;

        if (noOfDisplayedConditions === 0) {
          if (!this.state.boringDiagnoses.includes(code)) {
            displayedConditionsArray.push(string1.replace("(finding)", ""));
            noOfDisplayedConditions++;
          }
        } else {
          if (
            !displayedConditionsArray.includes(
              string1.replace("(finding)", "")
            ) &&
            !this.state.boringDiagnoses.includes(code)
          ) {
            displayedConditionsArray.push(string1.replace("(finding)", ""));

            noOfDisplayedConditions++;
          }
        }
      }
    }

    displayedConditions =
      displayedConditionsArray.join(", ") +
      "+ " +
      (conditions.length - displayedConditionsArray.length) +
      " more";
    if (noOfDisplayedConditions === 0) {
      if (conditions.length > 0) {
        return (
          conditions[0].DESCRIPTION.replace("(finding)", "") +
          "+ " +
          (conditions.length - 1) +
          " more"
        );
      } else {
        return "-";
      }
    } else {
      return displayedConditions;
    }
  }

  setPatOverViewState(patients) {
    var overviewArray = [];
    for (let i = 0; i < patients.length; i++) {
      var pat = {
        Id: patients[i].Id,
        Name: patients[i].LAST + ", " + patients[i].FIRST,
        Gender: patients[i].GENDER,
        Birthdate: patients[i].BIRTHDATE,
        Deathdate: patients[i].DEATHDATE,
        Age: patients[i].AGE,
        State: patients[i].STATE,
        ImportantDiagnoses: this.returnConditionStr(patients[i].CONDITIONS),
        JSONFile: patients[i],
      };
      overviewArray.push(pat);
    }

    this.setState(
      {
        allPatOverviewData: overviewArray,
        numbPatients: patients.length,
        overviewData: overviewArray,
      },
      () => {
        this.stopTiming(
          "loadingTimePatOverviewStart",
          "loadingTimePatOverviewDuration"
        );
        this.setState({
          overviewDataLoaded: true,
          filterLoading: false,
          loadingMessagePatOverview: "database filter request time:",
        });
      }
    );
  }

  getOverviewDataFromDB(dbName, savingLocation) {
    //gathers all patient data needed to display initial overvie component and saves them in state
    this.startTiming("loadingTimePatOverviewStart");
    this.setState({
      loadingMessagePatOverview: "filtering data",
      loadingTimePatOverviewDuration: null,
    });
    if (savingLocation === "index") {
      this.getOverviewDataFromDBDexie(dbName);
    } else if (savingLocation === "mongo") {
      this.getOverviewDataFromDBMongo(dbName, "patients");
    }
  }

  getOverviewDataFromDBDexie(dbName) {
    const dbAll = new db(dbName, "patients");
    dbAll
      .getAllEntries()
      .then((patients) => {
        this.setPatOverViewState(patients);
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  }

  emptyPatientViewDataObject = (list) => {
    let emptyPatViewObj = {};

    for (let i = 0; i < list.length; i++) {
      let filename = list[i].name;
      let data = [];

      if (filename === "patients") {
        filename = "patient";
        data = {};
      }

      emptyPatViewObj[filename] = {
        typ: filename,
        data: data,
      };
    }

    return emptyPatViewObj;
  };

  getFileList(dbName, savingLocation) {
    //gets all needed data for overview component (expect the patient data) and saves them in state
    //also gets an empty patientView object with the saved files
    this.setState({ fileListLoaded: false }, () => {
      if (savingLocation === "index") {
        this.getFileListDexie(dbName);
      } else if (savingLocation === "mongo") {
        this.getFileListMongo(dbName);
      }
    });
  }

  getFileListDexie(dbName) {
    const dbAll = new db(dbName);
    dbAll
      .listTablesAndIndexes()
      .then((list) => {
        const emptyPatViewObj = this.emptyPatientViewDataObject(list);
        this.setState(
          {
            fileList: list,
            patientViewData: emptyPatViewObj,
            displayedData: emptyPatViewObj,
            emptyPat: emptyPatViewObj,
            allPatDatLoaded: this.emptyPatLoadingObject(list),
            displayedDataProcessed: false,
          },
          () => {
            //load all reasoncodes and thier frequency
            this.loadReasonCodes(this.state.openDB, list);
            this.setState({ fileListLoaded: true });
          }
        );
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  }

  loadReasonCodes(openDB, list) {
    this.setState({ allReasonCodes: { data: [], loaded: false } }, () => {
      let tablesWIthReason = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].indices.includes("REASONCODE")) {
          tablesWIthReason.push(list[i].name);
        }
      }
      const dbAll = new db(openDB);
      dbAll.countReasonCodes(tablesWIthReason).then((results) => {
        this.setState({ allReasonCodes: { data: results, loaded: true } });
      });
    });
  }

  /*----------------------------------- Overview filter functions (form locale storage)----------------------------------------------------------------------------------------------------------------------------- */

  getFilterFormOverview(newFilter) {
    // gets filter from overview and saves it in state
    if (!this.areFilterEqual(newFilter, this.state.filterOverview)) {
      this.setState({ filterOverview: newFilter, searchValue: "" });
    }
  }

  areFilterEqual(newF, oldF) {
    const newActive = this.returnActiveFilter(newF);
    const oldActive = this.returnActiveFilter(oldF);
    const keys1 = Object.keys(newActive);
    const keys2 = Object.keys(oldActive);
    const allKeys = keys1.concat(keys2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    // Überprüfe die Werte für jeden Schlüssel
    for (let key of keys1) {
      if (newActive[key].length !== oldActive[key].length) {
        return false;
      }
    }

    return true;
  }

  returnActiveFilter(filterFull) {
    var filter = {};

    for (const key in filterFull) {
      var activeCritInFile = [];
      for (let i = 0; i < filterFull[key].length; i++) {
        if (filterFull[key][i].checked) {
          var newCrit = structuredClone(filterFull[key][i]);
          delete newCrit.showDelete;
          activeCritInFile.push(newCrit);
        }
      }

      if (activeCritInFile.length !== 0) {
        filter[key] = activeCritInFile;
      }
    }

    return filter;
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*-----------------------------------load data------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  demoPapa = async (demoFiles) => {
    demoFiles.forEach((file) => {
      Papa.parse(file.data !== undefined ? file.data : file, {
        complete: (result) => {
          const name = file.name.replace(".csv", "");

          //convert ever date in date object
          const dataWithDate = this.checkWhereIsDate(result.data);

          this.setState((prevState) => ({
            csvDataArray: [
              ...prevState.csvDataArray,
              { name: name, data: dataWithDate.data },
            ],
            parseLoadingStatus: prevState.parseLoadingStatus + 12.5,
          }));
        },
        header: true,
        download: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    });
  };

  parseCSVData = (csvFiles, newDBName, savingLocation) => {
    console.log("start parsing '" + newDBName + "' database");
    let csvDataObjEmpty = {};
    for (let i = 0; i < csvFiles.length; i++) {
      const name = csvFiles[i].name.replace(".csv", "");
      csvDataObjEmpty[name] = [];
    }

    this.setState(
      {
        newDBName: newDBName === undefined ? "DemoDataset" : newDBName,
        csvFiles: csvFiles,
        csvDataObj: csvDataObjEmpty,
        csvDateKeys: {},
        loadingProgressSimple: {
          paraseCsvDone: false,
          creatingDBDone: false,
          writingInDBDone: false,
        },
        creatingDBProgress: 0,
        filesFullLoaded: [],
        mongoBDFilesLoaded: {},
        parseLoadingStatus: 0,
        parsedSize: 0,
        savingLocation: savingLocation,
        addingDataStatus: 0,
      },
      () => {
        const totalSize = csvFiles.reduce((sum, file) => sum + file.size, 0);

        // Benutzerdefinierte Validierung und Parsen für jede ausgewählte CSV-Datei
        csvFiles.forEach((file) => {
          const name = file.name.replace(".csv", "");
          let fileData = file.data === undefined ? file : file.data;
          this.setState(
            (prevState) => ({
              filesFullLoaded: [
                ...prevState.filesFullLoaded,
                { name: name, loaded: false }, //to display progress adding data
              ],
            }),
            () => this.splitCSV(fileData, name, totalSize)
          );
        });
      }
    );
  };

  splitCSV = async (file, filename, totalSize) => {
    const chunkSize = 5 * 1024 * 1024; // 10 MB in Bytes

    const reader = new FileReader();

    reader.onload = (event) => {
      const csvContent = event.target.result;
      const lines = csvContent.split("\n");
      const header = lines[0] + "\n";
      let currentChunk = header;
      let currentSize = new Blob([header]).size;
      let chunkIndex = 0;

      for (let i = 1; i < lines.length; i++) {
        const line = lines[i] + "\n"; // Add the newline character back
        const lineSize = new Blob([line]).size;

        if (currentSize + lineSize > chunkSize) {
          this.requestFn(currentChunk, filename, totalSize, currentSize);
          chunkIndex++;
          currentChunk = header;
          currentSize = new Blob([header]).size;
        }

        currentChunk += line;
        currentSize += lineSize;
      }

      if (currentChunk !== header) {
        this.requestFn(currentChunk, filename, totalSize, currentSize);
      }
    };

    reader.readAsText(file);
  };

  requestFn = async (chunk, filename, totalSize, chunkSize) => {
    const processNextChunk = (
      chunk,
      filename,
      totalSize,
      chunkSize,
      resolve
    ) => {
      const chunkPap = chunk;
      const filenamePap = filename;
      const totalSizePap = totalSize;
      const chuckSizePap = chunkSize;

      Papa.parse(chunkPap, {
        complete: (result) => {
          const dataWithDate = this.checkWhereIsDate(result.data).data;
          const dateKeys = this.checkWhereIsDate(result.data).allDateKeys;
          this.setState(
            (prevState) => {
              const newParsedSize = prevState.parsedSize + chuckSizePap;
              const progress = (newParsedSize / totalSizePap) * 100;
              return {
                csvDataObj: {
                  ...prevState.csvDataObj,
                  [filenamePap]: [
                    ...prevState.csvDataObj[filenamePap],
                    ...dataWithDate,
                  ],
                },
                csvDateKeys: {
                  ...prevState.csvDateKeys,
                  [filenamePap]: dateKeys,
                },
                parseLoadingStatus: progress, // to display progress parsing
                parsedSize: newParsedSize,
              };
            },
            () => {
              resolve();
            }
          );
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    };

    await new Promise((resolve) => {
      requestIdleCallback(() =>
        processNextChunk(chunk, filename, totalSize, chunkSize, resolve)
      );
    });
  };

  loadDemoFilters() {
    //load demo filter profiles for pat view
    const dataArray = [
      {
        name: "Demo - General exam",
        data: {
          includeOArray: [
            {
              label: "Heart rate - 8867-4",
              description: "Heart rate",
              code: "8867-4",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Diastolic Blood Pressure - 8462-4",
              description: "Diastolic Blood Pressure",
              code: "8462-4",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Systolic Blood Pressure - 8480-6",
              description: "Systolic Blood Pressure",
              code: "8480-6",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Body mass index (BMI) [Ratio] - 39156-5",
              description: "Body mass index (BMI) [Ratio]",
              code: "39156-5",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Body Weight - 29463-7",
              description: "Body Weight",
              code: "29463-7",
              typ: "codeDesc",
              file: "observations",
            },
            {
              label: "Body Height - 8302-2",
              description: "Body Height",
              code: "8302-2",
              typ: "codeDesc",
              file: "observations",
            },
          ],
          includeAllDataOfEncounter: false,
          excludeArray: [],
        },
      },
      {
        name: "Demo - Laryngeal Ca",
        data: {
          includeOArray: [
            {
              label: "Encounter for symptom - 185345009",
              description: "Encounter for symptom",
              code: 185345009,
              typ: "codeDesc",
              file: "encounter",
            },
            {
              label: "Encounter for problem - 185347001_1",
              description: "Encounter for problem",
              code: "185347001_1",
              typ: "codeDesc",
              file: "encounter",
            },
            {
              label: "Encounter for problem (procedure) - 185347001",
              description: "Encounter for problem (procedure)",
              code: 185347001,
              typ: "codeDesc",
              file: "encounter",
            },
          ],
          includeAllDataOfEncounter: true,
          excludeArray: [],
        },
      },
    ];

    const dataArrayString = JSON.stringify(dataArray);

    try {
      localStorage.setItem("patientView", dataArrayString);
    } catch (error) {
      if (error.name === "QuotaExceededError") {
        console.error(
          "Fehler: Der verfügbare Speicherplatz des Local Storage ist überschritten."
        );
        //show snackbar
        this.props.newSnackbar(
          "error",
          "Error: The available storage space of the Local Storage has been exceeded."
        );
      } else {
        console.error(
          "Fehler beim Schreiben in den Local Storage:",
          error.message
        );
      }
    }
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- indexedDB dexie loading functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  deleteDatabase(dbName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        resolve(`Datenbank ${dbName} wurde erfolgreich gelöscht.`);
      };
    });
  }

  deleteAllDatabases = () => {
    // Holen aller Datenbanknamen
    indexedDB
      .databases()
      .then((databases) => {
        databases.forEach((dbInfo) => {
          // Jede Datenbank einzeln löschen
          this.deleteDatabase(dbInfo.name)
            .then((message) => {
              this.props.showSnackbar(
                "info",
                "Database '" + dbInfo.name + "' was deleted."
              );
            })
            .catch((error) => {
              console.error(error);
              // Fehlerbehandlung
            });
        });

        //reload demoData

        //shows loading screen
        this.setState({
          demoDatasetLoaded: false,
          demoDatasetLoadingMessage: "Downloading Dataset",
          updateDataStructure: false,
        });

        //loaded demo-dataset -> when all loaded it will be saved in indexedDB (see componentDidUpdate)
        this.setState({
          demoDatasetLoaded: false,
          demoDatasetLoadingMessage: "Downloading Dataset",
        });

        this.props.showSnackbar(
          "info",
          "DemoDataset was not found in local storage. The dataset will now be downloaded."
        );

        this.loadDemoFilters();

        const demoCSVFiles = [
          { name: "patients", data: patientsCsvDemo },
          { name: "encounters", data: encountersCsvDemo },
          { name: "allergies", data: allergiesCsvDemo },
          { name: "careplans", data: careplansCsvDemo },
          { name: "conditions", data: conditionsCsvDemo },
          { name: "procedures", data: proceduresCsvDemo },
          { name: "medications", data: medicationsCsvDemo },
          { name: "observations", data: observationsCsvDemo },
        ];

        this.setState(
          { csvFiles: demoCSVFiles, newDBName: "DemoDataset" },
          () => {
            this.demoPapa(demoCSVFiles);
          }
        );
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Datenbanken:", error);
      });
  };

  sortByBirthdateDescending(data) {
    console.log("sortByBirthdateDescending", data);
    return data.sort((a, b) => {
      const dateA = new Date(a.STOP);
      const dateB = new Date(b.STOP);
      return dateB - dateA; // Aktuellstes Datum zuerst
    });
  }

  addAgeToData(csvData, encounterCsvData) {
    if (csvData !== undefined) {
      var youngestDateOfSimulation = new Date(
        this.sortByBirthdateDescending(encounterCsvData)[0].STOP
      );

      for (let index = 0; index < csvData.length; index++) {
        //calculate age
        var dob = new Date(csvData[index].BIRTHDATE);
        var dayOfDeath = youngestDateOfSimulation;

        if (csvData[index].DEATHDATE !== null) {
          dayOfDeath = new Date(csvData[index].DEATHDATE);
        }

        let jahre = dayOfDeath.getFullYear() - dob.getFullYear();
        let monate = dayOfDeath.getMonth() - dob.getMonth();
        let tage = dayOfDeath.getDate() - dob.getDate();

        if (tage < 0) {
          monate -= 1;
          tage += new Date(
            dayOfDeath.getFullYear(),
            dayOfDeath.getMonth(),
            0
          ).getDate();
        }

        if (monate < 0) {
          jahre -= 1;
          monate += 12;
        }

        csvData[index]["AGE"] = {
          years: jahre,
          months: jahre * 12 + monate,
          estimatedEndOfSimulation: youngestDateOfSimulation,
        };
        csvData[index]["AGESIMPLE"] = jahre;
      }
    }

    return csvData;
  }

  getSavedDataId = (id) => {
    this.setState({ dataFromSaveId: id });
  };

  getImportedDataset = (dataset) => {
    this.setState({ loadedData: dataset }, () =>
      this.setState({ showStartPage: false })
    );
  };

  openCloseBD() {
    this.state.openBackdrop
      ? this.setState({ openBackdrop: false })
      : this.setState({ openBackdrop: true });
  }

  loadData = () => {
    const dataStructureVersion = localStorage.getItem("dataStructureVersion");

    if (
      Number(dataStructureVersion) !== this.state.dataStructureVersion ||
      dataStructureVersion === null
    ) {
      //stops websites puts up pop-up
      this.setState({ updateDataStructure: true });
      return;
    }

    //load data to display everything
    this.getOverviewDataFromDB(
      this.state.openDB,
      this.state.openDbSavingLocation
    );

    this.getFileList(this.state.openDB, this.state.openDbSavingLocation);

    //get data for the dataset dashboard
    this.countEntriesInObjectStores(
      this.state.openDB,
      this.state.openDbSavingLocation
    );

    //get all data for pop ana dashboard
    this.setState(
      {
        popAnaDashboardData: {
          conditions: { data: [], labels: [], processed: false },
          procedures: { data: [], labels: [], processed: false },
          encounters: { data: [], labels: [], processed: false },
          observations: { data: [], labels: [], processed: false },
        },
      },
      () =>
        this.getPopAnDashboardData(
          this.state.openDB,
          this.state.openDbSavingLocation
        )
    );
  };

  async initialLoadDB(dbName, savingLocation) {
    this.db = new db(dbName);

    try {
      await this.db.open();
      this.setState({ openDB: dbName, openDbSavingLocation: savingLocation });
      return this.db.closeDatabase();
    } catch (error) {
      console.log("Datenbank:" + dbName + "nicht vorhanden.");
      this.props.showSnackbar(
        "info",
        "Datenbank:" +
          dbName +
          " was not found in local storage. The dataset will now be downloaded."
      );
      if (dbName === "DemoDataset") {
        this.setState({
          demoDatasetLoaded: false,
          demoDatasetLoadingMessage: "Downloading Dataset",
        });

        this.props.showSnackbar(
          "info",
          "DemoDataset was not found in local storage. The dataset will now be downloaded."
        );

        this.loadDemoFilters();

        const demoCSVFiles = [
          { name: "patients", data: patientsCsvDemo },
          { name: "encounters", data: encountersCsvDemo },
          { name: "allergies", data: allergiesCsvDemo },
          { name: "careplans", data: careplansCsvDemo },
          { name: "conditions", data: conditionsCsvDemo },
          { name: "procedures", data: proceduresCsvDemo },
          { name: "medications", data: medicationsCsvDemo },
          { name: "observations", data: observationsCsvDemo },
        ];

        this.setState(
          { csvFiles: demoCSVFiles, newDBName: "DemoDataset" },
          () => {
            this.demoPapa(demoCSVFiles);
          }
        );
      }
      return this.db.closeDatabase();
    }
  }

  checkWhereIsDate = (data) => {
    //checks which colums are dates and then alters all entreies into dates -> only shoots if papa didnt automatically alter it
    let dateKeys = [];
    let allDateKeys = [];
    let emptyColums = [];
    //allowed date formats
    const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}Z)?$/;

    //check for columns that are dates
    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        //check for dates
        for (const key in data[i]) {
          //if colum is empty push in array
          if (data[i][key] === null) {
            emptyColums.push(key);
          } else {
            if (regex.test(data[i][key])) {
              dateKeys.push(key);
              allDateKeys.push(key);
            }

            if (data[i][key] instanceof Date) {
              allDateKeys.push(key);
            }
          }
        }
      } else {
        //only shoots if in first row there is an empty column
        for (let index = emptyColums.length - 1; index >= 0; index--) {
          if (data[i][emptyColums[index]] !== null) {
            // Eintrag löschen, wenn die Bedingung zutrifft
            if (regex.test(data[i][emptyColums[index]])) {
              dateKeys.push(emptyColums[index]);
              allDateKeys.push(emptyColums[index]);
            }

            if (data[i][emptyColums[index]] instanceof Date) {
              allDateKeys.push(emptyColums[index]);
            }

            emptyColums.splice(index, 1);
          }
        }
      }

      //stops if all colums have been looked at
      if (emptyColums.length === 0) {
        break;
      }
    }

    //alters colums dates into date objs
    for (let i = 0; i < data.length; i++) {
      var dateObject = {};
      //create date objects
      for (let index = 0; index < dateKeys.length; index++) {
        if (data[i][dateKeys[index]] !== null) {
          dateObject[dateKeys[index]] = new Date(data[i][dateKeys[index]]);
        }
      }

      //exchange date string with date object
      for (let index = 0; index < dateKeys.length; index++) {
        if (dateObject[dateKeys[index]] !== undefined) {
          data[i][dateKeys[index]] = dateObject[dateKeys[index]];
        }
      }
    }

    return { data: data, allDateKeys: allDateKeys };
  };

  perpareDemoCSVData = (csvData) => {
    //safes csvData as JSON locally when all CSV-Data has been imported
    const newId = 0;
    let savedFilesNames = [];

    for (let i = 0; i < csvData.length; i++) {
      savedFilesNames.push(csvData[i].name);
    }

    const patients = csvData.find((o) => o.name === "patients");

    const date = new Date();
    const data = {
      id: newId,
      name: "DemoDataset",
      savedFiles: savedFilesNames,
      size: "",
      patients: patients.data.length,
      uploaded: date.toLocaleString(),
    };

    const CSVnamesStruck = {
      names: "++id, name, savedFiles, size, patients, uploaded",
    };

    const CsvData = { names: [data] };

    this.createNewDexieDb("CSVNames", CSVnamesStruck, CsvData);

    //safe new dataset in indexedDB
    var dataObj = {};
    var strucktureObj = {};

    for (let i = 0; i < csvData.length; i++) {
      if (csvData[i].name === "patients") {
        const conditions = csvData.find((o) => o.name === "conditions");

        const encounters = csvData.find((o) => o.name === "encounters");

        console.log(encounters);

        dataObj[csvData[i].name] = this.prepareDataForOverview(
          this.addAgeToData(csvData[i].data, encounters.data),
          conditions.data
        );
      } else {
        dataObj[csvData[i].name] = csvData[i].data;
      }

      var index = [];

      if (csvData[i].data.length !== 0) {
        index.push("++primaryKey");
        for (const key in csvData[i].data[0]) {
          index.push(key);
        }
      }

      strucktureObj[csvData[i].name] = index.join(", ");
    }

    this.createNewDexieDb("DemoDataset", strucktureObj, dataObj);
  };

  addAgeAndPrepareOverviewData = (csvData) => {
    var dataObj = {};
    var strucktureObj = {};
    var strucktureObjMongo = {};

    for (let i = 0; i < csvData.length; i++) {
      if (csvData[i].name === "patients") {
        const conditions = csvData.find((o) => o.name === "conditions");

        const encounters = csvData.find((o) => o.name === "encounters");

        dataObj[csvData[i].name] = this.prepareDataForOverview(
          this.addAgeToData(csvData[i].data, encounters.data),
          conditions.data
        );
      } else {
        dataObj[csvData[i].name] = csvData[i].data;
      }

      let index = [];
      let indexMongo = [];
      if (csvData[i].data.length !== 0) {
        index.push("++primaryKey");
        for (const key in csvData[i].data[0]) {
          index.push(key);
          indexMongo.push(key);
        }
      }

      strucktureObj[csvData[i].name] = index.join(", ");
      strucktureObjMongo[csvData[i].name] = indexMongo;
    }

    return {
      strucktureObj: strucktureObj,
      dataObj: dataObj,
      strucktureObjMongo: strucktureObjMongo,
    };
  };

  perpareCSVDataDexie = (csvData, csvFiles) => {
    console.log("perpareCSVDataDexie", csvData);
    const newId = new Date().getTime() + "-" + Math.floor(Math.random() * 1000);
    let savedFilesNames = [];
    var datasetSize = 0;
    for (let i = 0; i < csvFiles.length; i++) {
      savedFilesNames.push(csvFiles[i].name);
      datasetSize = datasetSize + csvFiles[i].size * 10 ** -6;
    }

    const patients = csvData.find((o) => o.name === "patients");

    const date = new Date();
    const data = {
      id: newId,
      name: this.state.newDBName,
      savedFiles: savedFilesNames,
      size: datasetSize,
      patients: patients.data.length,
      uploaded: date.toLocaleString(),
    };

    const CsvData = { names: [data] };

    //save dataset info in CSVNames DB
    this.addToDB("CSVNames", "names", CsvData);

    //safe new dataset in indexedDB
    const strucktureObj = this.addAgeAndPrepareOverviewData(csvData);

    this.createNewDexieDb(
      this.state.newDBName,
      strucktureObj.strucktureObj,
      strucktureObj.dataObj
    );
  };

  createNewDexieDb = (name, dbStructure, csvData) => {
    //save dataset info in CSVNames DB
    const createDb = new createNewDb(
      name,
      dbStructure,
      csvData,
      this.callBackCreateDB,
      this.callBackProgressCreatDB,
      this.callBackProgress,
      this.setFullyLoaded
    );

    //save datastructure cersion locally
    localStorage.setItem(
      "dataStructureVersion",
      this.state.dataStructureVersion
    );
  };

  callBackProgress = (progress) => {
    this.setState({ addingDataStatus: progress });
  };

  callBackCreateDB = (table, n, dbName) => {
    this.setState(
      (prevState) => ({
        savedFilesDone: [...prevState.savedFilesDone, table],
      }),
      () => {
        if (this.state.savedFilesDone.length === n && dbName !== "CSVNames") {
          var openNewDB = this.state.newDBName;
          this.setState({
            demoDatasetLoaded: true,
            demoDatasetLoadingMessage: "",
            savedFilesDone: [],
            csvFiles: [],
            csvDataObj: {},
            csvDateKeys: {},
            newDBName: "",
            openDB: openNewDB,
            openPatient: {},
            loadingProgressSimple: {
              paraseCsvDone: true,
              creatingDBDone: true,
              writingInDBDone: true,
            },
          });
          this.loadData();
        }
      }
    );
  };

  setStateLoadedData = (stateToSafe, data) => {
    this.setState((prevState) => ({
      loadedData: {
        ...prevState.loadedData,
        [stateToSafe]: { data: data, loaded: true },
      },
    }));
  };

  getAllEntries = async (dbName, objectStore, stateToSafe) => {
    const dbAll = new db(dbName, objectStore);
    dbAll
      .getAllEntries()
      .then((entries) => {
        if (stateToSafe !== undefined) {
          this.setStateLoadedData(stateToSafe, entries);
        }
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  countEntriesInObjectStores(dbName, savingLocation) {
    if (savingLocation === "index") {
      this.countEntriesInObjectStoresDexie(dbName);
    } else if (savingLocation === "mongo") {
      this.countEntriesInObjectStoresMongo(dbName);
    }
  }

  countEntriesInObjectStoresDexie(dbName) {
    const dbAll = new db(dbName);
    dbAll
      .countEntriesInTables()
      .then((entryCounts) => {
        this.setState({ dashboardInfo: entryCounts });
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  }

  addToDB = (dbName, table, data) => {
    const dbAll = new db(dbName, table);
    dbAll
      .addData(data)
      .then((entryCounts) => {
        this.setState({ dashboardInfo: entryCounts });
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  callBackProgressCreatDB = () => {
    this.setState({
      creatingDBProgress: 100,
      loadingProgressSimple: {
        paraseCsvDone: true,
        creatingDBDone: true,
        writingInDBDone: false,
      },
    });
  };

  setFullyLoaded = (fileName) => {
    this.setState((prevState) => ({
      filesFullLoaded: prevState.filesFullLoaded.map((item) =>
        item.name === fileName ? { ...item, loaded: true } : item
      ),
    }));
  };

  /*------------------------------------ dexie overview filter funktions ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  returnOperator(operator) {
    if (operator === "=") {
      return "===";
    } else if (operator === "!=") {
      return "!==";
    } else {
      return operator;
    }
  }

  returnOperatorObject(operator) {
    if (operator === "=") {
      return "equals";
    } else if (operator === "!=") {
      return "notEqual";
    } else if (operator === ">") {
      return "above";
    } else if (operator === ">=") {
      return "aboveOrEqual";
    } else if (operator === "<") {
      return "below";
    } else if (operator === "<=") {
      return "belowOrEqual";
    }
  }

  returnANDOR(operator) {
    if (operator === "and") {
      return "&&";
    } else if (operator === "or") {
      return "||";
    }
  }

  isNumericString = (str) => {
    const regex = /^\d+$/;
    return regex.test(str);
  };

  checkForString(value) {
    if (this.isNumericString(value)) {
      return parseFloat(value);
    } else if (value instanceof Date) {
      return "new Date('" + value.toISOString() + "')";
    } else {
      return "'" + value + "'";
    }
  }

  checkForAge(file, column) {
    if (file === "patients") {
      if (column === "AGE") {
        return "AGESIMPLE";
      } else {
        return column;
      }
    } else {
      return column;
    }
  }

  checkForStringObject(value) {
    if (this.isNumericString(value)) {
      return parseFloat(value);
    } else {
      return value;
    }
  }

  createConditionStr(criteriaArray, key) {
    let conditionsStr = "";

    for (let iArg = 0; iArg < criteriaArray.argumentsArray.length; iArg++) {
      let argData = criteriaArray.argumentsArray[iArg].data;

      conditionsStr += `item.${this.checkForAge(
        key,
        argData.column
      )} ${this.returnOperator(argData.operator)} ${this.checkForString(
        argData.value
      )}`;

      if (iArg < criteriaArray.argumentsArray.length - 1) {
        conditionsStr += this.returnANDOR(
          criteriaArray.operatorValue[iArg].data
        );
      }
    }

    return conditionsStr;
  }

  filterForDoublePatIds(file, result) {
    let newRes = [];
    let key = "PATIENT";

    if (file === "patients") {
      key = "Id";
    }

    for (let i = 0; i < result.length; i++) {
      if (!newRes.includes(result[i][key])) {
        newRes.push(result[i][key]);
      }
    }

    return newRes;
  }

  setStateOverviewFilterIds = (id, key, i, results, requestId) => {
    if (this.state.overviewRequestId === requestId) {
      this.setState((prevState) => ({
        filteredIdsO: {
          ...prevState.filteredIdsO,
          [key]: {
            ...prevState.filteredIdsO[key],
            [id]: {
              data: this.filterForDoublePatIds(key, results),
              loaded: true,
            },
          },
        },
      }));
    }
  };

  onlyAndOperator(filter) {
    let allAnd = true;

    for (let i = 0; i < filter.operatorValue.length; i++) {
      if (filter.operatorValue[i].data !== "and") {
        allAnd = false;
        break;
      }
    }

    return allAnd;
  }

  includesOr(filter) {
    let includesOr = false;

    for (let i = 0; i < filter.operatorValue.length; i++) {
      if (filter.operatorValue[i].data === "or") {
        includesOr = true;
        break;
      }
    }

    return includesOr;
  }

  includesBracket(filter) {
    let includesBracket = false;

    for (let i = 0; i < filter.argumentsArray.length; i++) {
      if (
        filter.argumentsArray[i].typ === "bracketStart" ||
        filter.argumentsArray[i].typ === "bracketEnd"
      ) {
        includesBracket = true;
        break;
      }
    }

    return includesBracket;
  }

  prepareSingleArg(filter, key, index) {
    filter.argumentsArray[index].data.column = this.checkForAge(
      key,
      filter.argumentsArray[index].data.column
    );
    filter.argumentsArray[index].data.operator = this.returnOperatorObject(
      filter.argumentsArray[index].data.operator
    );
    filter.argumentsArray[index].data.value = this.checkForStringObject(
      filter.argumentsArray[index].data.value
    );

    return filter;
  }

  prepareMultiArgs(filter, key) {
    const sigArgObj = structuredClone(this.prepareSingleArg(filter, key, 0));

    filter["argumentsArray"] = filter.argumentsArray.splice(1);
    const filterStrig = this.createConditionStr(filter, key);

    return { string: filterStrig, obj: sigArgObj };
  }

  prepareOrCrit(filter, key) {
    let indicesOfOr = [];
    let argumentWithStringArray = []; // {obj: {} , string: ""}

    //finde indices of or operators
    for (let i = 0; i < filter.operatorValue.length; i++) {
      if (filter.operatorValue[i].data === "or") {
        indicesOfOr.push(i);
      }
    }

    for (let i = 0; i < indicesOfOr.length; i++) {
      if (i === 0) {
        let sliceStart = 1;
        //create first arg before first "or" object and following args till "or" as string
        const argsToNextOr = filter.argumentsArray.slice(
          sliceStart,
          indicesOfOr[i] + 1
        );
        let newFilter = structuredClone(filter);
        newFilter.argumentsArray = argsToNextOr;
        const strArgs = this.createConditionStr(newFilter, key);
        const argObjString = {
          obj: this.prepareSingleArg(filter, key, 0).argumentsArray[0],
          string: strArgs,
        };
        argumentWithStringArray.push(argObjString);
      }

      // prepare first arg after "or" as obj and following args till next "or" or end as string
      if (indicesOfOr[i + 1] === undefined) {
        const argsToNextOr = filter.argumentsArray.slice(indicesOfOr[i] + 2);

        let newFilter = structuredClone(filter);
        newFilter.argumentsArray = argsToNextOr;
        const strArgs = this.createConditionStr(newFilter, key);

        let indexOfOrArg = indicesOfOr[i] + 1;
        const argObjString = {
          obj: this.prepareSingleArg(filter, key, indexOfOrArg).argumentsArray[
            indexOfOrArg
          ],
          string: strArgs,
        };
        argumentWithStringArray.push(argObjString);
      } else {
        const argsToNextOr = filter.argumentsArray.slice(
          indicesOfOr[i] + 2,
          indicesOfOr[i + 1] + 1
        );

        let newFilter = structuredClone(filter);
        newFilter.argumentsArray = argsToNextOr;
        const strArgs = this.createConditionStr(newFilter, key);

        let indexOfOrArg = indicesOfOr[i] + 1;
        const argObjString = {
          obj: this.prepareSingleArg(filter, key, indexOfOrArg).argumentsArray[
            indexOfOrArg
          ],
          string: strArgs,
        };
        argumentWithStringArray.push(argObjString);
      }
    }

    return argumentWithStringArray;
  }

  dexieFilterPatOverview = (filter, requestId) => {
    for (const key in filter) {
      for (let i = 0; i < filter[key].length; i++) {
        const dbFilter = new db(this.state.openDB, key);
        if (filter[key][i].argumentsArray.length === 1) {
          dbFilter
            .singleArgPatOverview(this.prepareSingleArg(filter[key][i], key, 0))
            .then((results) => {
              this.setStateOverviewFilterIds(
                filter[key][i].id,
                key,
                i,
                results,
                requestId
              );
              return dbFilter.closeDatabase();
            });
        } else if (this.onlyAndOperator(filter[key][i])) {
          dbFilter
            .allANDOverview(this.prepareMultiArgs(filter[key][i], key))
            .then((results) => {
              this.setStateOverviewFilterIds(
                filter[key][i].id,
                key,
                i,
                results,
                requestId
              );
              return dbFilter.closeDatabase();
            });
        } else if (this.includesBracket(filter[key][i])) {
          this.setState({ filterLoading: false });
        } else if (this.includesOr(filter[key][i])) {
          dbFilter
            .simpleOrOverview(this.prepareOrCrit(filter[key][i], key))
            .then((results) => {
              this.setStateOverviewFilterIds(
                filter[key][i].id,
                key,
                i,
                results,
                requestId
              );
              return dbFilter.closeDatabase();
            });
        }
      }
    }
  };

  getFittingPatients = (fittinigIds) => {
    let fittingIdsAll = [];

    for (const key in fittinigIds) {
      for (const keyFile in fittinigIds[key]) {
        fittingIdsAll.push(fittinigIds[key][keyFile].data);
      }
    }

    const matchedIds = fittingIdsAll.reduce((commonIds, currentArray) => {
      return commonIds.filter((id) => currentArray.includes(id));
    });

    if (this.state.openDbSavingLocation === "index") {
      this.fittingPatDexie(matchedIds);
    } else if (this.state.openDbSavingLocation === "mongo") {
      this.getFittingIdsMongo(matchedIds);
    }
  };

  fittingPatDexie = (matchedIds) => {
    const dbAll = new db(this.state.openDB, "patients");
    dbAll
      .getEntryByArray("Id", matchedIds)
      .then((patients) => {
        this.setPatOverViewState(patients);
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  getFittingIdsMongo = async (matchedIds) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/findDocumentsByArray",
        {
          dbName: this.state.openDB,
          collectionName: "patients",
          indexName: "Id",
          array: matchedIds,
        }
      );
      if (response.data.status === "success") {
        const results = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );

        this.setPatOverViewState(results);
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };
  /*------------------------------------ dexie survial functions ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  getStartPointData = (file) => {
    this.setState({ startPointDataLoaded: false }, () => {
      if (this.state.openDbSavingLocation === "index") {
        this.getStartPointDataDexie(file);
      } else if (this.state.openDbSavingLocation === "mongo") {
        this.getStartPointDataMongo(file);
      }
    });
  };

  getStartPointDataDexie = (file) => {
    const dbAll = new db(this.state.openDB);
    dbAll
      .getEntriesByIndex(file.file, file.colum, file.value)
      .then((data) => {
        this.setState({ startPointData: data, startPointDataLoaded: true });
        return dbAll.closeDatabase();
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  };

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- mongo DB loading functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  getStartPointDataMongo = async (file) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getEntriesByIndexValue",
        {
          params: {
            dbName: this.state.openDB,
            collectionName: file.file,
            indexName: file.colum,
            indexValue: file.value,
          },
        }
      );
      if (response.data.status === "success") {
        const results = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );

        this.setState({ startPointData: results, startPointDataLoaded: true });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  checkConnection = async () => {
    let connectionStatus = { trying: false, connected: false };
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/check-connection"
      ); // Proxy-Server-Port
      if (response.data.status === "success") {
        this.props.showSnackbar("success", "MongoDB connection is successful");
        connectionStatus = { trying: false, connected: true };
        console.log("MongoDB connection status:", connectionStatus);
      } else {
        this.props.showMongoReSnackbar(
          "Unanble to connect to MongoDB. Please try to reconect or use website without MongoDB."
        );
        console.log("MongoDB connection status:", connectionStatus);
      }
      this.setState({ connectionToMongoDB: connectionStatus });
    } catch (error) {
      this.setState({ connectionToMongoDB: connectionStatus });
      this.props.showMongoReSnackbar(
        "Unanble to connect to proxyserver. Can not reach MongoDB. Please try to reconect or use website without MongoDB."
      );
      console.log(
        "Unable to reach proxyserver (server.js). Please check if the server is running (npm run server)"
      );
      console.log("MongoDB connection status:", connectionStatus);
    }
  };

  perpareCSVDataMongo(csvData, csvFiles, dateKeys) {
    const strucktureObj = this.addAgeAndPrepareOverviewData(csvData);
    const dataADD = {
      databaseName: this.state.newDBName,
      collectionsData: strucktureObj.dataObj,
    };

    const dataCreate = {
      databaseName: this.state.newDBName,
      collectionsStructure: strucktureObj.strucktureObjMongo,
    };

    this.createDBMongo(dataCreate, dataADD, csvFiles, dateKeys);
  }

  createDBMongo = async (dataCreate, dataADD, csvFiles, dateKeys) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/create-db-indices",
        dataCreate
      );
      if (response.data.status === "success") {
        this.props.showSnackbar("success", "Database successfully created");
        const totalEntries = this.getTotalEntrys(dataADD.collectionsData);
        this.setState({
          loadingProgressSimple: {
            paraseCsvDone: true,
            creatingDBDone: true,
            writingInDBDone: false,
          },
          totalEntries: totalEntries,
        });
        this.splitDataForTransfere(dataADD, csvFiles, dateKeys);
      } else {
        this.props.showSnackbar("error", "Creating database failed");
      }
    } catch (error) {
      this.props.showSnackbar("error", "Error creating database");
    }
  };

  getTotalEntrys(data) {
    let totalEntrys = 0;

    for (const key in data) {
      totalEntrys += data[key].length;
    }

    return totalEntrys;
  }

  tryStringiyJson(jsonArray, key) {
    try {
      // Versuche, den aktuellen JSON-Teil zu stringifizieren
      const jsonString = JSON.stringify(jsonArray);
      // Bestimme die Größe in Bytes, wenn es funktioniert hat
      const chunkSizeInBytes = new Blob([jsonString]).size;
      return chunkSizeInBytes;
    } catch (error) {
      console.log(key, ".csv string to long need to split");
      return undefined;
    }
  }

  stringifyInChunks(key, data) {
    let jsonArray = data; // Start mit dem gesamten JSON-Array
    let jsonString;
    let jsonSizeInBytes = 0;
    let success = false;

    while (!success && jsonArray.length > 0) {
      try {
        // Versuche, den aktuellen JSON-Teil zu stringifizieren
        jsonString = JSON.stringify(jsonArray);
        success = true;
        // Bestimme die Größe in Bytes, wenn es funktioniert hat
        jsonSizeInBytes = new Blob([jsonString]).size;
        console.log(
          key,
          "Größe des Chunks in Bytes: ",
          jsonSizeInBytes,
          "Größe des gesamten JSONs (geschätzt): ",
          jsonSizeInBytes * Math.ceil(data.length / jsonArray.length)
        );
      } catch (error) {
        // Falls ein Fehler auftritt, das Array halbieren und erneut versuchen
        console.log(key, ".csv string is to long. It needed to be split.");
        jsonArray = jsonArray.slice(0, Math.ceil(jsonArray.length / 2));
      }
    }

    if (!success) {
      console.log(key, " konnte nicht verarbeitet werden.");
    }

    return jsonSizeInBytes;
  }

  splitDataForTransfere = async (dataADD, csvFiles, dateKeys) => {
    const chunkSize = 80 * 1024 * 1024; // 20 MB Chunks

    for (let i = 0; i < csvFiles.length; i++) {
      const key = csvFiles[i].name.replace(".csv", "");
      const jsonSizeInBytes = this.stringifyInChunks(
        key,
        dataADD.collectionsData[key]
      );

      const numChunks = Math.ceil(jsonSizeInBytes / chunkSize);
      console.log("numChunks:", numChunks);
      //calcualte size of json
      if (numChunks === 1) {
        const data = {
          databaseName: dataADD.databaseName,
          collectionsData: { [key]: dataADD.collectionsData[key] },
          dateKeys: dateKeys[key],
        };
        this.setState(
          (prevState) => ({
            mongoBDFilesLoaded: {
              ...prevState.mongoBDFilesLoaded,
              [key]: { batch0: false },
            },
          }),
          () => {
            new Promise((resolve) => {
              requestIdleCallback(() => {
                this.insertData(data, key, "batch0", resolve);
              });
            });
          }
        );
      } else {
        const splits = this.splitArray(dataADD.collectionsData[key], numChunks);

        for (let j = 0; j < splits.length; j++) {
          const data = {
            databaseName: dataADD.databaseName,
            collectionsData: { [key]: splits[j] },
            dateKeys: dateKeys[key],
          };
          let batch = "batch" + j;

          this.setState(
            (prevState) => ({
              mongoBDFilesLoaded: {
                ...prevState.mongoBDFilesLoaded,
                [key]: {
                  ...prevState.mongoBDFilesLoaded[key],
                  [batch]: false,
                },
              },
            }),
            () => {
              new Promise((resolve) => {
                requestIdleCallback(() => {
                  this.insertData(data, key, batch, resolve);
                });
              });
            }
          );
        }
      }
    }
  };

  splitArray(array, noOfSplits) {
    if (noOfSplits <= 0) return [];

    const result = [];
    const len = array.length;
    const size = Math.floor(len / noOfSplits);
    let remainder = len % noOfSplits;

    let start = 0;
    for (let i = 0; i < noOfSplits; i++) {
      const end = start + size + (remainder > 0 ? 1 : 0);
      result.push(array.slice(start, end));
      start = end;
      if (remainder > 0) remainder--;
    }

    return result;
  }

  insertData = async (data, loadingkey, batchKey, resolve) => {
    /*const data = {
      databaseName: "mydatabase",
      collectionsData: {
        users: [
          { name: "User 1", email: "user1@example.com", age: 30 },
          { name: "User 2", email: "user2@example.com", age: 25 },
          // ... mehr Datensätze
        ],
        products: [
          { name: "Product 1", price: 9.99, category: "Category 1" },
          { name: "Product 2", price: 19.99, category: "Category 2" },
          // ... mehr Datensätze
        ],
      },
    };*/
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/insert-data",
        data
      );
      if (response.data.status === "success") {
        console.log(loadingkey, " ", batchKey, ": Data inserted successfully");
        this.setState((prevState) => ({
          mongoBDFilesLoaded: {
            ...prevState.mongoBDFilesLoaded,
            [loadingkey]: {
              ...prevState.mongoBDFilesLoaded[loadingkey],
              [batchKey]: true,
            },
          },
        }));
        resolve();
      } else {
        console.log(
          loadingkey,
          " ",
          batchKey,
          "error on mongoDB level while inserting data",
          data
        );
        console.log(response.data.error);
        resolve();
      }
    } catch (error) {
      console.log(
        loadingkey,
        " ",
        batchKey,
        ": error on proxyserver level while inserting data",
        data
      );
      console.log(error);
      resolve();
    }
  };

  async getMongoDBDatestInfos() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/db-stats"
      );
      const excludeDbNames = ["admin", "config", "local"];

      this.setState({
        mongoDatabases: {
          data: response.data.filter(
            (db) => !excludeDbNames.includes(db.dbName)
          ),
          loaded: true,
        },
      });
    } catch (error) {
      this.props.showSnackbar("error", "Error fetching database stats");
    }
  }

  deleteDatabaseAxios = async (dbName) => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/delete-database",
        { data: { dbName } }
      );
      if (response.data.status === "success") {
        this.props.showSnackbar("success", response.data.message);
        this.getMongoDBDatestInfos();
      } else {
        this.props.showSnackbar("error", response.data.message);
      }
    } catch (error) {
      this.props.showSnackbar(
        "error",
        `Error deleting database: ${error.message}`
      );
    }
  };

  createUploadWebSocket() {
    this.wsUploadMongo = new WebSocket(
      "ws://" +
        process.env.REACT_APP_SERVER_URL.replace("http://", "") +
        process.env.REACT_APP_SERVER_PORT
    );

    this.wsUploadMongo.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.step === "insert") {
        this.setState((prevState) => ({
          addingDataStatus:
            prevState.addingDataStatus +
            (data.progress / this.state.totalEntries) * 100,
        }));
      } else if (data.step === "create") {
        this.setState((prevState) => ({
          creatingDBProgress:
            prevState.creatingDBProgress + data.progress * 100,
        }));
      }
    };

    this.wsUploadMongo.onopen = () => {
      console.log("WebSocket connection established");
    };

    this.wsUploadMongo.onclose = () => {
      console.log("WebSocket connection closed");
    };

    this.wsUploadMongo.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }

  getOverviewDataFromDBMongo = async (dbName, collectionName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getAllDataOfCollection",
        {
          params: {
            dbName: dbName,
            collectionName: collectionName,
          },
        }
      );
      if (response.data.status === "success") {
        const dataWithDate = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );
        this.setPatOverViewState(dataWithDate);
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  convertKeysToDate(array1, array2) {
    return array1.map((obj) => {
      // Erstelle eine Kopie des Objekts, um es nicht direkt zu mutieren
      const newObj = { ...obj };

      // Iteriere durch die Schlüssel in array2
      array2.forEach((key) => {
        if (newObj.hasOwnProperty(key) && newObj[key] !== null) {
          newObj[key] = new Date(newObj[key]);
        }
      });

      return newObj;
    });
  }

  getFileListMongo = async (dbName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getFileList",
        {
          params: {
            dbName: dbName,
          },
        }
      );
      if (response.data.status === "success") {
        const list = response.data.data;
        const emptyPatViewObj = this.emptyPatientViewDataObject(list);
        this.setState(
          {
            fileList: list,
            patientViewData: emptyPatViewObj,
            displayedData: emptyPatViewObj,
            emptyPat: emptyPatViewObj,
            allPatDatLoaded: this.emptyPatLoadingObject(list),
            displayedDataProcessed: false,
          },
          () => {
            //load all reasoncodes and thier frequency
            this.loadReasonCodesMongo(this.state.openDB, list);
            this.setState({ fileListLoaded: true });
          }
        );
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  countEntriesInObjectStoresMongo = async (dbName) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getCollectionCounts",
        {
          params: {
            dbName: dbName,
          },
        }
      );
      if (response.data.status === "success") {
        this.setState({ dashboardInfo: response.data.data });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  getPopAnDashboardDataMongo = async (dbName, objStorage) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getFrequenciesIndices",
        {
          params: {
            dbName: dbName,
            collectionName: objStorage,
          },
        }
      );
      if (response.data.status === "success") {
        const frequencies = response.data.data;

        let labels = [];
        let data = [];

        for (let i = 0; i < frequencies.length; i++) {
          labels.push(this.removeSubstrings(frequencies[i]._id));
          data.push(frequencies[i].count);
        }

        this.setState((prevState) => ({
          popAnaDashboardData: {
            ...prevState.popAnaDashboardData,
            [objStorage]: {
              data: data,
              labels: labels,
              processed: true,
            },
          },
        }));
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  loadReasonCodesMongo = async (dbNamae, list) => {
    this.setState({ allReasonCodes: { data: [], loaded: false } }, () => {
      let tablesWIthReason = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].indices.includes("REASONCODE")) {
          tablesWIthReason.push(list[i].name);
        }
      }
      this.reasonMongoRequest(dbNamae, tablesWIthReason);
    });
  };

  reasonMongoRequest = async (dbName, array) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getReasonDescriptionCounts",
        {
          params: {
            dbName: dbName,
            collectionArray: array,
          },
        }
      );
      if (response.data.status === "success") {
        const reasoncodes = response.data.data;
        this.setState({ allReasonCodes: { data: reasoncodes, loaded: true } });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  patHistoryMongo = async (dbName, file, patID) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getEntriesByIndexValue",
        {
          params: {
            dbName: dbName,
            collectionName: file,
            indexName: "PATIENT",
            indexValue: patID,
          },
        }
      );
      if (response.data.status === "success") {
        const results = this.convertKeysToDate(
          response.data.data,
          response.data.dateKeys
        );

        this.setState(
          (prevState) => ({
            patientViewData: {
              ...prevState.patientViewData,
              [file]: { typ: file, data: results },
            },
          }),
          () => {
            this.setState((prevState) => ({
              allPatDatLoaded: {
                ...prevState.allPatDatLoaded,
                [file]: true,
              },
            }));
          }
        );
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  getLastOpenPatFromLocalStorage() {
    const jsonString = localStorage.getItem("lastOpenPatient");
    var json = {};
    var db = "";

    if (jsonString !== null) {
      try {
        json = JSON.parse(jsonString);
        db = json.database;

        delete json.database;
      } catch (error) {
        console.error("Fehler beim Parsen des JSON-Strings:", error);
      }
    }

    return { pat: json, db: db };
  }

  mongoFilterPatOverview = async (filter, requestId) => {
    for (const key in filter) {
      for (let i = 0; i < filter[key].length; i++) {
        const mongoFIlter = this.buildMongoFilterOBj(filter[key][i]);
        console.log(mongoFIlter);
        try {
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              process.env.REACT_APP_SERVER_PORT +
              "/getFilteredEntries",
            {
              params: {
                dbName: this.state.openDB,
                collectionName: key,
                filterCrit: mongoFIlter,
              },
            }
          );
          if (response.data.status === "success") {
            this.setStateOverviewFilterIds(
              filter[key][i].id,
              key,
              i,
              response.data.data,
              requestId
            );
          } else {
            console.log("error", response.data.message);
          }
        } catch (error) {
          console.log(
            "error",
            `Error getting db collections: ${error.message}`
          );
        }
      }
    }
  };

  buildMongoFilterOBj(filter) {
    let counterForArgs = 0; //is needed to find rigth operator
    let inBracket = false;
    let brackets = [];
    let queryCrit = [];

    console.log(filter);
    for (let i = 0; i < filter.argumentsArray.length; i++) {
      let argument = structuredClone(filter.argumentsArray[i]);
      if (!inBracket) {
        if (argument.typ === "argument") {
          let argWithConnector = argument.data;

          if (i < filter.argumentsArray.length - 1) {
            argWithConnector["connector"] =
              filter.operatorValue[counterForArgs].data;
          }

          queryCrit.push(argWithConnector);
          counterForArgs++;
        } else if (argument.typ === "bracketStart") {
          inBracket = true;
          let emptyBracket = { group: [] };
          brackets.push(emptyBracket);
        }
      } else {
        let currentBrackIndex = brackets.length - 1;
        if (argument.typ === "argument") {
          let argWithConnector = argument.data;

          if (
            filter.argumentsArray[i + 1] !== undefined &&
            filter.argumentsArray[i + 1].typ !== "bracketEnd"
          ) {
            argWithConnector["connector"] =
              filter.operatorValue[counterForArgs].data;

            brackets[currentBrackIndex].group.push(argWithConnector);
            counterForArgs++;
          } else {
            brackets[currentBrackIndex].group.push(argWithConnector);
            //here no count up for args beacuse other wise an operator is skipt -> increased after connector added to group
          }
        } else if (argument.typ === "bracketStart") {
          inBracket = true;
          let emptyBracket = { group: [] };
          brackets.push(emptyBracket);
        } else if (argument.typ === "bracketEnd") {
          let bracket = brackets[currentBrackIndex];
          if (
            filter.argumentsArray[i + 1] !== undefined &&
            filter.argumentsArray[i + 1].typ !== "bracketEnd"
          ) {
            console.log(counterForArgs);
            bracket["connector"] = filter.operatorValue[counterForArgs].data;
            counterForArgs++;
          }

          if (currentBrackIndex === 0) {
            inBracket = false;
            queryCrit.push(bracket);
          } else {
            brackets[currentBrackIndex - 1].group.push(bracket);
          }

          brackets.splice(currentBrackIndex, 1);
        }
      }
    }
    return queryCrit;
  }

  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*----------------------------------- react functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /*--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  restoreLastSession = async () => {
    const lastOpenDB = localStorage.getItem("lastOpenDB");

    if (lastOpenDB === null) {
      this.initialLoadDB("DemoDataset", "index");
    } else {
      const jsonLastOpen = JSON.parse(lastOpenDB);

      if (jsonLastOpen.location === "index") {
        this.db = new db(jsonLastOpen.dbName);
        try {
          await this.db.open();
          this.setState({
            openDB: jsonLastOpen.dbName,
            openDbSavingLocation: jsonLastOpen.location,
          });

          return this.db.closeDatabase();
        } catch (error) {
          this.props.showSnackbar(
            "error",
            "The last session could not be restored. Database no longer exists in indexedDB. Loading DemoDataset."
          );
          this.initialLoadDB("DemoDataset", "index");
        }
      } else if (jsonLastOpen.location === "mongo") {
        try {
          const response = await axios.get(
            process.env.REACT_APP_SERVER_URL +
              process.env.REACT_APP_SERVER_PORT +
              "/check-database"
          ); // Proxy-Server-Port

          if (response.data.status === "success") {
            this.setState({
              openDB: jsonLastOpen.dbName,
              openDbSavingLocation: jsonLastOpen.location,
            });
          } else {
            this.props.showSnackbar(
              "error",
              "The last session could not be restored. Unable to open Database in MongoDB. Loading DemoDataset."
            );
            this.initialLoadDB("DemoDataset", "index");
          }
        } catch (error) {
          this.props.showSnackbar(
            "error",
            "The last session could not be restored. Can not connect to backend server. Loading DemoDataset."
          );
          this.initialLoadDB("DemoDataset", "index");
        }
      }
    }
  };

  componentDidMount() {
    console.log("app version:", this.state.version);
    console.log(
      "Server-URL: " +
        process.env.REACT_APP_SERVER_URL +
        process.env.REACT_APP_SERVER_PORT
    );

    console.log(
      "MongoDB-URL: " +
        process.env.REACT_APP_MongoDB_URL +
        process.env.REACT_APP_MongoDB_PORT
    );

    //check for connection to MongoDB
    this.checkConnection();

    //create upload websocket -> gives back progress of adding data in mongodb
    if (process.env.REACT_APP_WS === "true") {
      this.createUploadWebSocket();
      console.log("Websocket activated.");
    } else {
      console.log("Websocket deactivated.");
    }

    //load all data to display website from indexedDB
    //load if to show dialog
    this.setState({ showDialogStates: this.loadDialog() });

    //restore last session -> get last open db and patient or download demodataset
    this.restoreLastSession();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if no conncetion to mongoDB is possible retry (is triggered by snackbar)
    if (prevProps.reconnect !== this.props.reconnect) {
      this.checkConnection();
    }

    // if the demo data is loaded through the CSV-Handler the page is continued when all data is loaded
    if (
      prevState.parseLoadingStatus !== this.state.parseLoadingStatus &&
      this.state.parseLoadingStatus >= 100
    ) {
      this.setState({
        loadingProgressSimple: {
          paraseCsvDone: true,
          creatingDBDone: false,
          writingInDBDone: false,
        },
      });

      let csvDataArray = [];

      for (const key in this.state.csvDataObj) {
        let data = { name: key, data: this.state.csvDataObj[key] };
        csvDataArray.push(data);
      }

      if (this.state.newDBName === "DemoDataset") {
        this.perpareDemoCSVData(this.state.csvDataArray, this.state.csvFiles);
      } else if (this.state.savingLocation === "index") {
        this.perpareCSVDataDexie(csvDataArray, this.state.csvFiles);
      } else {
        //because for mongoDB the data is posted via axios we need to know the keys that contain date objects to change them again into dateobjects after the transmission
        this.perpareCSVDataMongo(
          csvDataArray,
          this.state.csvFiles,
          this.state.csvDateKeys
        );
      }
    }

    //when all mongoDB loaded change progress
    if (
      prevState.mongoBDFilesLoaded !== this.state.mongoBDFilesLoaded &&
      Object.keys(this.state.mongoBDFilesLoaded).length > 0
    ) {
      let allIdLoaded = true;
      let completlyLoadedFiles = [];

      const loaded = this.state.mongoBDFilesLoaded;
      for (const key in loaded) {
        let fileLoaded = true;
        for (const keyFile in loaded[key]) {
          if (!loaded[key][keyFile]) {
            allIdLoaded = false;
            fileLoaded = false;
            break;
          }
        }

        if (fileLoaded) {
          completlyLoadedFiles.push(key);
        }
      }

      completlyLoadedFiles.forEach((file) => this.setFullyLoaded(file));

      if (allIdLoaded) {
        this.getMongoDBDatestInfos();
        this.props.showSnackbar(
          "success",
          "Successfully added all data to database"
        );
        this.setState({
          loadingProgressSimple: {
            paraseCsvDone: true,
            creatingDBDone: true,
            writingInDBDone: true,
          },
        });
      }
    }

    //when mongoDB connection established load the saved datasets
    if (
      prevState.connectionToMongoDB !== this.state.connectionToMongoDB &&
      this.state.connectionToMongoDB.connected
    ) {
      this.getMongoDBDatestInfos();
    }

    //filter the overview data if the filter conditions change
    if (prevState.filterOverview !== this.state.filterOverview) {
      //create empty id array for state
      var emptyIdObj = {};
      let filter = this.returnActiveFilter(this.state.filterOverview);

      if (Object.keys(filter).length === 0) {
        //if filter object is empty return all patients
        this.getOverviewDataFromDB(
          this.state.openDB,
          this.state.openDbSavingLocation
        );
      } else {
        this.startTiming("loadingTimePatOverviewStart");
        this.setState({
          loadingMessagePatOverview: "filtering data",
          loadingTimePatOverviewDuration: null,
        });
        for (const key in filter) {
          emptyIdObj[key] = {};
          for (let index = 0; index < filter[key].length; index++) {
            emptyIdObj[key] = {};
            for (let i = 0; i < filter[key].length; i++) {
              emptyIdObj[key][filter[key][i].id] = {
                loaded: false,
                data: [],
              };
            }
          }
        }
        const newOverviewRequestId = this.state.overviewRequestId + 1;
        this.setState(
          {
            filteredIdsO: emptyIdObj,
            filterLoading: true,
            overviewRequestId: newOverviewRequestId,
          },
          () => {
            if (this.state.openDbSavingLocation === "index") {
              this.dexieFilterPatOverview(filter, newOverviewRequestId);
            } else if (this.state.openDbSavingLocation === "mongo") {
              this.mongoFilterPatOverview(filter, newOverviewRequestId);
            }
          }
        );
      }
    }

    //shoots when ever the overview id array is changed
    if (prevState.filteredIdsO !== this.state.filteredIdsO) {
      //check if all ids are loaded
      let allIdLoaded = true;
      const ids = this.state.filteredIdsO;
      for (const key in ids) {
        for (const fileKey in ids[key]) {
          if (!ids[key][fileKey].loaded) {
            allIdLoaded = false;
          }
        }
      }

      //when all data loaded the array of all fitting patients is created
      if (allIdLoaded) {
        this.getFittingPatients(ids);
      }
    }

    //loads open patient data when filList was loaded (because it creates an empty patientViewData object -> is needed to load all patient data) -> is needed when website is reloaded and patientview is open
    if (
      prevState.fileList !== this.state.fileList &&
      Object.keys(this.state.openPatient).length !== 0
    ) {
      this.preparePatientHistory(
        this.state.openPatient,
        this.state.patientViewData,
        this.state.openDB,
        this.state.openDbSavingLocation
      );
    }

    //loads open patient data when open patient is changed
    if (
      prevState.openPatient !== this.state.openPatient &&
      this.state.fileListLoaded &&
      Object.keys(this.state.openPatient).length !== 0
    ) {
      this.preparePatientHistory(
        this.state.openPatient,
        this.state.patientViewData,
        this.state.openDB,
        this.state.openDbSavingLocation
      );
    }

    //reloads patientList in Overview when new Database is opened
    if (prevState.openDB !== this.state.openDB) {
      this.newSnackbar(
        "success",
        "Loading " + "'" + this.state.openDB + "' dataset"
      );

      const openDBSav = {
        dbName: this.state.openDB,
        location: this.state.openDbSavingLocation,
      };

      const stringObj = JSON.stringify(openDBSav);

      //save last open dataset
      localStorage.setItem("lastOpenDB", stringObj);

      //load last open patient
      const lastPat = this.getLastOpenPatFromLocalStorage();
      this.setState(
        { openPatient: lastPat.db === this.state.openDB ? lastPat.pat : {} },
        () => this.loadData()
      );
    }

    //performs search in Overview List when search term was alterd
    if (prevState.searchValue !== this.state.searchValue) {
      var newPatList = [];
      if (this.state.searchValue !== "") {
        newPatList = this.searchObjectsByTerm(
          this.state.searchValue,
          this.state.allPatOverviewData
        );
      } else {
        newPatList = this.state.allPatOverviewData;
      }
      this.setState({ overviewData: newPatList });
    }

    //adds a new snackbar when tthe message saved in the state is cahnged
    if (prevState.snackbarMessage !== this.state.snackbarMessage) {
      this.props.showSnackbar(
        this.state.snackbarMessage.variant,
        this.state.snackbarMessage.text
      );
    }

    //checks if dialog pop-up needs to be shown when location is changed
    if (prevProps.location !== this.props.location) {
      this.showPopUp(this.props.location.pathname);
    }
  }

  render() {
    if (!this.state.updateDataStructure) {
      return (
        <React.StrictMode>
          <div className="App">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={!this.state.demoDatasetLoaded}
            >
              <CircularProgress color="inherit" />
              <p style={{ marginLeft: "20px" }}>
                {this.state.demoDatasetLoadingMessage}
              </p>
            </Backdrop>
            <Overlay
              isOpen={this.state.settingsOpen}
              onClose={() => this.openSettings()}
              title="Settings"
            >
              <Settings
                sHeaderDisplay={this.state.sHeaderDisplay}
                changeSHeaderDisplay={this.changeSHeaderDisplay}
                boringDiagnoses={this.state.boringDiagnoses}
                resetDialog={this.resetDialog}
                newSnackbar={this.newSnackbar}
                showDeleteSnackbar={this.props.showDeleteSnackbar}
              />
            </Overlay>
            <Overlay
              isOpen={this.state.showPopUp}
              onClose={() => this.closeDialog()}
              title={this.state.popUpTitle}
            >
              <DialogPopUp
                showDialogStatesKey={this.state.showDialogStatesKey}
                dontShowAgain={this.dontShowAgain}
                closeDialog={this.closeDialog}
              />
            </Overlay>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <StartPage
                        getSavedDataId={this.getSavedDataId}
                        getImportedDataset={this.getImportedDataset}
                        openNewDb={this.openNewDb}
                        loadingProgressSimple={this.state.loadingProgressSimple}
                        creatingDBProgress={this.state.creatingDBProgress}
                        openDB={this.state.openDB}
                        getAllEntries={this.getAllEntries}
                        csvFiles={this.state.loadedData.csvFiles}
                        parseCSVData={this.parseCSVData}
                        parseLoadingStatus={this.state.parseLoadingStatus}
                        addingDataStatus={this.state.addingDataStatus}
                        filesFullLoaded={this.state.filesFullLoaded}
                        connectionToMongoDB={this.state.connectionToMongoDB}
                        mongoDatabases={this.state.mongoDatabases}
                      />
                    </>
                  }
                ></Route>
                <Route
                  path="troubleshooting"
                  element={
                    <>
                      <Troubleshooting />
                    </>
                  }
                ></Route>
                <Route
                  path="syn-viewer"
                  element={
                    <>
                      <SyntheaView
                        getOffOnStartPage={this.changeView}
                        location={this.props.location}
                        patientInfoVisible={this.state.patientInfoVisible}
                        openPatient={
                          this.state.openPatient.LAST +
                          ", " +
                          this.state.openPatient.FIRST
                        }
                        nextPatient={this.nextPatient}
                        prevPatient={this.prevPatient}
                        openPatientId={this.state.overviewData.findIndex(
                          (o) => o.Id === this.state.openPatient.Id
                        )}
                        openDB={this.state.openDB}
                        size={this.state.overviewData.length}
                        collapsesSave={this.state.collapsesSave}
                        navigate={this.props.navigate}
                        openSettings={this.openSettings}
                        sHeaderDisplay={this.state.sHeaderDisplay}
                        loadingTimePatientDuration={
                          this.state.loadingTimePatientDuration
                        }
                        loadingMessagePatView={this.state.loadingMessagePatView}
                        loadingTimePatOverviewDuration={
                          this.state.loadingTimePatOverviewDuration
                        }
                        loadingMessagePatOverview={
                          this.state.loadingMessagePatOverview
                        }
                      />
                    </>
                  }
                >
                  <Route
                    path="overview-dataset"
                    element={
                      <>
                        <OverviewDatset
                          openDB={this.state.openDB}
                          dashboardInfo={this.state.dashboardInfo}
                          boringDiagnoses={this.state.boringDiagnoses}
                          patientList={this.state.overviewData}
                          getAllEntries={this.getAllEntries}
                          csvFiles={this.state.loadedData.csvFiles}
                        />
                      </>
                    }
                  ></Route>
                  <Route
                    path="patient-records"
                    element={
                      <>
                        <Outlet />
                      </>
                    }
                  >
                    <Route
                      path="Overview"
                      element={
                        <>
                          {this.state.overviewDataLoaded &&
                          this.state.fileListLoaded ? (
                            <div className="body-view-main">
                              <Overview
                                patientList={this.state.overviewData}
                                loadedFileList={this.state.fileList}
                                changeView={this.setOpenPatient}
                                getFilterFormOverview={
                                  this.getFilterFormOverview
                                }
                                numbPatients={this.state.numbPatients}
                                filterLoading={this.state.filterLoading}
                                dbName={this.state.openDB}
                                oldFilterArray={this.state.filterOverview}
                                overviewSearchValue={this.overviewSearchValue}
                                searchValueOverview={this.state.searchValue}
                                newSnackbar={this.newSnackbar}
                                showDeleteSnackbar={
                                  this.props.showDeleteSnackbar
                                }
                                detailedDBLocation={
                                  this.state.detailedDBLocation
                                }
                                location={this.state.openDbSavingLocation}
                              />
                            </div>
                          ) : (
                            <div className="body-view-main">
                              <CircularProgress
                                color="inherit"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "#4169e1",
                                }}
                              />
                            </div>
                          )}
                        </>
                      }
                    ></Route>
                    <Route
                      path="patient-view"
                      element={
                        <>
                          {Object.keys(this.state.patientViewData).length !==
                          0 ? (
                            <div
                              className="body-view-main"
                              style={{ height: "90.5vh" }}
                            >
                              <PatientView
                                openPatientData={this.state.patientViewData}
                                allPatDatLoaded={this.state.allPatDatLoaded}
                                displayedDataProcessed={
                                  this.state.displayedDataProcessed
                                }
                                displayedData={this.state.displayedData}
                                setDisplayDataProcessed={
                                  this.setDisplayDataProcessed
                                }
                                setPatientInfoVisible={
                                  this.setPatientInfoVisible
                                }
                                getFilteredPatData={this.getFilteredPatData}
                                lastOpenView={this.state.lastOpenView}
                                location={this.props.location.pathname}
                                containerRefStartParent={
                                  this.containerRefStartParent
                                }
                                containerRefStart={this.containerRefStart}
                                setPatViewFilter={this.setPatViewFilter}
                                patViewFilter={this.state.patViewFilter}
                                changePatViewCollapses={
                                  this.changePatViewCollapses
                                }
                                collapsesSave={this.state.collapsesSave}
                                nextPatient={this.nextPatient}
                                prevPatient={this.prevPatient}
                                newSnackbar={this.newSnackbar}
                                showDeleteSnackbar={
                                  this.props.showDeleteSnackbar
                                }
                                allReasonCodes={this.state.allReasonCodes}
                              />
                            </div>
                          ) : (
                            <div className="body-view-main">
                              <CircularProgress
                                color="inherit"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "#4169e1",
                                }}
                              />
                            </div>
                          )}
                        </>
                      }
                    >
                      <Route
                        path="patient-dashboard"
                        element={
                          <Dashboard
                            data={this.state.displayedData}
                            displayedDataProcessed={
                              this.state.displayedDataProcessed
                            }
                            matchEncounter={this.matchEncounter}
                            openEncounter={this.onClickEncounter}
                            whichLocationWhenMounted={
                              this.whichLocationWhenMounted
                            }
                            allPatDatLoaded={this.state.allPatDatLoaded}
                          />
                        }
                      ></Route>
                      <Route
                        path="patient-dataview"
                        element={
                          <>
                            <PatientDataview
                              displayedData={this.state.displayedData}
                              openEncounter={this.onClickEncounter}
                              scrollToTop={this.scrollToTop}
                              scrollToTopFast={this.scrollToTopFast}
                              clickedDataViewEncounter={
                                this.state.clickedDataViewEncounter
                              }
                              setDataViewEncounterNull={
                                this.setDataViewEncounterNull
                              }
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                      <Route
                        path="patient-history"
                        element={
                          <>
                            <PatientHistory
                              clickedEncounter={this.state.clickedEncounter}
                              resetClickedEncounter={this.resetClickedEncounter}
                              openPatientData={this.state.displayedData}
                              matchEncounter={this.matchEncounter}
                              view={this.state.view}
                              scrollToTopFast={this.scrollToTopFast}
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                      <Route
                        path="patient-timeline"
                        element={
                          <>
                            <PatientTimeline
                              data={this.state.displayedData}
                              matchEncounter={this.matchEncounter}
                              openEncounter={this.onClickEncounter}
                              view={this.state.view}
                              scrollToTopFast={this.scrollToTopFast}
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                      <Route
                        path="patient-charts"
                        element={
                          <>
                            <Charts
                              data={this.state.displayedData}
                              scrollToTopFast={this.scrollToTopFast}
                              whichLocationWhenMounted={
                                this.whichLocationWhenMounted
                              }
                            />
                          </>
                        }
                      ></Route>
                    </Route>
                  </Route>
                  <Route
                    path="population-analysis"
                    element={
                      <>
                        <div className="body-view-main">
                          <PopulationAnalysis />
                        </div>
                      </>
                    }
                  >
                    <Route
                      path="pop-ana-dashboard"
                      element={
                        <>
                          <PopAnaDashboard
                            patientList={this.state.overviewData}
                            popAnaDashboardData={this.state.popAnaDashboardData}
                            openDB={this.state.openDB}
                            allReasonCodes={this.state.allReasonCodes}
                            location={this.state.openDbSavingLocation}
                          />
                        </>
                      }
                    ></Route>
                    <Route
                      path="pop-ana-survival"
                      element={
                        <>
                          {this.state.fileListLoaded ? (
                            <SurvivalContainer
                              patientList={this.state.overviewData}
                              openDB={this.state.openDB}
                              loadedFileList={this.state.fileList}
                              getStartPointData={this.getStartPointData}
                              startPointData={this.state.startPointData}
                              startPointDataLoaded={
                                this.state.startPointDataLoaded
                              }
                              location={this.state.openDbSavingLocation}
                            />
                          ) : (
                            <div className="body-view-main">
                              <CircularProgress
                                color="inherit"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "#4169e1",
                                }}
                              />
                            </div>
                          )}
                        </>
                      }
                    ></Route>
                  </Route>
                  <Route
                    path="module-diagram"
                    element={
                      <>
                        <div className="body-view-main">
                          <ModuleDataHandler
                            newSnackbar={this.newSnackbar}
                            patientList={this.state.overviewData}
                            fileList={this.state.patientViewData}
                            openDB={this.state.openDB}
                          />
                        </div>
                      </>
                    }
                  ></Route>
                  <Route
                    path="datasets"
                    element={
                      <>
                        <div className="body-view-main">
                          <Outlet />
                        </div>
                      </>
                    }
                  >
                    <Route
                      path="datasets-overview"
                      element={
                        <>
                          <Datasets
                            setDetailedDbName={this.setDetailedDbName}
                            openNewDb={this.openNewDb}
                            loadingProgressSimple={
                              this.state.loadingProgressSimple
                            }
                            creatingDBProgress={this.state.creatingDBProgress}
                            openDB={this.state.openDB}
                            getAllEntries={this.getAllEntries}
                            csvFiles={this.state.loadedData.csvFiles}
                            parseCSVData={this.parseCSVData}
                            parseLoadingStatus={this.state.parseLoadingStatus}
                            addingDataStatus={this.state.addingDataStatus}
                            filesFullLoaded={this.state.filesFullLoaded}
                            connectionToMongoDB={this.state.connectionToMongoDB}
                            mongoDatabases={this.state.mongoDatabases}
                            deleteDatabaseAxios={this.deleteDatabaseAxios}
                          />
                        </>
                      }
                    ></Route>
                    <Route
                      path="dataset-details"
                      element={
                        <div className="body-view-main">
                          <DatasetDetails
                            detailedDbName={this.state.detailedDbName}
                            detailedDBLocation={this.state.detailedDBLocation}
                          />
                        </div>
                      }
                    ></Route>
                  </Route>
                </Route>
              </Routes>
            </ThemeProvider>
          </div>
        </React.StrictMode>
      );
    } else {
      return (
        <div>
          <Overlay
            isOpen={this.state.updateDataStructure}
            title="ATTENTION: Old data structure detected"
          >
            <div style={{ width: "40vw" }}>
              <p>
                We dedected that the datesets, saved in your local indexedDB use
                an older data structure then the current version of the website.
                Unfortunally you need to delete all of your saved datasets for
                the website to work.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "2vh",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: 300,
                    marginRight: "2vw",
                  }}
                >
                  Delete all databases (filter profiles and filter criterions
                  will be kept):
                </p>
                <Button
                  variant="contained"
                  color="error"
                  onClick={this.deleteAllDatabases}
                >
                  Delete Databases
                </Button>
              </div>
            </div>
          </Overlay>
        </div>
      );
    }
  }
}

export default withRouter(App);
