// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synthea-view-main {
  height: 100vh;
  overflow: hidden;
}

.body-view-main {
  display: flex;
  margin-bottom: 0;
  height: 91vh;
  overflow-y: hidden;
  padding-top: 10px;
}

.content-box {
  width: 85%;
  padding-top: 25px;
  padding-left: 25px;
  overflow: hidden;
}

.general-btn {
  background-color: #4361ee;
  border: none;
  height: 45px;
  margin: 5px;
  min-width: 100px;
  border-radius: 25px;
  color: #f8f4f0;
  cursor: pointer;
}

.general-btn:hover {
  border: 2px solid #fdc4de;
  color: #fdc4de;
}
`, "",{"version":3,"sources":["webpack://./src/SyntheaView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".synthea-view-main {\n  height: 100vh;\n  overflow: hidden;\n}\n\n.body-view-main {\n  display: flex;\n  margin-bottom: 0;\n  height: 91vh;\n  overflow-y: hidden;\n  padding-top: 10px;\n}\n\n.content-box {\n  width: 85%;\n  padding-top: 25px;\n  padding-left: 25px;\n  overflow: hidden;\n}\n\n.general-btn {\n  background-color: #4361ee;\n  border: none;\n  height: 45px;\n  margin: 5px;\n  min-width: 100px;\n  border-radius: 25px;\n  color: #f8f4f0;\n  cursor: pointer;\n}\n\n.general-btn:hover {\n  border: 2px solid #fdc4de;\n  color: #fdc4de;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
