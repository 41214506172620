import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import { Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { Link } from "react-router-dom";

import "./PatientDataview.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
        marginTop: "1em",
        marginBottom: "1em",
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4169e1",
    color: theme.palette.common.white,
    fontFamily: "Spline Sans",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Spline Sans",
    fontWeight: 350,
  },
  "&.encounterCell:hover": {
    color: "#aabcf2",
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default class PatientDataview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      rowsPerPage: 25,
      page: 0,
      order: "asc",
      orderBy: "",
      tableToBig: false,
      checkedIfTableFits: true,
    };

    this.checkOverflow = this.checkOverflow.bind(this);
    this.containerRef = React.createRef();
  }

  /*------------------------------------------state handle functions-------------------------------------------------------------------------------------------------------------------- */

  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => this.props.scrollToTop());
  };

  onClick = (index, indexOfEncounter, encounterId) => {
    if (index === indexOfEncounter) {
      this.props.openEncounter(encounterId);
    }
  };

  checkOverflow = () => {
    const container = this.containerRef.current;

    if (container) {
      const isOverflowing = container.scrollWidth > container.offsetWidth;
      this.setState(
        { tableToBig: isOverflowing, checkedIfTableFits: false },
        () => {
          this.setState({ checkedIfTableFits: true });
        }
      );
    }
  };

  /*---------------------------------------- page functions ---------------------------------------------------------------------------------------------------------------------- */

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  /*---------------------------------------- sort functions ---------------------------------------------------------------------------------------------------------------------- */

  handleRequestSort = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  /*---------------------------------------- display functions---------------------------------------------------------------------------------------------------------------------- */

  formatDate(date) {
    if (date !== null) {
      var date2 = date;
      var isDateObject = date instanceof Date;

      if (!isDateObject) {
        date2 = new Date(date);
      }

      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date2.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  noData(data) {
    if (data.data.length === 0) {
      return <h4>No {data.typ} available.</h4>;
    }
  }

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  checkForEncounter(index, indexOfEncounter, view) {
    if (index === indexOfEncounter && view === "dataView") {
      return "encounterTd";
    } else {
      return "";
    }
  }

  mapTableHead(data, tableToBig) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        var splicedKey = { key: key };
        if (key.length > 10 && tableToBig) {
          splicedKey["displayName"] = key.slice(0, 3);
          splicedKey["tooltip"] = true;
        } else {
          splicedKey["displayName"] = key;
          splicedKey["tooltip"] = false;
        }
        displayArray.push(splicedKey);
      }
    }
    return displayArray;
  }

  checkForAge(data) {
    if (data !== null) {
      if (data.years !== undefined) {
        return data.years;
      } else {
        return data;
      }
    } else {
      return data;
    }
  }

  returnPatientTable(data, tableToBig) {
    var patientDataHead = this.mapTableHead(data.data[0], tableToBig);
    var indexOfEncounter = undefined;
    var indexOfCode = undefined;

    for (let i = 0; i < patientDataHead.length; i++) {
      if (patientDataHead[i].key === "CODE") {
        indexOfCode = i;
        break;
      }
    }

    for (let i = 0; i < patientDataHead.length; i++) {
      if (patientDataHead[i].key === "ENCOUNTER") {
        indexOfEncounter = i;
        break;
      }
    }

    if (data.typ !== "patient") {
      return (
        <TableContainer component={Paper} ref={this.containerRef}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {patientDataHead.map((data, index) => (
                  <StyledTableCell
                    key={data.key}
                    sortDirection={
                      this.state.orderBy === data.key ? this.state.order : false
                    }
                  >
                    <TableSortLabel
                      active={this.state.orderBy === data.key}
                      direction={
                        this.state.orderBy === data.key
                          ? this.state.order
                          : "asc"
                      }
                      onClick={this.handleRequestSort(data.key)}
                      sx={{ fontFamily: "Spline Sans" }}
                    >
                      {data.tooltip ? (
                        <Tooltip title={data.displayName} arrow>
                          <span>{data.displayName}</span>
                        </Tooltip>
                      ) : (
                        data.displayName
                      )}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.stableSort(
                data.data,
                this.getComparator(this.state.order, this.state.orderBy)
              )
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((x, i) => (
                  <StyledTableRow key={"rowTbody" + i}>
                    {this.mapDataView(x).map((y, index) => (
                      <StyledTableCell
                        onClick={() => {
                          this.onClick(index, indexOfEncounter, y);
                        }}
                        className={
                          indexOfEncounter === index || indexOfCode === index
                            ? "encounterCell"
                            : ""
                        }
                      >
                        {indexOfEncounter === index || indexOfCode === index ? (
                          <Tooltip
                            title={
                              indexOfEncounter === index
                                ? "See all entrys of encounter"
                                : "Open in Chart"
                            }
                            enterDelay={1000}
                          >
                            <span>{y}</span>
                          </Tooltip>
                        ) : y instanceof Date ? (
                          this.formatDate(y)
                        ) : (
                          y
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    30,
                    50,
                    100,
                    200,
                    500,
                    { label: "All", value: -1 },
                  ]}
                  colSpan={patientDataHead.length}
                  count={data.data.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  selectprops={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    ".MuiTablePagination-displayedRows": {
                      "margin-top": "1em",
                      "margin-bottom": "1em",
                    },
                    ".MuiTablePagination-selectLabel": {
                      "margin-top": "1em",
                      "margin-bottom": "1em",
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      );
    } else {
      patientDataHead = this.mapTableHead(data.data, tableToBig);
      return (
        <TableContainer
          ref={this.containerRef}
          style={{
            display: "flex",
            alignItems: "top",
            justifyContent: "center",
            height: "200vh",
          }}
          component={Paper}
        >
          <Table
            sx={{
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              padding: "2px",
            }}
            aria-label="customized table"
          >
            <TableHead>
              {patientDataHead.map((head, index) => (
                <TableRow key={"rowTHead" + index}>
                  <StyledTableCell key={head.key + index + "tabHead"}>
                    {head.key}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {this.mapDataView(data.data).map((data, index) => (
                <StyledTableRow
                  key={data + index + "tabBody"}
                  style={{ height: "57px", flexGrow: "1" }}
                >
                  <StyledTableCell>
                    {data instanceof Date
                      ? this.formatDate(data)
                      : this.checkForAge(data)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }

  /*------------------React functions-------------------------------------------------------------------------------------------------------------------------------------------- */

  componentDidMount() {
    this.props.scrollToTopFast();
    this.props.whichLocationWhenMounted();
    this.checkOverflow(); //checks if table is too big for screen

    //set patient view as open tab
    var indexOf = 0;
    for (const key in this.props.displayedData) {
      if (key === "patient") {
        break;
      }
      indexOf++;
    }

    this.setState({ tabValue: indexOf });
    //when dataview is opend from historyview open encounter tab and scroll to encounter
    if (this.props.clickedDataViewEncounter !== "") {
      var findIndexOfEncounter = 0;
      for (const key in this.props.displayedData) {
        if (key === "encounters") {
          break;
        } else {
          findIndexOfEncounter++;
        }
      }

      const encounterIndex = this.props.displayedData.encounters.data.findIndex(
        (o) => o.Id === this.props.clickedDataViewEncounter
      );

      /**  -------------------------------------------------------------------------------------------------------------------------- to do
      var pageOfEncounter;

      for (let i = 1; i < 1000; i++) {
        if (encounterIndex <= i * this.state.rowsPerPage) {
          pageOfEncounter = i;
          break;
        }
      }
       */

      this.setState({ tabValue: findIndexOfEncounter }, () =>
        this.props.setDataViewEncounterNull()
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tabValue !== this.state.tabValue) {
      this.checkOverflow();
    }
  }

  render() {
    if (this.state.checkedIfTableFits) {
      return (
        <Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              top: -1,
              backgroundColor: "white",
            }}
          >
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
            >
              {this.mapDataView(this.props.displayedData).map((data, index) => (
                <Tab
                  label={data.typ.toUpperCase()}
                  {...a11yProps(index)}
                  style={{ fontSize: "18px", fontFamily: "Spline Sans" }}
                  disabled={data.data.length === 0}
                  key={"tabDataView" + data + index}
                />
              ))}
            </Tabs>
          </Box>

          {this.mapDataView(this.props.displayedData).map((data, index) => (
            <CustomTabPanel
              value={this.state.tabValue}
              index={index}
              key={"tabPanelDataView" + data.typ + index}
            >
              {this.noData(data)}
              {this.returnPatientTable(data, this.state.tableToBig)}
            </CustomTabPanel>
          ))}
        </Box>
      );
    }
  }
}
