import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Typography,
  Box,
  styled,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  IconButton,
  Divider,
  Tooltip,
  ListSubheader,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Collapse,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  ButtonGroup,
  TextField,
  ListItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import axios from "axios";
import { tableCellClasses } from "@mui/material/TableCell";

import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import InfoBtnToolTip from "../utils/InfoBtnToolTip";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Overlay from "../Overlay/Overlay";
import OverviewSavedCriterions from "./OverviewSavedCriterions";
import RawTable from "./RawTable";
import DndBox from "./DndBox";

import fileColumnSearchbar from "../utils/DexieDatabase/fileColumnSearchbar";

import "./Overview.css";

export default class Overview extends Component {
  // component that displays the patient overview page and filter -> the actual filtering happens in the app component
  constructor(props) {
    super(props);
    this.containerRefStartParent = React.createRef();
    this.containerRefStart = React.createRef();

    this.state = {
      tableHead: [
        { display: "Name", sort: "Name" },
        { display: "Gender", sort: "Gender" },
        { display: "Birthdate", sort: "Birthdate" },
        { display: "Deathdate", sort: "Deathdate" },
        { display: "Age", sort: "Age" },
        { display: "State", sort: "State" },
        { display: "Important Diagnoses", sort: "ImportantDiagnoses" },
      ],
      rowsPerPage: 25,
      page: 0,
      order: "asc",
      orderBy: "",
      filterFileList: [],
      openFilterList: {},
      columnsOfFile: {},
      searchResults: [],
      isOverlayOpen: false,
      isOverlayOpenCrit: false,
      isOverlayRawTableOpen: false,
      comesFromOverlay: false,
      openFileFilter: "patients",
      countOperator: 0,
      textfieldWidth: 0,
      filterArgument: {
        typ: "argument",
        data: { column: "", operator: "", value: "", inputTyp: "" },
      },
      filterCondition: [],
      filterOperators: [],
      conditionsObject: {},
      filterConditionName: "",
      showDropdown: false,
      divOnFocus: "",
      activeCriterions: 0,
      showPage: false,
      warningOverlay: "",
      didComponetJustMount: true,
      edit: false,
      columnIsDate: false,

      //search bar states
      openSearchBar: false,
      searchId: 0,
      loadingSearchResults: true,
    };

    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleOperatorMouseOver = this.handleOperatorMouseOver.bind(this);
    this.openSelect = this.openSelect.bind(this);
    this.chooseItem = this.chooseItem.bind(this);
    this.hideFilterCriterion = this.hideFilterCriterion.bind(this);
    this.deleteFilterCriterion = this.deleteFilterCriterion.bind(this);
    this.addFilterCriterionFromLocal =
      this.addFilterCriterionFromLocal.bind(this);

    this.textFieldRef = React.createRef();
    this.dropdownRef = React.createRef();
  }

  onClick(patient) {
    this.props.changeView(patient);
  }

  checkForDeathDate(deathdate) {
    if (deathdate === null) {
      return "-";
    } else {
      return deathdate;
    }
  }

  formatDate(date1) {
    if (date1 !== null && date1 !== undefined) {
      let date = date1;

      if (!(date1 instanceof Date)) {
        date = new Date(date1);
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Monat beginnt bei 0, deshalb +1, und dann auf 2 Stellen auffüllen
      const day = String(date.getDate()).padStart(2, "0"); // Tag auf 2 Stellen auffüllen

      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  }

  containsOnlyNumbers(variable) {
    // Überprüfe, ob die Variable eine Zahl oder eine Zeichenkette ist
    if (typeof variable === "number" || typeof variable === "string") {
      // Verwende einen regulären Ausdruck, um zu prüfen, ob die Variable nur aus Zahlen besteht
      return /^[0-9]+([.,][0-9]+)?$/.test(variable);
    }
    // Wenn die Variable kein number oder string ist, dann ist das Ergebnis false
    return false;
  }
  /*-------------------------------------------- search functions ------------------------------------------------------------------------------------------------------------ */

  openSearchBar = () => {
    var trueOrFalse;
    this.state.openSearchBar ? (trueOrFalse = false) : (trueOrFalse = true);
    this.setState({ openSearchBar: trueOrFalse });
  };

  /*-------------------------------------------- scroll functions ------------------------------------------------------------------------------------------------------------ */

  scrollToTopFast = () => {
    const child = this.containerRefStart.current;
    const parent = this.containerRefStartParent.current;
    if (parent !== null) {
      // Where is the parent on page
      var parentRect = parent.getBoundingClientRect();
      // What can you see?
      var parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
      };

      // Where is the child
      var childRect = child.getBoundingClientRect();
      // Is the child viewable?
      var isViewable =
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.top + parentViewableArea.height;

      // if you can't see the child try to scroll parent
      if (!isViewable) {
        // Should we scroll using top or bottom? Find the smaller ABS adjustment
        const scrollTop = childRect.top - parentRect.top;
        const scrollBot = childRect.bottom - parentRect.bottom;
        if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
          // we're near the top of the list
          parent.scrollTop += scrollTop;
        } else {
          // we're near the bottom of the list
          parent.scrollTop += scrollBot;
        }
      }
    }
  };

  smoothScrollTo(element, to, duration) {
    const start = element.scrollTop;
    const difference = to - start;
    const startTime = performance.now();

    function scroll(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);
      element.scrollTop = start + difference * progress;

      if (currentTime < duration) {
        window.requestAnimationFrame(scroll);
      }
    }

    window.requestAnimationFrame(scroll);
  }

  scrollToTop = () => {
    const child = this.containerRefStart.current;
    const parent = this.containerRefStartParent.current;
    if (parent !== null) {
      const parentRect = parent.getBoundingClientRect();
      const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
      };

      const childRect = child.getBoundingClientRect();
      const isViewable =
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.top + parentViewableArea.height;

      if (!isViewable) {
        const scrollTop = childRect.top - parentRect.top;
        const scrollBot = childRect.bottom - parentRect.bottom;
        const scrollAmount =
          Math.abs(scrollTop) < Math.abs(scrollBot) ? scrollTop : scrollBot;

        this.smoothScrollTo(parent, parent.scrollTop + scrollAmount, 200); // Hier 500 ist die Dauer der Scrollbewegung in Millisekunden
      }
    }
  };

  /*-------------------------------------------- overlay ------------------------------------------------------------------------------------------------------------ */

  openOverlay = (file) => {
    //opens overlay that allows you to add new arguments to the filter list

    if (!this.state.isOverlayOpen) {
      //figure out if openFile has been changed  -> if yes then filterArgument is emptied
      var filterArgument;
      var filterCondition;
      if (file !== this.state.openFileFilter) {
        filterArgument = {
          typ: "argument",
          data: {
            column: "",
            operator: "",
            value: "",
            inputTyp: "",
          },
        };
        filterCondition = [];
      } else {
        filterArgument = this.state.filterArgument;
        filterCondition = this.state.filterCondition;
      }

      this.setState({
        isOverlayOpen: true,
        openFileFilter: file,
        filterArgument: filterArgument,
        filterCondition: filterCondition,
        filterConditionName: "",
      });
    } else {
      this.setState({
        isOverlayOpen: false,
        edit: false,
      });
    }
  };

  openSavedCriterions = (file) => {
    this.state.isOverlayOpenCrit
      ? this.setState({ isOverlayOpenCrit: false })
      : this.setState({ isOverlayOpenCrit: true, openFileFilter: file });
  };

  openRawTable = (fileClicked) => {
    //sets clicked file in openFile so RawTable component knows which data to get
    var file;
    var openOverlay;

    if (fileClicked !== undefined) {
      file = fileClicked;
      openOverlay = false;
    } else {
      file = this.state.openFileFilter;
      openOverlay = true;
    }

    var isOverlayOpen = false;
    //decide if overlay needs to be opened again
    if (this.state.comesFromOverlay) {
      isOverlayOpen = true;
    }

    this.state.isOverlayRawTableOpen
      ? this.setState({
          isOverlayRawTableOpen: false,
          isOverlayOpen: isOverlayOpen,
        })
      : this.setState({
          isOverlayRawTableOpen: true,
          isOverlayOpen: false,
          openFileFilter: file,
          comesFromOverlay: openOverlay,
        });
  };

  /*-------------------------------------------- Filter List functions ------------------------------------------------------------------------------------------------------------ */

  handleClickFilterList = (option) => {
    var oldState = this.state.openFilterList;
    !oldState[option] ? (oldState[option] = true) : (oldState[option] = false);
    this.setState({ openFilterList: oldState });
  };

  handleListCheckbox = (filename, index) => {
    //handles the checked status of a filter criterion -> allows user to activte/deactivate a criterion without deleting it
    var oldConditionObject = structuredClone(this.state.conditionsObject);
    const checkedOrNot = oldConditionObject[filename][index].checked;
    var newActiveCriterions;

    if (checkedOrNot) {
      oldConditionObject[filename][index]["checked"] = false;
      newActiveCriterions = this.state.activeCriterions - 1;
    } else {
      oldConditionObject[filename][index]["checked"] = true;
      newActiveCriterions = this.state.activeCriterions + 1;
    }

    this.setState({
      conditionsObject: oldConditionObject,
      activeCriterions: newActiveCriterions,
    });
  };

  handleListHover = (filename, index) => {
    // handles hover of list item -> when hoverd delete and edit button are showed
    this.setState((prevState) => ({
      conditionsObject: {
        ...prevState.conditionsObject,
        [filename]: [
          ...prevState.conditionsObject[filename].slice(0, index),
          {
            ...prevState.conditionsObject[filename][index],
            showDelete: true,
          },
          ...prevState.conditionsObject[filename].slice(index + 1),
        ],
      },
    }));
  };

  handleMouseLeave = (filename, index) => {
    // handles not hover of list item -> delete and edit button are not showed
    this.setState((prevState) => ({
      conditionsObject: {
        ...prevState.conditionsObject,
        [filename]: [
          ...prevState.conditionsObject[filename].slice(0, index),
          {
            ...prevState.conditionsObject[filename][index],
            showDelete: false,
          },
          ...prevState.conditionsObject[filename].slice(index + 1),
        ],
      },
    }));
  };

  saveCriterionLoacllay = async (data) => {
    // saves added filter criterions to the loacl storage
    var savedValues = JSON.parse(
      localStorage.getItem("overviewRecentConditionArray")
    );

    if (savedValues === null) {
      savedValues = {};
    }

    var newData = structuredClone(data);
    newData["checked"] = false;

    if (
      savedValues[this.state.openFileFilter] === undefined ||
      savedValues[this.state.openFileFilter].length === 0
    ) {
      savedValues[this.state.openFileFilter] = [newData];
    } else {
      savedValues[this.state.openFileFilter].push(newData);
    }

    try {
      localStorage.setItem(
        "overviewRecentConditionArray",
        JSON.stringify(savedValues)
      );

      //show snackbar
      this.props.newSnackbar(
        "success",
        "'" + newData.name + "'" + " filter option has been added."
      );
    } catch (error) {
      if (error.name === "QuotaExceededError") {
        console.error(
          "Attention: The available storage space of the Local Storage has been exceeded. The filter criterion is added, but cannot be saved loacl and is therefore not available for the next session."
        );
        alert(
          "Attention: The available storage space of the Local Storage has been exceeded. The filter criterion is added, but cannot be saved loacl and is therefore not available for the next session."
        );
      } else {
        console.error(
          "Fehler beim Schreiben in den Local Storage:",
          error.message
        );
      }
    }
  };

  returnSavedFilterCriterions() {
    //get all filter criterions from loacl storage and adds them to the list off filters, the criterions are not checked/active -> functions as suggestion
    //only the 5 lastest filters per file will be added
    var savedFilterCriterions = JSON.parse(
      localStorage.getItem("overviewRecentConditionArray")
    );

    // make all stringified date objects back into date obj
    for (const key in savedFilterCriterions) {
      for (let index = 0; index < savedFilterCriterions[key].length; index++) {
        for (
          let i = 0;
          i < savedFilterCriterions[key][index].argumentsArray.length;
          i++
        ) {
          if (
            savedFilterCriterions[key][index].argumentsArray[i].typ !==
              "bracketStart" &&
            savedFilterCriterions[key][index].argumentsArray[i].typ !==
              "bracketEnd"
          ) {
            if (
              savedFilterCriterions[key][index].argumentsArray[i].data
                .inputTyp === "date"
            ) {
              const dateObject = new Date(
                savedFilterCriterions[key][index].argumentsArray[i].data.value
              );
              savedFilterCriterions[key][index].argumentsArray[i].data[
                "value"
              ] = dateObject;
            }
          }
        }
      }
    }

    if (
      Object.keys(this.props.oldFilterArray).length === 0 &&
      this.state.didComponetJustMount
    ) {
      if (savedFilterCriterions !== null) {
        for (const key in savedFilterCriterions) {
          if (savedFilterCriterions[key].length > 5) {
            savedFilterCriterions[key].splice(5);
          }
        }
      } else {
        savedFilterCriterions = {};
      }
    } else {
      savedFilterCriterions = this.props.oldFilterArray;
    }

    return savedFilterCriterions;
  }

  returnAllFilterCriterions() {
    //returns all FilterCriterions that are saved in local storage
    return JSON.parse(localStorage.getItem("overviewRecentConditionArray"));
  }

  hideFilterCriterion = (filename, index, noSnackbar) => {
    //hides filter criterion so its not any longer shown in list, but not deletes -> can be found in OverviewSavedCriterions
    var oldConditionObject = structuredClone(this.state.conditionsObject);

    var nameOfCrit = oldConditionObject[filename][index].name;
    //checks if criterion is checked to alter the activeCriterion state
    var isChecked = 0;
    oldConditionObject[filename][index].checked && (isChecked = -1);

    oldConditionObject[filename].splice(index, 1);
    this.setState(
      (prevState) => ({
        conditionsObject: oldConditionObject,
        activeCriterions: prevState.activeCriterions + isChecked,
      }),
      () => {
        if (noSnackbar !== true) {
          this.props.newSnackbar(
            "info",
            "'" + nameOfCrit + "'" + " filter option has been hidden."
          );
        }
      }
    );
  };

  hideFilterOverlay = (filename, id) => {
    //hides filter criterion from overlay
    var oldConditionObject = structuredClone(this.state.conditionsObject);

    var index = oldConditionObject[filename].findIndex((o) => o.id === id);
    var nameOfCrit = oldConditionObject[filename][index].name;
    //checks if criterion is checked to alter the activeCriterion state
    var isChecked = 0;
    oldConditionObject[filename][index].checked && (isChecked = -1);
    oldConditionObject[filename].splice(index, 1);

    this.props.newSnackbar(
      "info",
      "'" + nameOfCrit + "'" + " filter option has been hidden."
    );

    this.setState((prevState) => ({
      conditionsObject: oldConditionObject,
      activeCriterions: prevState.activeCriterions + isChecked,
    }));
  };

  editCrit = (file, index, filename) => {
    this.setState({
      filterOperators: file.operatorValue,
      filterCondition: file.argumentsArray,
      filterConditionName: file.name,
      isOverlayOpen: true,
      openFileFilter: filename,
      edit: true,
      editIndex: index,
    });
  };

  editSave = () => {
    var newConditions = structuredClone(this.state.conditionsObject);
    var critName = this.state.filterConditionName;
    newConditions[this.state.openFileFilter][
      this.state.editIndex
    ].argumentsArray = this.state.filterCondition;

    newConditions[this.state.openFileFilter][
      this.state.editIndex
    ].operatorValue = this.state.filterOperators;

    newConditions[this.state.openFileFilter][this.state.editIndex].name =
      this.state.filterConditionName;

    this.editLocalCrit(
      this.state.openFileFilter,
      newConditions[this.state.openFileFilter][this.state.editIndex]
    );

    this.setState(
      (prevState) => ({
        conditionsObject: newConditions,
        filterCondition: [],
        isOverlayOpen: false,
      }), //show snackbar
      this.props.newSnackbar(
        "success",
        "'" + critName + "'" + " filter option has been edited."
      )
    );
  };

  editLocalCrit = async (filename, newCondition) => {
    var savedValues = JSON.parse(
      localStorage.getItem("overviewRecentConditionArray")
    );

    var index = savedValues[filename].findIndex(
      (o) => o.id === newCondition.id
    );

    savedValues[this.state.openFileFilter][index].argumentsArray =
      newCondition.argumentsArray;

    savedValues[this.state.openFileFilter][index].operatorValue =
      newCondition.operatorValue;

    savedValues[this.state.openFileFilter][index].name = newCondition.name;

    try {
      localStorage.setItem(
        "overviewRecentConditionArray",
        JSON.stringify(savedValues)
      );
    } catch (error) {
      if (error.name === "QuotaExceededError") {
        console.error(
          "Attention: The available storage space of the Local Storage has been exceeded. The filter criterion is added, but cannot be saved loacl and is therefore not available for the next session."
        );
        alert(
          "Attention: The available storage space of the Local Storage has been exceeded. The filter criterion is added, but cannot be saved loacl and is therefore not available for the next session."
        );
      } else {
        console.error(
          "Fehler beim Schreiben in den Local Storage:",
          error.message
        );
      }
    }
  };

  deleteFilterCriterion = async (filename, id) => {
    var savedValues = JSON.parse(
      localStorage.getItem("overviewRecentConditionArray")
    );

    var index = savedValues[filename].findIndex((o) => o.id === id);
    var nameOfCrit = savedValues[filename][index].name;

    savedValues[filename].splice(index, 1);

    this.hideFilterOverlay(filename, id);
    try {
      localStorage.setItem(
        "overviewRecentConditionArray",
        JSON.stringify(savedValues)
      );
      this.props.showDeleteSnackbar(
        "'" + nameOfCrit + "'" + " filter option has been deleted."
      );
    } catch (error) {
      if (error.name === "QuotaExceededError") {
        console.error(
          "Attention: The available storage space of the Local Storage has been exceeded. The filter criterion is added, but cannot be saved loacl and is therefore not available for the next session."
        );
        this.props.newSnackbar(
          "error",
          "Error while deleting " +
            "'" +
            nameOfCrit +
            "'" +
            ": The available storage space of the Local Storage has been exceeded. The filter criterion is added, but cannot be saved loacl and is therefore not available for the next session."
        );
      } else {
        console.error(
          "Fehler beim Schreiben in den Local Storage:",
          error.message
        );
      }
    }
  };

  addFilterCriterionFromLocal = (filename, condition) => {
    //adds hidden filter criterion from localstorage to state
    var oldConditionObject = structuredClone(this.state.conditionsObject);
    var newCondition = structuredClone(condition);
    newCondition["checked"] = true;
    oldConditionObject[filename].push(newCondition);

    this.setState(
      (prevState) => ({
        conditionsObject: oldConditionObject,
        activeCriterions: prevState.activeCriterions + 1,
      }),
      () => {
        //show snackbar
        this.props.newSnackbar(
          "success",
          "'" + condition.name + "'" + " filter option has been added."
        );
      }
    );
  };

  /*---------------------------------------- page functions ---------------------------------------------------------------------------------------------------------------------- */

  handleChangePage = (event, newPage) => {
    this.scrollToTop();
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  /*---------------------------------------- sort functions ---------------------------------------------------------------------------------------------------------------------- */

  handleRequestSort = (property) => (event) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  descendingComparator(a, b, orderBy) {
    if (orderBy === "Age") {
      if (b[orderBy].years < a[orderBy].years) {
        return -1;
      }
      if (b[orderBy].years > a[orderBy].years) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  /* ---------------------------------------- overlay filter button functions ---------------------------------------------------------------------------------------------------------------------- */

  addArgument = () => {
    //adds argument to filter condition and operator (if necessary)
    //prepare argument array
    const argument = structuredClone(this.state.filterArgument);

    var oldFilterCondition = [...this.state.filterCondition];
    argument["position"] = oldFilterCondition.length;
    argument["id"] =
      argument.position +
      argument.data.column +
      String(argument.data.value).substring(0, 3);

    oldFilterCondition.push(argument);

    //prepare operator array
    var operator = [];
    if (oldFilterCondition.length > 1) {
      operator = this.returnOperatorObj();
    }

    this.setState({
      filterOperators: operator,
      filterCondition: oldFilterCondition,
      filterArgument: {
        typ: "argument",
        data: { column: "", operator: "", value: "" },
      },
    });
  };

  handleDate = (date) => {
    var oldFilterArgument = { ...this.state.filterArgument };
    oldFilterArgument.data.value = date;
    oldFilterArgument.data.inputTyp = "date";
    this.setState({ filterArgument: oldFilterArgument });
  };

  handleChange = (event) => {
    //handles inputs of arguments
    var oldFilterArgument = { ...this.state.filterArgument };

    //for the mouseDown function
    if (event.target.id === "value") {
      const value = event.target.getAttribute("value");
      //check if value is string or float
      var inputTyp;
      if (this.containsOnlyNumbers(value)) {
        inputTyp = "float";
      } else {
        inputTyp = "string";
      }

      oldFilterArgument.data[event.target.id] = value;
      oldFilterArgument.data["inputTyp"] = inputTyp;
    } else {
      const value = event.target.value;

      //triggers if function is called by handleChange
      //if change is value -> check for input typ
      if (event.target.name === "value") {
        var inputTyp;
        if (this.containsOnlyNumbers(value)) {
          inputTyp = "float";
        } else {
          inputTyp = "string";
        }
        oldFilterArgument.data["inputTyp"] = inputTyp;
      }

      oldFilterArgument.data[event.target.name] = value;
    }

    if (event.target.name === "column") {
      //add examples in search results array as examples
      this.displayFirstColumn(
        this.state.openFileFilter,
        oldFilterArgument.data.column,
        this.props.location
      );
      oldFilterArgument.data["value"] = "";
    } else if (event.target.name === "value") {
      const searchTerm = event.target.value;

      this.performSearch(
        searchTerm,
        this.state.openFileFilter,
        oldFilterArgument.data.column,
        this.props.location
      );
    }

    //when trying to compare string with other values with an operator that is not "=" show warning
    if (
      oldFilterArgument.data.operator === "=" ||
      oldFilterArgument.data.operator === "!="
    ) {
      this.setState({
        warningOverlay: "",
      });
    } else {
      if (oldFilterArgument.data.inputTyp === "string") {
        this.setState({
          warningOverlay:
            'Comparing a strings with other operators then "=" or "!=" can lead to unexpected results.',
        });
      }
    }

    this.setState({ filterArgument: oldFilterArgument });
  };

  handleNameChange = (event) => {
    //handles the change of the input that gives the name of the filter condition
    this.setState({ filterConditionName: event.target.value });
  };

  addBrackets = () => {
    //adds a brackets to the filterCondition Array that is saved in the state

    //prepare brackets
    const oldFilterCondition = [...this.state.filterCondition];
    const position = oldFilterCondition.length;
    const bracketStart = {
      typ: "bracketStart",
      id: "startBrackets" + position,
      display: "(",
    };

    const bracketEnd = {
      typ: "bracketEnd",
      id: "endBrackets" + position,
      display: ")",
    };

    oldFilterCondition.push(bracketStart);
    oldFilterCondition.push(bracketEnd);

    var operator = [];
    if (oldFilterCondition.length > 1) {
      operator = this.returnOperatorObj();
    }

    this.setState({
      filterCondition: oldFilterCondition,
      filterOperators: operator,
    });
  };

  returnOperatorObj() {
    //returns a fresh operator
    const oldOperator = [...this.state.filterOperators];
    const newOperator = {
      typ: "operator",
      data: "and",
      position: oldOperator.length,
      showSelect: false,
      showIconButton: false,
    };
    oldOperator.push(newOperator);

    return oldOperator;
  }

  removeItem = () => {
    // removes items out of the filterCondition array and removes the last operator out of the operator array
    var oldFilterCondition = [...this.state.filterCondition];
    const index = oldFilterCondition.findIndex(
      (item) => item.id === this.state.divOnFocus
    );

    var oldFilterOperators = [...this.state.filterOperators];
    oldFilterOperators.splice(oldFilterOperators.length - 1, 1);
    oldFilterCondition.splice(index, 1);
    this.setState({
      filterCondition: oldFilterCondition,
      filterOperators: oldFilterOperators,
    });
  };

  chooseItem = (id) => {
    //sets active item in state -> is used to delete itemes out of the filterCondition array
    this.setState({ divOnFocus: id });
  };

  addFilterConditionToArray = () => {
    //adds the filter condition for one file into the array with all conditions of all files
    var condition = {
      name: this.state.filterConditionName,
      argumentsArray: this.state.filterCondition,
      operatorValue: this.state.filterOperators,
      checked: true,
      showDelete: false,
      id: Date.now(),
    };

    this.saveCriterionLoacllay(condition);

    var oldConditionObject = structuredClone(this.state.conditionsObject);

    if (
      oldConditionObject[this.state.openFileFilter] === undefined ||
      oldConditionObject[this.state.openFileFilter].length === 0
    ) {
      oldConditionObject[this.state.openFileFilter] = [condition];
    } else {
      oldConditionObject[this.state.openFileFilter].push(condition);
    }

    this.setState((prevState) => ({
      conditionsObject: oldConditionObject,
      filterCondition: [],
      activeCriterions: prevState.activeCriterions + 1,
    }));
  };

  addToConditionArrayAndClose = () => {
    this.addFilterConditionToArray();
    this.setState({ isOverlayOpen: false });
  };

  /* ---------------------------------------- operator display functions ---------------------------------------------------------------------------------------------------------------------- */

  openSelect = (position) => {
    //when clicking "operator-text-div" div open the select to change operator, called in "DndBox.jsx"

    var oldOperatorArray = [...this.state.filterOperators];

    this.state.filterOperators[position].showSelect
      ? (oldOperatorArray[position].showSelect = false)
      : (oldOperatorArray[position].showSelect = true);

    this.setState({ filterOperators: oldOperatorArray });
  };

  handleOperatorMouseOver = (position, inOrOut) => {
    //display ModeIcon when hovering over "operator-text-div", called in "DndBox.jsx"

    var oldOperatorArray = [...this.state.filterOperators];

    oldOperatorArray[position].showIconButton = inOrOut;

    this.setState({ filterOperators: oldOperatorArray });
  };

  handleOperatorSelect = (event) => {
    //updates filterOperators (saved in state) and closes select, called in "DndBox.jsx"

    var oldOperatorArray = [...this.state.filterOperators];
    var position = event.target.name;

    oldOperatorArray[position].showSelect = false;
    oldOperatorArray[position].showIconButton = false;
    oldOperatorArray[position].data = event.target.value;

    this.setState({ filterOperators: oldOperatorArray });
  };

  diplayFilterTooltip(condition) {
    //creats a string that can be displayed in tooltip in filter list -> shows the conditions of filter criterion
    var displayStr = "";
    var argumentIndex = 0;

    for (let index = 0; index < condition.argumentsArray.length; index++) {
      if (condition.argumentsArray[index].typ === "bracketStart") {
        if (
          argumentIndex > 0 &&
          condition.operatorValue[argumentIndex - 1] !== undefined
        ) {
          displayStr =
            displayStr +
            " " +
            condition.operatorValue[argumentIndex - 1].data.toUpperCase() +
            " ";
        }
        displayStr += "(";
      } else if (condition.argumentsArray[index].typ === "bracketEnd") {
        displayStr += ")";
      } else {
        if (
          argumentIndex > 0 &&
          condition.operatorValue[argumentIndex - 1] !== undefined &&
          condition.argumentsArray[index - 1].typ !== "bracketStart"
        ) {
          displayStr =
            displayStr +
            " " +
            condition.operatorValue[argumentIndex - 1].data.toUpperCase() +
            " ";
        }

        displayStr =
          displayStr +
          condition.argumentsArray[index].data.column +
          condition.argumentsArray[index].data.operator +
          condition.argumentsArray[index].data.value;

        argumentIndex++;
      }
    }
    return displayStr;
  }

  /* ---------------------------------------- handle drag and drop in filter "overlay-filter-div-overview" ---------------------------------------------------------------------------------------------------------------------- */

  handleDragEnd = (event) => {
    //gets trigged when drag of draggable object (in DndBox.jsx) ends
    const { active, over } = event;

    var oldFilterCondition = [...this.state.filterCondition];
    var newFilterCondition;

    if (over !== null) {
      if (over.id !== active.id) {
        const indexA = oldFilterCondition.findIndex(
          (item) => item.id === active.id
        );

        // index over
        const indexO = oldFilterCondition.findIndex(
          (item) => item.id === over.id
        );

        newFilterCondition = this.changeObjectPosition(
          oldFilterCondition,
          indexA,
          indexO
        );
      } else {
        newFilterCondition = oldFilterCondition;
      }
    }
    this.setState({ filterCondition: newFilterCondition });
  };

  changeObjectPosition(array, currentIndex, targetIndex) {
    // Überprüfe, ob die Indizes im gültigen Bereich liegen
    if (
      currentIndex < 0 ||
      currentIndex >= array.length ||
      targetIndex < 0 ||
      targetIndex >= array.length ||
      currentIndex === targetIndex
    ) {
      return array;
    }

    // Entferne das Objekt an der aktuellen Position

    var [objectToMove] = array.splice(currentIndex, 1);

    // Füge das Objekt an der neuen Position ein
    array.splice(targetIndex, 0, objectToMove);

    return array;
  }

  /* ---------------------------------------- dropdown function ---------------------------------------------------------------------------------------------------------------------- */

  calculateTextFieldWidth = () => {
    const inputField = this.textFieldRef.current;
    if (inputField) {
      const computedStyle = window.getComputedStyle(inputField);
      const minWidth = parseInt(computedStyle.minWidth, 10);
      const scrollWidth = inputField.scrollWidth;
      const width = Math.max(minWidth, scrollWidth);
      inputField.style.width = width + "px";
      this.setState({ textfieldWidth: width });
    }
  };

  displayFirstColumn = (filename, selectValueColumn, location) => {
    //returns the first 10 entrys of fiting column

    //returns the first 10 entrys of fiting column
    const searchId = this.state.searchId + 1;

    this.setState({ searchId: searchId, loadingSearchResults: true }, () => {
      if (location === "index") {
        this.displayFirstColumnDexie(filename, selectValueColumn, searchId);
      } else if (location === "mongo") {
        this.displayFirstColumnMongo(
          filename,
          selectValueColumn,
          searchId,
          this.props.dbName
        );
      }
    });
  };

  displayFirstColumnDexie = (filename, selectValueColumn, searchId) => {
    const dbSearch = new fileColumnSearchbar(this.props.dbName, filename);
    dbSearch
      .firstUniqueValues(selectValueColumn)
      .then((data) => {
        if (this.state.searchId === searchId) {
          var columnIsDate = false;
          if (data.length > 0) {
            if (data[0] instanceof Date) {
              columnIsDate = true;
            }
          }
          this.setState({
            searchResults: data,
            loadingSearchResults: false,
            columnIsDate: columnIsDate,
          });
        }
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  displayFirstColumnMongo = async (
    filename,
    selectValueColumn,
    searchId,
    dbName
  ) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/getFileColumnSearchbar",
        {
          params: {
            dbName: dbName,
            collectionName: filename,
            indexName: selectValueColumn,
          },
        }
      );

      if (response.data.status === "success") {
        const data = response.data.data;
        if (this.state.searchId === searchId) {
          this.setState({
            searchResults: data,
            loadingSearchResults: false,
            columnIsDate: response.data.date,
          });
        }
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  performSearch = async (searchTerm, filename, selectValueColumn, location) => {
    if (searchTerm.toString().trim() === "") {
      this.displayFirstColumn(filename, selectValueColumn, this.props.location);
      return;
    }

    const searchId = this.state.searchId + 1;

    this.setState({ searchId: searchId, loadingSearchResults: true }, () => {
      if (location === "index") {
        this.performSearchDexie(
          searchTerm,
          filename,
          selectValueColumn,
          searchId
        );
      } else if (location === "mongo") {
        this.performSearchMongo(
          searchTerm,
          filename,
          selectValueColumn,
          searchId
        );
      }
    });
  };

  performSearchDexie = async (
    searchTerm,
    filename,
    selectValueColumn,
    searchId
  ) => {
    const dbSearch = new fileColumnSearchbar(this.props.dbName, filename);
    dbSearch
      .searchDatabase(selectValueColumn, searchTerm)
      .then((data) => {
        if (this.state.searchId === searchId) {
          this.setState({
            searchResults: data,
            loadingSearchResults: false,
          });
        }
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Einträge:", error);
      });
  };

  performSearchMongo = async (
    searchTerm,
    filename,
    selectValueColumn,
    searchId
  ) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SERVER_PORT +
          "/searchByStartsWith",
        {
          params: {
            dbName: this.props.dbName,
            collectionName: filename,
            indexName: selectValueColumn,
            searchValue: searchTerm,
          },
        }
      );
      if (response.data.status === "success") {
        if (this.state.searchId === searchId) {
          this.setState({
            searchResults: response.data.data,
            loadingSearchResults: false,
          });
        } else {
          this.setState({
            searchResultsEvent: [],
            loadingSearchResults: false,
          });
        }
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  sortAlphabetically(arr) {
    return arr.sort((a, b) => a.localeCompare(b));
  }

  /* ---------------------------------------- react functions ---------------------------------------------------------------------------------------------------------------------- */

  componentDidMount() {
    //set all importen states for naviagtion -> after they are set component will be shown
    var loadedFiles = [];
    var openList = {};
    var columnsOfFile = {};

    for (let i = 0; i < this.props.loadedFileList.length; i++) {
      if (
        this.props.loadedFileList[i].name === "patients" ||
        this.props.loadedFileList[i].indices.includes("PATIENTID") ||
        this.props.loadedFileList[i].indices.includes("PATIENT")
      ) {
        loadedFiles.push(this.props.loadedFileList[i].name);

        openList[this.props.loadedFileList[i].name] = false;

        columnsOfFile[this.props.loadedFileList[i].name] =
          this.props.loadedFileList[i].indices.filter(
            (item) =>
              item !== "CONDITIONS" && item !== "AGESIMPLE" && item !== "_id_"
          );
      }
    }

    this.setState(
      {
        filterFileList: loadedFiles,
        openFilterList: openList,
        columnsOfFile: columnsOfFile,
      },
      () => this.setState({ showPage: true })
    );

    // for the dropdown menu that shows the search results
    this.calculateTextFieldWidth();
    window.addEventListener("resize", this.calculateTextFieldWidth);

    //get all filter criterions from loacl storage and adds them to the list off filters, the criterions are not checked/active -> functions as suggestion
    //only the 5 lastest filters per file will be added
    this.setState({ conditionsObject: this.returnSavedFilterCriterions() });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isOverlayOpen !== this.state.isOverlayOpen) {
      if (this.state.isOverlayOpen) {
        this.calculateTextFieldWidth();
      }
    }

    if (prevState.conditionsObject !== this.state.conditionsObject) {
      //gives filter conditions to app component so it can perform the filtering of data
      if (this.state.didComponetJustMount) {
        this.setState({ didComponetJustMount: false });
      } else {
        //only triggers if its not the first time the component is loading the saved criterions
        this.props.getFilterFormOverview(this.state.conditionsObject);
      }
    }

    //triggers when filters are loading -> scroll to the top of the table
    if (prevProps !== this.props.filterLoading && this.props.filterLoading) {
      this.scrollToTop();
    }
  }

  componentWillUnmount() {
    //clear time out of display search results
    window.removeEventListener("resize", this.calculateTextFieldWidth);
    clearTimeout(this.timeout);
  }

  render() {
    const { filterOperators } = this.state;
    if (this.state.showPage) {
      return (
        <div className="overview-main-box">
          <Overlay
            isOpen={this.state.isOverlayRawTableOpen}
            onClose={() => this.openRawTable()}
            title={this.state.openFileFilter + " CSV-File"}
          >
            <RawTable
              openFile={this.state.openFileFilter}
              dbName={this.props.dbName}
              detailedDBLocation={this.props.detailedDBLocation}
              style={{ width: "98vw", height: "85vh", overflowY: "scroll" }}
              location={this.props.location}
            />
          </Overlay>
          <Overlay
            isOpen={this.state.isOverlayOpenCrit}
            onClose={() => this.openSavedCriterions()}
            title={"Manage filter criterions"}
          >
            <OverviewSavedCriterions
              loadedFileList={this.props.loadedFileList}
              openFileFilter={this.state.openFileFilter}
              savedCriterions={this.returnAllFilterCriterions()}
              displayedCriterions={this.state.conditionsObject}
              hideCriterion={this.hideFilterOverlay}
              deleteFilterCriterion={this.deleteFilterCriterion}
              addFilterCriterionFromLocal={this.addFilterCriterionFromLocal}
            />
          </Overlay>
          <Overlay
            isOpen={this.state.isOverlayOpen}
            onClose={() => this.openOverlay()}
            title={
              "Add " + this.state.openFileFilter + "-file filter criterion"
            }
          >
            <div style={{ height: "30vh", minWidth: "50vw" }}>
              <div className="overlay-head-filter">
                <div style={{ display: "flex" }}>
                  <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                    <InputLabel id="columnSelect">Column</InputLabel>
                    <Select
                      labelId="columnSelect"
                      id="columnSelect"
                      name={"column"}
                      value={this.state.filterArgument.data.column}
                      label="Column"
                      onChange={this.handleChange}
                      autoWidth
                      size="small"
                    >
                      {this.state.columnsOfFile[this.state.openFileFilter].map(
                        (column) => (
                          <MenuItem value={column} key={column}>
                            {column}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 110 }} size="small">
                    <InputLabel id="operatorSelect">Operator</InputLabel>
                    <Select
                      labelId="operatorSelect"
                      id="operatorSelect"
                      name={"operator"}
                      value={this.state.filterArgument.data.operator}
                      label="Column"
                      onChange={this.handleChange}
                      autoWidth
                      size="small"
                    >
                      <MenuItem
                        value="="
                        key="="
                        disabled={this.state.columnIsDate ? true : false}
                      >
                        =
                      </MenuItem>
                      <MenuItem
                        value="!="
                        key="!="
                        disabled={this.state.columnIsDate ? true : false}
                      >
                        &ne;
                      </MenuItem>
                      <MenuItem value=">=" key=">=">
                        &ge;
                      </MenuItem>
                      <MenuItem value="<=" key="<=">
                        &le;
                      </MenuItem>
                      <MenuItem value=">" key=">">
                        &gt;
                      </MenuItem>
                      <MenuItem value="<" key="<">
                        &lt;
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box position="relative">
                    {this.state.columnIsDate ? (
                      <DatePicker
                        selected={this.state.filterArgument.data.value}
                        onChange={this.handleDate}
                        name="value"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="choose date (yyyy-mm-dd)"
                      />
                    ) : (
                      <TextField
                        ref={this.textFieldRef}
                        id="valueInput"
                        label="Value"
                        name="value"
                        variant="outlined"
                        value={this.state.filterArgument.data.value}
                        onBlur={() => this.setState({ showDropdown: false })}
                        onFocus={() => this.setState({ showDropdown: true })}
                        onChange={this.handleChange}
                        disabled={
                          this.state.filterArgument.data.column !== ""
                            ? false
                            : true
                        }
                        sx={{ m: 1, minWidth: 80 }}
                        size="small"
                      />
                    )}
                    {this.state.showDropdown && (
                      <Box
                        className="dropdown-box"
                        style={{ width: this.state.textfieldWidth + "px" }}
                        ref={this.dropdownRef}
                      >
                        <Divider variant="middle" />
                        {this.state.searchResults.length === 0 &&
                          !this.state.loadingSearchResults && (
                            <MenuItem name="value" value="" key="">
                              no fitting value found
                            </MenuItem>
                          )}

                        {!this.state.loadingSearchResults ? (
                          this.state.searchResults.map((result, index) =>
                            this.state.filterArgument.data.column !== "AGE" ? (
                              <MenuItem
                                key={result + index}
                                id="value"
                                value={result}
                                onMouseDown={this.handleChange}
                              >
                                {result instanceof Date
                                  ? this.formatDate(result)
                                  : result}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                key={result.years + index}
                                id="value"
                                value={result.years}
                                onMouseDown={this.handleChange}
                              >
                                {result.years}
                              </MenuItem>
                            )
                          )
                        ) : (
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress size={25} />
                          </div>
                        )}
                      </Box>
                    )}
                  </Box>
                </div>
                <ButtonGroup
                  variant="contained"
                  aria-label="Basic button group"
                  size="small"
                >
                  <Button onClick={this.addArgument} size="small">
                    Add argument
                  </Button>
                  <Button onClick={this.addBrackets} size="small">
                    Add brackets
                  </Button>
                  <Button onClick={this.removeItem} size="small">
                    Remove item
                  </Button>
                </ButtonGroup>
              </div>
              {this.state.warningOverlay !== "" && (
                <p style={{ color: "rgb(156, 156, 0)" }}>
                  {this.state.warningOverlay}
                </p>
              )}
              <DndBox
                argumentArray={this.state.filterCondition}
                operatorArray={filterOperators}
                handleDragEnd={this.handleDragEnd}
                handleOperatorMouseOver={this.handleOperatorMouseOver}
                openSelect={this.openSelect}
                handleOperatorSelect={this.handleOperatorSelect}
                countOperator={this.state.countOperator}
                setCountOperator={this.setCountOperator}
                setActiveItem={this.chooseItem}
                activeItem={this.state.divOnFocus}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => this.openRawTable()}>
                  Look at file
                </Button>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="criterionName"
                    label="criterion name"
                    variant="outlined"
                    size="small"
                    onChange={this.handleNameChange}
                    value={this.state.filterConditionName}
                  />

                  <Button onClick={this.addFilterConditionToArray}>
                    Add {this.state.edit && "as new"}
                  </Button>
                  <Button onClick={this.addToConditionArrayAndClose}>
                    Add {this.state.edit && "as new"} & Close
                  </Button>
                  {this.state.edit && (
                    <Button onClick={this.editSave}>Save</Button>
                  )}
                </div>
              </div>
            </div>
          </Overlay>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
              marginRight: "15px",
              padding: "20px",
              borderRadius: "20px",
              marginTop: "1vh",
            }}
          >
            {/* Navigation-------------------------------------------------------------------------------------------------------------*/}

            <div
              style={{
                height: "100%",
                width: "20vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#4361ee",
                  minHeight: "45px",
                }}
              >
                {/* Navigation Head-------------------------------------------------------------------------------------------------------------*/}
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-adjustments-alt"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 8h4v4h-4z" />
                    <path d="M6 4l0 4" />
                    <path d="M6 12l0 8" />
                    <path d="M10 14h4v4h-4z" />
                    <path d="M12 4l0 10" />
                    <path d="M12 18l0 2" />
                    <path d="M16 5h4v4h-4z" />
                    <path d="M18 4l0 1" />
                    <path d="M18 9l0 11" />
                  </svg>
                  <p
                    style={{
                      fontSize: "23px",
                      fontWeight: 350,
                      marginRight: "0.5vw",
                    }}
                  >
                    Filter
                  </p>
                  <InfoBtnToolTip
                    title={
                      <React.Fragment>
                        <p className="mb-2">
                          Here you can filer the patient list. Select the tab of
                          the file in which the desired information is located
                          and click on the + button to add a new filter.{" "}
                        </p>
                        <p className="mb-2">
                          All active filters are linked by the AND operator.{" "}
                        </p>
                        <p
                          className="mb-2"
                          style={{ color: "var(--primary-color)" }}
                        >
                          {" "}
                          For more information, please refer to chapter 3.2.1 of
                          the manual.{" "}
                        </p>
                        <p>
                          <span
                            style={{
                              color: "var(--primary-color)",
                              fontWeight: 500,
                            }}
                          >
                            Tip:
                          </span>{" "}
                          Hover the mouse over the name of your filter criterion
                          to see a tooltip displaying the filter term.
                        </p>
                      </React.Fragment>
                    }
                    placement="right-start"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "45px",
                  }}
                >
                  <Collapse
                    orientation="horizontal"
                    in={this.state.openSearchBar}
                  >
                    <TextField
                      label="search"
                      id="standard-size-small"
                      size="small"
                      variant="standard"
                      value={this.props.searchValueOverview}
                      onChange={this.props.overviewSearchValue}
                      sx={{ marginLeft: "15px" }}
                    />
                  </Collapse>
                  <IconButton
                    sx={{
                      color: "#4361ee",
                      height: "30px",
                      width: "30px",
                      marginTop: "20px",
                    }}
                    onClick={this.openSearchBar}
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                  height: "69vh",
                  paddingRight: "10px",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader
                      component="div"
                      id="nested-list-subheader"
                      style={{ textAlign: "left", fontFamily: "Spline Sans" }}
                    >
                      Filter criterions
                    </ListSubheader>
                  }
                >
                  {this.sortAlphabetically(this.state.filterFileList).map(
                    (option, index) => (
                      <div key={"listOption" + option}>
                        {index === 0 && <Divider />}
                        <ListItemButton
                          onClick={() => this.handleClickFilterList(option)}
                        >
                          <ListItemText
                            primary={
                              option.charAt(0).toUpperCase() + option.slice(1)
                            }
                          />
                          {this.state.openFilterList[option] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Divider />
                        <Collapse
                          in={this.state.openFilterList[option]}
                          timeout="auto"
                          unmountOnExit
                          sx={{ paddingLeft: "7px" }}
                        >
                          {this.state.conditionsObject[option] !== undefined &&
                            this.state.conditionsObject[option].map(
                              (condition, index) => (
                                <ListItem
                                  key={option + index}
                                  onMouseOver={() =>
                                    this.handleListHover(option, index)
                                  }
                                  onMouseLeave={() =>
                                    this.handleMouseLeave(option, index)
                                  }
                                  disablePadding
                                  style={{ paddingLeft: "20px" }}
                                >
                                  <ListItemIcon dense>
                                    <Checkbox
                                      edge="start"
                                      name={condition.name}
                                      onClick={() =>
                                        this.handleListCheckbox(option, index)
                                      }
                                      checked={condition.checked}
                                      disableRipple
                                      inputProps={{ "aria-labelledby": option }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText>
                                    <Tooltip
                                      title={this.diplayFilterTooltip(
                                        condition
                                      )}
                                      enterDelay={800}
                                      arrow
                                      followCursor
                                    >
                                      <span>{condition.name}</span>
                                    </Tooltip>
                                  </ListItemText>
                                  {condition.showDelete && (
                                    <IconButton
                                      edge="end"
                                      aria-label="comments"
                                      size="small"
                                      onClick={() =>
                                        this.editCrit(condition, index, option)
                                      }
                                    >
                                      <EditIcon size="small" />
                                    </IconButton>
                                  )}
                                  {condition.showDelete && (
                                    <IconButton
                                      edge="end"
                                      aria-label="comments"
                                      size="small"
                                      onClick={() =>
                                        this.hideFilterCriterion(option, index)
                                      }
                                    >
                                      <VisibilityOffIcon size="small" />
                                    </IconButton>
                                  )}
                                  {condition.showDelete && (
                                    <IconButton
                                      edge="end"
                                      aria-label="comments"
                                      size="small"
                                      onClick={() =>
                                        this.deleteFilterCriterion(
                                          option,
                                          condition.id
                                        )
                                      }
                                    >
                                      <DeleteIcon size="small" />
                                    </IconButton>
                                  )}
                                </ListItem>
                              )
                            )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px",
                            }}
                          >
                            <Button
                              onClick={() => this.openSavedCriterions(option)}
                            >
                              manage criterions
                            </Button>
                            <div style={{ display: "flex" }}>
                              <IconButton
                                aria-label="add"
                                onClick={() => this.openRawTable(option)}
                              >
                                <DatasetLinkedIcon />
                              </IconButton>
                              <IconButton
                                aria-label="add"
                                onClick={() => this.openOverlay(option)}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </div>
                          </div>
                        </Collapse>

                        {this.state.openFilterList[option] && <Divider />}
                      </div>
                    )
                  )}
                </List>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Active filters: {this.state.activeCriterions}</p>
                <p>
                  Macting patients: {this.props.patientList.length} /{" "}
                  {this.props.numbPatients}
                </p>
              </div>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginLeft: "1vw",
                marginRight: "1vw",
              }}
            />
            <Paper
              style={{
                overflow: "hidden",
                position: "relative",
                width: "90vw",
                marginRight: "15px",
                overflow: "hidden",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              ref={this.containerRefStartParent}
            >
              <Backdrop
                sx={{
                  color: "#4169e1",
                  position: "absolute",
                  backgroundColor: "rgba(255, 255, 255, 0.623)",
                }}
                open={this.props.filterLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <TableContainer
                sx={{
                  fontSize: "14px",
                  flexGrow: 1,
                  overflowY: "auto",
                }}
              >
                <Table stickyHeader aria-label="customized table" size="small">
                  <TableHead ref={this.containerRefStart}>
                    <TableRow>
                      {this.state.tableHead.map((data) => (
                        <StyledTableCell
                          key={data.display}
                          sortDirection={
                            this.state.orderBy === data.sort
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={this.state.orderBy === data.sort}
                            direction={
                              this.state.orderBy === data.sort
                                ? this.state.order
                                : "asc"
                            }
                            onClick={this.handleRequestSort(data.sort)}
                            sx={{ fontFamily: "Spline Sans" }}
                          >
                            <p
                              style={{
                                marginTop: "8px",
                                marginBottom: "8px",
                              }}
                            >
                              {data.display}
                            </p>
                          </TableSortLabel>
                          {data.display === "Age" && (
                            <InfoBtnToolTip
                              title={
                                <React.Fragment>
                                  <p className="mb-2">
                                    This is the patient's age at the patients
                                    death or end of the simulation.
                                  </p>
                                  <p>
                                    {this.props.patientList.length > 0 &&
                                      `The end of the simulation was estimated at ${this.props.patientList[0].Age.estimatedEndOfSimulation}`}
                                  </p>
                                </React.Fragment>
                              }
                              placement="right-start"
                              color="white"
                            />
                          )}
                          {data.display === "Important Diagnoses" && (
                            <InfoBtnToolTip
                              title={
                                <React.Fragment>
                                  <p>
                                    Only 'interesting' diagnoses (conditions)
                                    that are important to you are displayed
                                    here. You can specify which diagnoses should
                                    be filtered out in the settings in the
                                    'Patient Records' tab.
                                  </p>
                                </React.Fragment>
                              }
                              placement="right-start"
                              color="white"
                            />
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.stableSort(
                      this.props.patientList,
                      this.getComparator(this.state.order, this.state.orderBy)
                    )
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((patient) => (
                        <StyledTableRow
                          key={patient.LAST}
                          onClick={() => this.onClick(patient)}
                        >
                          <StyledTableCell
                            name="Name"
                            style={{ width: "250px" }}
                          >
                            {patient.Name}
                          </StyledTableCell>
                          <StyledTableCell name="Gender">
                            {patient.Gender}
                          </StyledTableCell>
                          <StyledTableCell name="Birthdate">
                            {this.formatDate(patient.Birthdate)}
                          </StyledTableCell>
                          <StyledTableCell name="Deathdate">
                            {this.checkForDeathDate(
                              this.formatDate(patient.Deathdate)
                            )}
                          </StyledTableCell>
                          <StyledTableCell name="Age">
                            {patient.Age.years}
                          </StyledTableCell>
                          <StyledTableCell name="State">
                            {patient.State}
                          </StyledTableCell>
                          {patient.ImportantDiagnoses !== undefined ? (
                            <StyledTableCell
                              name="Important Diagnoses"
                              className="td-diagnosis"
                            >
                              {patient.ImportantDiagnoses}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              name="Important Diagnoses"
                              className="td-diagnosis"
                            >
                              -
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <div style={{ height: "35px" }}>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    30,
                    50,
                    100,
                    200,
                    500,
                    { label: "All", value: -1 },
                  ]}
                  component="div"
                  colSpan={this.state.tableHead.length}
                  count={this.props.patientList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  selectprops={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    ".MuiTablePagination-displayedRows": {
                      "margin-top": "0",
                      "margin-bottom": "0",
                    },
                    ".MuiTablePagination-selectLabel": {
                      "margin-top": "0",
                      "margin-bottom": "0",
                    },
                    ".MuiTablePagination-toolbar": {
                      minHeight: "35px",
                      height: "35px",
                    },
                  }}
                />
              </div>
            </Paper>
          </Paper>
        </div>
      );
    } else {
      return <div>Error while rendering overview component. Data missing.</div>;
    }
  }
}

/* ---------------------------------------- extra functions needed for MUI Library  ---------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* ---------------------------------------- table  ---------------------------------------------------------------------------------------------------------------------- */

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const btnStyle = { height: "30px", width: "30px" };

  return (
    <Box
      sx={{
        flexShrink: 0,
        ml: 2.5,
        marginTop: "0",
        marginBottom: "0",
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        sx={btnStyle}
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primary-color)",
    color: theme.palette.common.white,
    fontFamily: "Spline Sans",
    fontWeight: 380,
    fontSize: 17,
    height: "7vh",
    lineHeight: "4vh",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontFamily: "Spline Sans",
    fontWeight: 350,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#aabcf2a8",
    cursor: "pointer",
  },
}));
