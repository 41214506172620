// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts-options-div {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  max-width: 80vw;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  align-items: center;
  min-height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/PatientRecords/Charts/Charts.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,YAAY;EACZ,eAAe;EACf,uBAAkB;EAAlB,kBAAkB;EAClB,qCAAqC;EACrC,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".charts-options-div {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  padding: 6px;\n  max-width: 80vw;\n  width: fit-content;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  border-radius: 16px;\n  align-items: center;\n  min-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
