import React, { Component } from "react";
import Overlay from "./Overlay/Overlay";
import DataUploadPage from "./DataUpload/DataUploadPage";
import { Link } from "react-router-dom";
import { BackgroundGradientAnimation } from "./AceternityComponents/background-gradient-animation";
import { FloatingNav } from "./AceternityComponents/floating-navbar";
import Divider from "@mui/material/Divider";

import iccasLogo from "./data/iccas_logo.jpg";
import logoUniLeipzig from "./data/logoUniLeipzig.jpg";
import NFDI4Logo from "./data/NFDI4DS_Logo.png";

import manulePic31Pic1 from "./data/pictures/manule-3.1-pic1.png";
import manulePic31Pic2 from "./data/pictures/manule-3.1-pic2.png";
import manulePic32Pic1 from "./data/pictures/manule-3.2-pic1.png";
import manulePic33Pic1 from "./data/pictures/manule-3.3-pic1.png";
import manulePic33Pic2 from "./data/pictures/manule-3.3-pic2.png";
import manulePic33Pic3 from "./data/pictures/manule-3.3-pic3.png";
import manulePic33Pic4 from "./data/pictures/manule-3.3-pic4.png";

import "./StartPage.css";

import { withRouter } from "./utils/withRouter";

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
      openDataset: false,
      openDB: "",
    };
  }

  scrollToAnchor = (elementId) => {
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  openNewTab = (link) => {
    window.open(link, "_blank");
  };

  openOverlay = () => {
    //prevent data uploaded to close while new dataset is loading
    if (!this.state.isOverlayOpen) {
      this.setState({ isOverlayOpen: true });
    } else {
      this.setState({ isOverlayOpen: false });
    }
  };

  navigateNav = (item) => {
    console.log(item);
    if (item.typ === "id") {
      this.scrollToAnchor(item.id);
    } else if (item.typ === "link") {
      this.openNewTab(item.link);
    } else if (item.typ === "linkIntern") {
      this.props.navigate(item.linkIntern);
    }
  };

  getNewDBName = (dbName) => {
    if (this.state.openDataset) {
      this.setState({ openDB: dbName });
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loadingProgress !== this.props.loadingProgress) {
      this.setState({ openDataset: true });
    }

    if (prevState.openDB !== this.state.openDB && this.state.openDB !== "") {
      this.props.navigate("/syn-viewer/overview-dataset");
      this.props.openNewDb(this.state.openDB);
    }
  }

  render() {
    return (
      <div className="startPage-wrapper">
        <FloatingNav
          navItems={[
            {
              name: "About us",
              typ: "id",
              id: "about-us-head",
            },
            {
              name: "How it works",
              typ: "id",
              id: "how-it-works-head",
            },
            {
              name: "Synthea",
              typ: "link",
              link: "https://github.com/synthetichealth/synthea/wiki",
            },
            {
              name: "SynViewer",
              typ: "linkIntern",
              linkIntern: "/syn-viewer/overview-dataset",
            },
            {
              name: "Contact",
              typ: "id",
              id: "contact-head",
            },
          ]}
          collapseOpen={this.state.isCollapsedNav}
          location={this.props.location}
          navigate={this.navigateNav}
        />
        <div
          style={{
            height: "105vh",
            position: "relative",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowX: "hidden",
            }}
          >
            <BackgroundGradientAnimation>
              <div className="absolute z-50 inset-0 pointer-events-none">
                <div className="mainDivStartPage">
                  <div className="navigationStartPage">
                    <div
                      className="flexbox-item"
                      onClick={() => this.scrollToAnchor("about-us-head")}
                    >
                      About us
                    </div>
                    <div
                      className="flexbox-item"
                      onClick={() => this.scrollToAnchor("how-it-works-head")}
                    >
                      How it works
                    </div>
                    <div
                      className="flexbox-item"
                      onClick={() =>
                        this.openNewTab(
                          "https://github.com/synthetichealth/synthea/wiki"
                        )
                      }
                    >
                      Synthea
                    </div>
                    <div
                      className="flexbox-item"
                      onClick={() => this.scrollToAnchor("contact-head")}
                    >
                      Contact
                    </div>
                  </div>
                  <div className="heading">SynViewer</div>
                  <div className="subHeading">
                    This is a free open-source tool, that helps you to debug
                    your Synthea modules and visualize Synthea-generated patient
                    data.
                  </div>
                  <div className="btn-flexbox">
                    <Link to="/syn-viewer/overview-dataset">
                      <button className="btnToDemo">Look at demo data</button>
                    </Link>
                    <div className="spacer-btn-flex-div"></div>
                    <button
                      className="btnToDemo"
                      onClick={() => this.openOverlay()}
                    >
                      Upload your own data
                    </button>
                    <Overlay
                      isOpen={this.state.isOverlayOpen}
                      onClose={() => this.openOverlay()}
                      title="Data Import"
                      loadingDone={
                        this.props.loadingProgressSimple.writingInDBDone
                      }
                    >
                      <div className="pointer-events-auto">
                        <DataUploadPage
                          getSavedDataId={this.props.getSavedDataId}
                          getImportedDataset={this.props.getImportedDataset}
                          closeOverlay={this.openOverlay}
                          getNewDBName={this.getNewDBName}
                          getAllEntries={this.props.getAllEntries}
                          csvFiles={this.props.csvFiles}
                          parseCSVData={this.props.parseCSVData}
                          loadingProgressSimple={
                            this.props.loadingProgressSimple
                          }
                          creatingDBProgress={this.props.creatingDBProgress}
                          onClose={this.openOverlay}
                          parseLoadingStatus={this.props.parseLoadingStatus}
                          addingDataStatus={this.props.addingDataStatus}
                          filesFullLoaded={this.props.filesFullLoaded}
                          connectionToMongoDB={this.props.connectionToMongoDB}
                        />
                      </div>
                    </Overlay>
                  </div>
                </div>
              </div>
            </BackgroundGradientAnimation>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "10vh",
            }}
          >
            <div className="h-[10vh] bg-gradient-to-b from-transparent from-20% to-white"></div>
          </div>
        </div>
        <div
          className="subHeading"
          style={{
            position: "absolute",
            width: "100%",
            marginTop: "2px",
            fontSize: "19px",
            letterSpacing: "0px",
          }}
        >
          sponsored by:
        </div>
        <div className="logo-box">
          <img
            src={logoUniLeipzig}
            className="logo-start-uni"
            style={{ cursor: "pointer" }}
            onClick={() => this.openNewTab("https://www.uni-leipzig.de/")}
          ></img>
          <div style={{ width: "4vh" }}></div>
          <div
            style={{
              width: "20vw",
              height: "18vh",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              src={iccasLogo}
              className="logo-start-iccas"
              style={{ cursor: "pointer" }}
              onClick={() => this.openNewTab("https://www.iccas.de/")}
            ></img>
          </div>
          <div style={{ width: "4vh" }}></div>
          <div
            style={{
              width: "20vw",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img
              src={NFDI4Logo}
              className="logo-start-nfdi4"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.openNewTab("https://www.nfdi4datascience.de/")
              }
            ></img>
          </div>
        </div>
        <Divider
          style={{
            marginTop: "calc(20vh + 80px)",
            width: "60vw",
            marginLeft: "20vw",
          }}
          variant="middle"
        />
        <div className="about-us-div">
          <p
            className="subHeading"
            style={{
              fontWeight: "390",
              fontSize: "30px",
              marginBottom: "20px",
            }}
            id="about-us-head"
          >
            ABOUT US
          </p>
          <p className="start-text">
            We are a small team of developers from the Innovation Center
            Computer Assisted Surgery based in Leipzig, Germany, who have worked
            with Synthea in the past and developed some modules ourselves.
            Synthea is a synthetic patient generator that models the medical
            history of synthetic patients. We found it difficult to quickly
            understand the data generated by Synthea and to check that the
            information we wanted to display was correctly mapped. So we set out
            to develop a program that would allow us to look at the medical
            records of individual patients, and also to examine the generated
            population for survival and the distribution of variables. We want
            to give Synthea users a better understanding of their data and make
            it easier for you to debug your modules.
          </p>
        </div>
        <Divider
          style={{
            width: "60vw",
            marginLeft: "20vw",
          }}
          variant="middle"
        />
        <div className="how-it-works-div">
          <p
            className="subHeading"
            style={{ fontWeight: "390", fontSize: "30px" }}
            id="how-it-works-head"
          >
            HOW IT WORKS
          </p>
          <p className="heading-startpage">1. What is Synthea?</p>
          <p className="first-text">
            SyntheaTM is a synthetic patient generator that models the medical
            history of synthetic patients. Their goal is to output high-quality
            synthetic, realistic but not real, patient data and associated
            health records covering every aspect of healthcare. Their resulting
            data is free from cost, privacy, and security restrictions. It can
            be used without restriction for a variety of secondary uses in
            academia, research, industry, and government.
          </p>
          <p className="absatz">
            To achieve this, individual diseases are represented in Synthea in
            so-called disease modules. These contain both the statistical
            characteristics of the disease as well as the individual course of
            the disease experienced by patients in the form of a tree diagram.
          </p>
          <p className="absatz">
            Synthea currently has over 90 different modules, such as Essential
            Hypertension, Diabetes Mellitus, COPD and many more. To built your
            own module you can visit the{" "}
            <span
              style={{
                color: "var(--primary-color)",
                cursor: "pointer",
                fontWeight: 400,
                borderBottom: "1px solid var(--primary-color)",
              }}
              onClick={() =>
                this.openNewTab(
                  "https://synthetichealth.github.io/module-builder/"
                )
              }
            >
              Synthea Module Builder
            </span>
            .
          </p>
          <p className="absatz">
            For more information on how Synthea works, visit the official{" "}
            <span
              style={{
                color: "var(--primary-color)",
                cursor: "pointer",
                fontWeight: 400,
                borderBottom: "1px solid var(--primary-color)",
              }}
              onClick={() =>
                this.openNewTab(
                  "https://github.com/synthetichealth/synthea/wiki"
                )
              }
            >
              Synthea GitHub
            </span>{" "}
            page.
          </p>
          <p className="heading-startpage">2. What is the SynViewer?</p>
          <p className="first-text">
            The SynViewer is a program for visualizing data generated by
            Synthea.
          </p>
          <p className="absatz">
            It allows you to explore each patient's disease progression in
            different views and to better understand how your modules affect
            each patient's medical history. You can also see the probability of
            each patient being generated by your modules, or how often your
            module generates the exact patient in question.
          </p>
          <p className="absatz">
            You can also analyse your dataset at a population level and
            calculate distributions of variables and survival.
          </p>
          <p className="absatz">
            You will find instructions on how to use the SynViewer in the next
            chapter (3. How do I use the SynViewer?).
          </p>
          <p className="heading-startpage">3. How do I use the SynViewer?</p>
          <p className="first-text">
            We provide two demo datasets that you can easily view by clicking on
            the "Look at demo data" (Button 1 in Figure 1) button on the home
            page or by downloading them from the{" "}
            <Link
              to="syn-viewer/datasets/datasets-overview"
              style={{
                color: "var(--primary-color)",
                cursor: "pointer",
                fontWeight: 400,
                borderBottom: "1px solid var(--primary-color)",
              }}
            >
              dataset manager
            </Link>
            . For the website to work, the datasets are downloaded and stored in
            a local indexed database in the browser. In order to use the site,
            it is therefore essential that the browser's security settings allow
            the site to access local memory. As all data is processed locally,
            the technical characteristics of the computer used will affect
            performance. It is recommended that you use a computer with
            sufficient RAM (32 GB). Currently the website is only optimized for
            1920x1080 screens. We recommend Firefox, Chrome or Opera as
            browsers.
          </p>
          <p className="subheading1-startpage">
            3.1 How can I view my own data?
          </p>
          <p className="first-text">
            Your dataset must meet the following criteria for the website to
            work:
          </p>
          <ul className="custom-list">
            <li>
              only CSV files that comply with the Synthea format are accepted
            </li>
            <li>
              recommended dataset size: 100 patients (max. 200 patients,
              processing of larger datasets is currently not possible)
            </li>
            <li>
              at least the encounters.csv and patients.csv files are required
            </li>
          </ul>
          <p className="absatz">
            To view your own dataset, either click on the "Upload your own data"
            (Button 2 in Figure 1) button on the home page or go to the{" "}
            <Link
              to="syn-viewer/datasets/datasets-overview"
              style={{
                color: "var(--primary-color)",
                cursor: "pointer",
                fontWeight: 400,
                borderBottom: "1px solid var(--primary-color)",
              }}
            >
              dataset manager
            </Link>{" "}
            and click on the large plus button (Button 4 in Figure 2). Depending
            on your computer and the size of your data, it may take several
            minutes to process your data.
          </p>
          <p className="absatz">
            You can then select your CSV files from the pop-up that appears.
            Please make sure you select at least the encounters.csv and
            patients.csv files. All other files are optional.
          </p>
          <div style={{ marginTop: "15px" }}>
            <img alt="" src={manulePic31Pic1} style={{ marginLeft: "1vw" }} />
            <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>Fig. 1:</span> Startpage, 1 -
              button that brings you to demo dataset, 2 - Button that let you
              load your own data
            </p>
            <img alt="" src={manulePic31Pic2} />
            <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
              <span style={{ fontWeight: 400 }}>Fig. 2:</span> Dataset manager,
              3 - Button that allows you to load already locally saved datasets,
              4 - button to load new datasets
            </p>
          </div>
          <p className="subheading1-startpage">3.2 Where can I find what?</p>
          <p className="first-text">
            You will first arrive at the dataset dashboard. Here you will find
            an overview of the key points of the open dataset. The following
            image (Fig. 3) shows a label with the functions of each button.
          </p>
          <img
            alt=""
            src={manulePic32Pic1}
            style={{ marginTop: "15px", marginBottom: "15px" }}
          />
          <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
            <span style={{ fontWeight: 400 }}>Fig. 3:</span> Dashboard
          </p>
          <ol style={{ marginLeft: "20px" }}>
            <li>
              1. Navigation, lets you change between dashboard, patient records
              and population analysis.
            </li>
            <li>2. Opens the Module Viewer</li>
            <li>3. opens dataset manager.</li>
            <li>4. opens settings.</li>
            <li>
              5. dashboard view of dataset details. Click on the box to open
              dataset manger.
            </li>
            <li>
              6. dashboard view of patient records. Click on the box to open
              patient records.
            </li>
            <li>
              7. dashboard view of population analysis. Click on the box to open
              population analysis.
            </li>
          </ol>
          <p className="subheading2-startpage">3.2.1 Patient Records</p>
          <p className="first-text">
            Here you can view the individual patient histories. On the left you
            will find the filter options, click on a patient in the list to open
            their record (Patient View).
          </p>
          <img
            alt=""
            src={manulePic33Pic1}
            style={{ marginTop: "15px", marginBottom: "15px" }}
          />
          <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
            <span style={{ fontWeight: 400 }}>Fig. 4:</span> Patient records -
            patient list
          </p>
          <ol style={{ marginLeft: "20px" }}>
            <li>1. Click here to perform a search with in the patient list.</li>
            <li>2. check box to turn filter options on and off</li>
            <li>
              3. opens pop up to manage visitable and hidden filter options
            </li>
            <li>4. opens the raw file in pop-up</li>
            <li>5. opens pop-up to add new filter option</li>
          </ol>
          <p className="absatz">
            You can use the filter to determine which patients are displayed
            (see below for two specific examples). You can filter on any column
            in any file. To add a new filter option, open the tab named after
            the CSV file containing the desired information and press the button
            with the plus sign (Fig. 4 button 5). This will open a pop-up window
            where you can create the filter option. A filter option can contain
            several arguments, which can be linked with either AND or OR. To add
            a new argument, first select the column containing the information
            (only then can you search for the value). The operator can be used
            to select when the argument returns true (e.g. the value of the
            column should be equal to or not equal to the specified value). The
            desired values that the column should or should not accept should be
            specified in the value. If you forgot how the file was structured
            when you created the filter option, you can click 'View File' to
            open the raw file and search within it. Each filter option requires
            a name.
          </p>
          <p className="absatz">
            All active filter options are linked by the AND operator (i.e. a
            patient will only be displayed if all active filter options apply).
          </p>
          <p className="absatz" style={{ fontWeight: 400 }}>
            Filter example 1 (simple): Show me only patients with laryngeal
            cancer
          </p>
          <img
            alt=""
            src={manulePic33Pic2}
            style={{ marginTop: "15px", marginBottom: "15px" }}
          />
          <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
            <span style={{ fontWeight: 400 }}>Fig. 5:</span> Filter options
            pop-up
          </p>
          <p className="absatz" style={{ fontWeight: 400 }}>
            Filter example 2 (complex): Show me only patients with systolic
            blood pressure above 140 mmHg
          </p>
          <img
            alt=""
            src={manulePic33Pic3}
            style={{ marginTop: "15px", marginBottom: "15px" }}
          />
          <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
            <span style={{ fontWeight: 400 }}>Fig. 6:</span> Filter options
            pop-up
          </p>
          <p className="absatz">
            All filter options created are saved locally. If you have saved a
            lot of options but do not want to delete any of them, you can hide
            the filter options. This means that they are no longer displayed,
            but are still stored locally. To display them again, click the
            'Manage Criteria' (Fig. 4 button 3) button. Deleted filter options
            cannot be restored.
          </p>
          <p className="absatz">
            The search function searches each column of the table (name, gender,
            date of birth, date of death, age, condition and important
            diagnosis) for the search term entered and returns matching
            patients. It is also possible to search by date. Please use the
            following format: yyyy-mm-dd.
          </p>
          <p className="absatz">
            <span style={{ fontWeight: 400 }}>Tip:</span> If you hover over the
            filter options name a tooltip will show the filter term.
          </p>
          <p className="subheading2-startpage">3.2.2 Patient View</p>
          <p className="first-text">
            When you click on a patient in the Patient Records, the patient is
            opened and displayed in the Patient View. The following screen shows
            all the controls with their function.
          </p>
          <img
            alt=""
            src={manulePic33Pic4}
            style={{ marginTop: "15px", marginBottom: "15px" }}
          />
          <p style={{ marginBottom: "20px", marginLeft: "10px" }}>
            <span style={{ fontWeight: 400 }}>Fig. 7:</span> Patient records -
            patient view
          </p>
          <ol style={{ marginLeft: "20px" }}>
            <li>1. See next patient in the list.</li>
            <li>2. See previous patient in the list</li>
            <li>3. Switch in between filter profiles.</li>
            <li>
              4. Safe current selection of include and exclude codes as filter
              profile.
            </li>
            <li>5. Delete currently active filter profile</li>
            <li>6. Add a code that should be included.</li>
            <li>7. Add a code that should be excluded.</li>
            <li>
              8. Select a reason code for which encounters should be included.
            </li>
            <li>9. Click to collapse the control box.</li>
            <li>
              10. Choose if all events of an included encounter should also be
              included.
            </li>
            <li>11. Slider to select the time period (in years) to include.</li>
            <li>12. Navigation to switch in between views.</li>
          </ol>
          <p className="absatz">
            On the left you will find some general information about the patient
            and filter options that determine what information is displayed in
            the right content box. If you do not want certain information
            (encounter, procedure, observation, etc.) to be displayed, click the
            plus button (Fig. 7, Button 6) in the exclude box and search for the
            description or code of the information you want to exclude. If you
            exclude an encounter, all the events it contains will also be
            excluded.{" "}
          </p>
          <p className="absatz">
            Conversely, only the events you select in the include box (Fig. 7,
            Button 7) will be displayed and all others will be excluded. If you
            select an encounter here and want to include all the events in the
            encounter, you need to check the 'include all encounter data' box
            (Fig. 7, Button 10). You can also include data by reason code only
            (Fig. 7, Button 8). All encounters with matching reason code and
            corresponding events will then be included.
          </p>
          <p className="absatz">
            If you have codes in both the Include and Exclude fields, all the
            data in the Include field will be included first, and then the data
            in the Exclude field will be excluded.
          </p>
          <p className="absatz">
            You can also save your current selection of filter criteria as a
            filter profile. To do this, click the download icon (Fig. 7, Button
            4). You will then be asked to name your profile. To save the
            profile, click the save icon. You can switch between the different
            filter profiles in select box (Fig. 7, Button 3). To delete a
            profile, select it in the select box and click the delete icon (Fig.
            7, Button 5: only the currently active filter profile can be
            deleted).
          </p>
          <p className="absatz">
            <span style={{ fontWeight: 400 }}>Tip:</span> hover over the code in
            the include or exclude box to see the corresponding description
          </p>
          <p className="absatz">
            Use the slider to select the time period (in years) to include (Fig.
            7, slider 11).
          </p>
          <p className="absatz">
            On the right hand side is the content box in which the data is
            displayed. Here you can choose between 5 different views (Fig. 7,
            navigation 12):
          </p>
          <ul className="custom-list">
            <li>Dashboard: provides an overview of the patient</li>
            <li>
              Data View: displays all patient data in tabular form, organised by
              CSV file
            </li>
            <li>
              History view: associates patient data with their encounters and
              displays them in chronological order
            </li>
            <li>Timeline: displays elapsed events graphically as a timeline</li>
            <li>
              Charts: allows numerical observations to be displayed in charts
            </li>
          </ul>
          <p className="subheading2-startpage">3.2.3 Population analysis</p>
          <p className="first-text">
            This part of the site is currently under construction. Later it will
            be possible to analyse your datasets on a population basis and
            calculate survival and distribution of selected variables.
          </p>
          <p className="subheading2-startpage">3.2.4 Module Viewer</p>
          <p className="first-text">
            This part of the website is in the early stages of development. You
            can already upload and view your own Synthea modules. Later it will
            be possible to view the course of selected patients through the
            selected module.
          </p>
          <p className="subheading2-startpage">3.2.5 Dataset manager</p>
          <p className="first-text">
            With the dataset manager you can manage and switch between your
            datasets. To do this, click on the green ‘Load’ button (Fig. 8,
            Button 3). If you click on the line of a data record, the detailed
            view of the data record opens and you can view the raw data.
          </p>
          <p className="absatz">
            To import new datasets, click on the plus button (Fig. 8, Button 4).
          </p>
          <img alt="" src={manulePic31Pic2} />
          <p
            style={{
              marginBottom: "20px",
              marginLeft: "10px",
              marginTop: "15px",
            }}
          >
            <span style={{ fontWeight: 400 }}>Fig. 8:</span> Dataset manager, 3
            - Button that allows you to load already locally saved datasets, 4 -
            button to load new datasets
          </p>
          <p className="heading-startpage">4. Troubleshooting</p>
          <p className="first-text">
            If errors occur on the website and it no longer works, it can be
            helpful to delete all local data. To do this, go to the settings.
            Here you will find two buttons for deleting under the ‘General
            settings’ tab in the ‘Locally stored data’ section.
          </p>{" "}
          <p className="absatz">
            If the website no longer works and you can no longer access the
            settings, you can also do the same under{" "}
            <Link
              to="/troubleshooting"
              style={{
                color: "var(--primary-color)",
                cursor: "pointer",
                fontWeight: 400,
                borderBottom: "1px solid var(--primary-color)",
              }}
            >
              /troubleshooting
            </Link>{" "}
            .
          </p>
          <p className="absatz">
            After deleting local data please reload the website so the demo data
            can be reloaded. Otherwise the website will not properly work.
          </p>
        </div>
        <Divider
          style={{
            width: "60vw",
            marginLeft: "20vw",
          }}
          variant="middle"
        />
        <div className="contact-div">
          <p
            className="subHeading"
            style={{
              fontWeight: "390",
              fontSize: "30px",
              marginBottom: "25px",
            }}
            id="contact-head"
          >
            CONTACT
          </p>
          <p style={{ fontWeight: 400 }}>
            Universität Leipzig | Medizinische Fakultät Innovation Center
            Computer Assisted Surgery (ICCAS)
          </p>
          <p>Semmelweisstraße 14, Haus 14 D – 04103 Leipzig Germany</p>
          <div
            style={{
              marginLeft: "20vw",
              marginRight: "10vw",
              textAlign: "left",
            }}
          >
            <p>
              <span>Phone:</span> +49 (0) 341 / 97 – 1 20 00{" "}
            </p>
            <p>
              <span>Fax:</span> +49 (0) 341 / 97 – 1 20 09{" "}
            </p>
            <p>
              <span>E-Mail:</span> info@iccas.de
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StartPage);
