import Dexie from "dexie";

class createNewDb {
  constructor(
    dbName,
    newDbStrucktur,
    newDbdata,
    callBack,
    callBackProgressCreatDB,
    callBackProgress,
    setFullyLoaded
  ) {
    this.callBackProgress = callBackProgress;
    this.setFullyLoaded = setFullyLoaded;
    // Erstelle eine neue Dexie-Datenbank mit dem übergebenen Namen
    this.db = new Dexie(dbName);
    this.callBack = callBack;
    this.dbName = dbName;
    this.totalEntrys = this.getTotalentrys(newDbdata);
    this.batchSize = this.totalEntrys / 50;
    this.progress = 0;
    // Initialisiere die Datenbankversion und das Schema
    this.db.version(1).stores(newDbStrucktur);
    this.newDbdata = newDbdata;
    // Führe das Schema-Setup durch
    this._initialize();
    this.callBackProgressCreatDB = callBackProgressCreatDB;
  }

  getTotalentrys(data) {
    let totalEntrys = 0;

    for (const key in data) {
      totalEntrys += data[key].length;
    }

    return totalEntrys;
  }

  // Private Methode zur Initialisierung der Datenbank
  async _initialize() {
    try {
      await this.db.open();
      console.log(`Datenbank "${this.db.name}" erfolgreich geöffnet.`);

      this.callBackProgressCreatDB();
      for (const key in this.newDbdata) {
        this.addData(key, this.newDbdata[key]);
      }
    } catch (error) {
      console.error("Fehler beim Öffnen der Datenbank:", error);
    }
  }

  async addData(tableName, data) {
    try {
      const table = this.db.table(tableName);

      for (let i = 0; i < data.length; i += this.batchSize) {
        const batch = data.slice(i, i + this.batchSize);
        await table.bulkAdd(batch);
        this.progress += batch.length;

        // Berechne den Fortschritt in Prozent
        const percentComplete = (this.progress / this.totalEntrys) * 100;
        this.callBackProgress(percentComplete);
        console.log(`Adding data to database progress: ${percentComplete}%`);
      }

      this.callBack(tableName, Object.keys(this.newDbdata).length, this.dbName);
      this.setFullyLoaded(tableName);
      console.log(tableName + ` data added:`, data);
      return;
    } catch (error) {
      console.error("Fehler beim Hinzufügen von Daten:", error);
    }
  }
}

export default createNewDb;
