import React, { Component } from "react";

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./DataUploadPage.css";
import {
  Collapse,
  TextField,
  LinearProgress,
  CircularProgress,
  Chip,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";

const dropzoneStyles = {
  border: "2px solid var(--divider-color)",
  borderRadius: "5px",
  padding: "10px",
  textAlign: "center",
  margin: "5px",
  cursor: "pointer",
  backgroundColor: "var(--drop-color)",
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" size={30} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="primary"
          sx={{ fontSize: "8px", fontWeight: 500 }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default class DataUploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvFiles: [],
      error: null,
      saveLocally: true,
      savedDatasets: [],
      updateSavedDataSets: false,
      nameValue: "",
      sizes: {},
      showProgress: false,
      showPrasePro: false,
      showPraseCrea: false,
      showPraseWrit: false,
      startTimePra: null,
      startTimeCrea: null,
      startTimeWrit: null,
      times: {
        parseCsv: 0,
        createDB: 0,
        writeInDB: 0,
      },
      showImportBtn: true,
      showCloseBtn: false,
      savingLocation: "index", //index or mongo
    };
  }

  /*-------------------------------drag and drop functions---------------------------------------------------------------------------------------------------------------------------------------*/
  onDragEnter = () => {
    this.setState({ isDragActive: true });
  };

  onDragLeave = () => {
    this.setState({ isDragActive: false });
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    let duplicateCheck = [...Array.from(files)];
    for (let i = 0; i < this.state.csvFiles.length; i++) {
      duplicateCheck.push(this.state.csvFiles[i]);
    }
    var duplicates = this.findDuplicates(duplicateCheck);
    if (duplicates.length === 0) {
      this.setState((prevState) => ({
        isDragActive: false,
        csvFiles: [...prevState.csvFiles, ...Array.from(files)],
        error: null,
      }));
    } else {
      this.setState({
        error:
          "You tried to import a file that already existed or tried to import 2 files with the same name. File/Files: " +
          duplicates.join(", ") +
          " Please try again.",
      });
    }
  };

  handleFileClick = () => {
    if (this.state.csvFiles.length === 0) {
      this.fileInput.click();
    }
  };

  handleFileClickBtn = () => {
    this.fileInput.click();
  };

  /*------------------------------file management-----------------------------------------------------------------------------------------------------------------------------------------*/
  findDuplicates(arr) {
    let hashTable = {};
    let duplicates = [];
    for (let i = 0; i < arr.length; i++) {
      if (hashTable[arr[i].name] === undefined) {
        hashTable[arr[i].name] = true;
      } else {
        duplicates.push(arr[i].name);
      }
    }

    return duplicates;
  }

  handleFileChange = (event) => {
    const files = event.target.files;
    let duplicateCheck = [...Array.from(files)];
    for (let i = 0; i < this.state.csvFiles.length; i++) {
      duplicateCheck.push(this.state.csvFiles[i]);
    }
    var duplicates = this.findDuplicates(duplicateCheck);
    if (duplicates.length === 0) {
      this.setState((prevState) => ({
        csvFiles: [...prevState.csvFiles, ...Array.from(files)],
        error: null,
      }));
    } else {
      this.setState({
        error:
          "You tried to import a file that already existed or tried to import 2 files with the same name. File/Files: " +
          duplicates.join(", ") +
          " Please try again.",
      });
    }
  };

  removeFile = (file) => {
    const index = this.state.csvFiles.indexOf(file);
    var fileArray = [...this.state.csvFiles];
    fileArray.splice(index, 1);
    this.setState({ csvFiles: fileArray });
  };

  /*-------------------------Prase Csv to JSON--------------------------------------------------------------------------------------------------------------------------------------------- */
  handleParseButtonClick = () => {
    const { csvFiles } = this.state;
    // error handling
    if (csvFiles.length === 0) {
      this.setState({
        error: "Please Import at least one file.",
      });
      return;
    }

    if (this.state.nameValue.length === 0 && this.state.saveLocally) {
      this.setState({
        error: "Please enter a Name.",
      });
      return;
    }

    var patientCSVPresent = false;
    var encounterCSVPresent = false;

    for (let i = 0; i < csvFiles.length; i++) {
      if (csvFiles[i].name === "patients.csv") {
        patientCSVPresent = true;
      }

      if (csvFiles[i].name === "encounters.csv") {
        encounterCSVPresent = true;
      }
    }

    if (!encounterCSVPresent || !patientCSVPresent) {
      this.setState({
        error:
          "At least the encounter.csv and patient.csv file needs to be uploaded. Make sure that the files are named accordingly.",
      });
      return;
    }

    this.setState({ error: null, showImportBtn: false });
    const sizes = {};
    var totalSize = 0;
    //exarate the the single sizes
    for (let i = 0; i < csvFiles.length; i++) {
      sizes[csvFiles[i].name.replace(".csv", "")] = this.state.csvFiles[i].size;
      totalSize = totalSize + this.state.csvFiles[i].size;
    }
    sizes["total"] = totalSize;
    this.setState({ sizes: sizes });

    this.props.parseCSVData(
      csvFiles,
      this.state.nameValue,
      this.state.savingLocation
    );
  };

  handleTextChange = (e) => {
    this.setState({ nameValue: e.target.value });
  };

  changeLocation = (location) => {
    this.setState({ savingLocation: location });
  };

  /*-------------------------Load Dataset--------------------------------------------------------------------------------------------------------------------------------------------- */

  startTiming = (key) => {
    this.setState({ [key]: new Date().getTime() });
  };

  stopTiming = (key, stateKey) => {
    const endTime = new Date().getTime();
    const startTime = this.state[key];
    if (startTime) {
      const duration = endTime - startTime;
      this.setState((prevState) => ({
        times: {
          ...prevState.times,
          [stateKey]: duration,
        },
        [key]: null,
      }));
    }
  };

  /*-------------------------Display--------------------------------------------------------------------------------------------------------------------------------------------- */

  setName = (allData) => {
    var indexLastObj = allData.length - 1;
    var highestId = 0;
    if (indexLastObj > -1) {
      highestId = allData[indexLastObj].id;
    }
    this.setState({
      savedDatasets: allData,
      highestId: highestId,
    });
  };

  componentDidMount() {
    //gets all dataset names -> is needed for new id and to check if the name of db already exists
    this.props.getAllEntries("CSVNames", "names", "csvFiles");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.csvFiles !== this.props.csvFiles) {
      this.setName(this.props.csvFiles.data);
    }

    if (
      prevProps.loadingProgressSimple.paraseCsvDone !==
      this.props.loadingProgressSimple.paraseCsvDone
    ) {
      if (!this.props.loadingProgressSimple.paraseCsvDone) {
        this.setState({ showPrasePro: true, showProgress: true });
        this.startTiming("startTimePra");
      } else {
        this.setState({ showPraseCrea: true });
        this.stopTiming("startTimePra", "parseCsv");
        this.startTiming("startTimeCrea");
      }
    }

    if (
      prevProps.loadingProgressSimple.creatingDBDone !==
      this.props.loadingProgressSimple.creatingDBDone
    ) {
      if (this.props.loadingProgressSimple.creatingDBDone) {
        this.setState({ showPraseWrit: true });
        this.stopTiming("startTimeCrea", "createDB");
        this.startTiming("startTimeWrit");
      }
    }

    if (
      prevProps.loadingProgressSimple.writingInDBDone !==
      this.props.loadingProgressSimple.writingInDBDone
    ) {
      if (this.props.loadingProgressSimple.writingInDBDone) {
        this.stopTiming("startTimeWrit", "writeInDB");
        this.setState({ showCloseBtn: true, showProgress: false });
      }
    }
  }

  render() {
    const styleTime = {
      marginLeft: "10px",
      fontWeight: 300,
      fontSize: "14px",
      color: "var(--text-color-start)",
    };
    const styleCollapseHori = {
      display: "flex",
      alignItems: "center",
    };
    const stylePCol = {
      marginLeft: "10px",
      fontWeight: 300,
      color: "var(--primary-color)",
    };
    const lineBox = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 30,
      height: 30,
    };
    const line = {
      backgroundColor: "var(--primary-color)",
      height: "100%",
      width: "1px",
    };
    const btnStyle = {
      borderColor: "var(--primary-color)",
      color: "var(--primary-color)",
    };

    const btnStyleActive = {
      color: "white",
      backgroundColor: "var(--primary-color)",
    };

    return (
      <div className="pl-4 pr-4 font-light">
        <Collapse orientation="vertical" in={!this.state.showPrasePro}>
          <div className="flex gap-9 items-center mt-4 mb-6">
            <h5 style={{ fontWeight: 400, marginBottom: "5px" }}>
              Choose saving location:
            </h5>
            <ButtonGroup variant="outlined">
              <Button
                sx={
                  this.state.savingLocation === "index"
                    ? btnStyleActive
                    : btnStyle
                }
                onClick={() => this.changeLocation("index")}
              >
                IndexedDB (Dexie)
              </Button>
              <Button
                sx={
                  this.state.savingLocation === "mongo"
                    ? btnStyleActive
                    : btnStyle
                }
                onClick={() => this.changeLocation("mongo")}
                disabled={!this.props.connectionToMongoDB.connected}
              >
                MongoDB
              </Button>
            </ButtonGroup>
            {this.props.connectionToMongoDB.trying && (
              <div className="flex gap-2 items-center">
                <CircularProgress
                  size={20}
                  sx={{ color: "var(--divider-color)" }}
                />
                <p style={{ color: "var(--divider-color)" }}>
                  connecting to MongoDB
                </p>
              </div>
            )}
            {!this.props.connectionToMongoDB.connected &&
              !this.props.connectionToMongoDB.trying && (
                <p style={{ color: "red" }}>connecting with MongoDB failed</p>
              )}
          </div>
          <div className="data-info-div">
            <h5 style={{ fontWeight: 400, marginBottom: "5px" }}>
              Important information considering the data import{" "}
              {this.state.savingLocation === "index"
                ? "with Dexie into the indexedDB"
                : "into the MongoDB database"}
              :
            </h5>
            <ul className="list-disc pl-5">
              <li>Only CSV files are accepted</li>
              <li>
                Please use the Synthea formatting for the CSV files, otherwise
                the correct functioning of the website cannot be guaranteed
              </li>
              <li>
                {this.state.savingLocation === "index"
                  ? "Your data is stored locally in the browser in the so-called indexedDB"
                  : "Your data will only be stored into your local MongoDB database"}
              </li>
              <li>
                How fast your datasets can be processed depends heavily on your
                hardware (we recommend at least 32GB RAM)
              </li>
              {this.state.savingLocation === "index" ? (
                <li>
                  The size of the local storage space offered by the browser
                  varies from browser to browser - in case of problems it may be
                  useful to use a different browser
                </li>
              ) : (
                <li>
                  We currently recommend dataset sizes of{" "}
                  <span className="font-bold">no more than 1GB</span>.
                </li>
              )}
              {this.state.savingLocation === "index" && (
                <li>
                  <span className="font-bold">IndexedDB</span> is the slower
                  option and is <span className="font-bold">only</span>{" "}
                  recommended for{" "}
                  <span className="font-bold">
                    small datasets (up to ca. 200-300mb)
                  </span>
                  . For <span className="font-bold">larger datasets</span>{" "}
                  please use a <span className="font-bold">MongoDB</span>{" "}
                  database. For more information on how to use a MongoDB click
                  here.
                </li>
              )}
            </ul>
          </div>
          <div className="font-light mt-3">
            <h5 style={{ fontWeight: 400 }}>Import your CSV files:</h5>
            <div
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
              onDragOver={this.onDragOver}
              onClick={this.handleFileClick}
              style={dropzoneStyles}
            >
              {this.state.csvFiles.length !== 0 ? (
                <div className="imported-files-div gap-1">
                  {this.state.csvFiles.map((data) => (
                    <Chip
                      label={data.name}
                      variant="outlined"
                      onDelete={() => this.removeFile(data)}
                      color="primary"
                      key={data.name}
                    />
                  ))}
                  <IconButton onClick={this.handleFileClickBtn} color="primary">
                    <AddIcon />
                  </IconButton>
                </div>
              ) : (
                <p style={{ color: "var(--text-color-start)" }} className="p-8">
                  Drag-and-Drop or click to upload your CSV files
                </p>
              )}
              <input
                type="file"
                accept=".csv"
                ref={(input) => (this.fileInput = input)}
                onChange={this.handleFileChange}
                style={{ display: "none" }}
                multiple
              />
            </div>
          </div>
        </Collapse>
        <Collapse
          orientation="vertical"
          in={this.state.showPrasePro}
          className="mt-1"
        >
          <p
            style={{
              marginBottom: "10px",
              fontWeight: 300,
              fontSize: "18px",
              color: "var(--text-color-start)",
            }}
          >
            Loading Progress of {this.state.nameValue} dataset
          </p>

          <div
            style={{
              minHeight: "200px",
              marginBottom: "15px",
              paddingLeft: "20px",
            }}
          >
            <Collapse orientation="vertical" in={this.state.showPrasePro}>
              <div style={styleCollapseHori}>
                <CircularProgressWithLabel
                  value={this.props.parseLoadingStatus}
                />
                <Collapse in={this.state.showPrasePro} timeout={{ enter: 500 }}>
                  <div style={styleCollapseHori}>
                    <p style={stylePCol}>Parasing CSV-Data</p>{" "}
                    {this.state.times.parseCsv !== 0 && (
                      <p style={styleTime}>
                        {(this.state.times.parseCsv / 1000).toFixed(3)}s
                      </p>
                    )}
                  </div>
                </Collapse>
              </div>
            </Collapse>

            <Collapse orientation="vertical" in={this.state.showPraseCrea}>
              <div style={lineBox}>
                <div style={line}></div>
              </div>
              <div style={styleCollapseHori}>
                <CircularProgressWithLabel
                  value={this.props.creatingDBProgress}
                />
                <Collapse
                  in={this.state.showPraseCrea}
                  timeout={{ enter: 500 }}
                >
                  <div style={styleCollapseHori}>
                    <p style={stylePCol}>Creating database in indexedDB</p>{" "}
                    {this.state.times.createDB !== 0 && (
                      <p style={styleTime}>
                        {(this.state.times.createDB / 1000).toFixed(3)} s
                      </p>
                    )}
                  </div>
                </Collapse>
              </div>
            </Collapse>

            <Collapse orientation="vertical" in={this.state.showPraseWrit}>
              <div style={lineBox}>
                <div style={line}></div>
              </div>
              <div style={styleCollapseHori}>
                <Collapse
                  in={this.state.showPraseWrit}
                  timeout={{ enter: 500 }}
                >
                  <div style={styleCollapseHori}>
                    <CircularProgressWithLabel
                      value={
                        this.props.addingDataStatus <= 100
                          ? this.props.addingDataStatus
                          : 100
                      }
                    />
                    <p style={stylePCol}>Adding data to database</p>{" "}
                    {this.state.times.writeInDB !== 0 && (
                      <p style={styleTime}>
                        {(this.state.times.writeInDB / 1000).toFixed(3)}s
                      </p>
                    )}
                  </div>
                  <div className="flex gap-2 justify-start flex-wrap max-w-[50vw]">
                    {this.props.filesFullLoaded.map((file) => (
                      <div className="flex items-center flex-nowrap gap-1 pl-9 text-sm font-light">
                        {file.loaded ? (
                          <DoneIcon sx={{ height: "25px" }} color="success" />
                        ) : (
                          <CircularProgress size={10} />
                        )}
                        <p>{file.name}</p>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            </Collapse>
          </div>
        </Collapse>
        <div>
          {this.state.showImportBtn && (
            <div className="data-upload-control">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <p>Dataset name:</p>
                <TextField
                  name="value"
                  variant="standard"
                  value={this.state.nameValue}
                  onChange={this.handleTextChange}
                  sx={{ m: 1, width: "15vw", marginLeft: "15px" }}
                  size="small"
                />
              </div>

              <button
                className="general-btn"
                onClick={this.handleParseButtonClick}
              >
                {this.state.saveLocally ? "Import" : "Import & Load"}
              </button>
            </div>
          )}

          {this.state.error && (
            <div style={{ color: "red" }}>{this.state.error}</div>
          )}
        </div>
        <Collapse in={!this.state.showImportBtn}>
          <div className="mt-2 flex  justify-between items-center gap-4">
            <div className="flex-grow">
              {this.state.showProgress ? (
                <LinearProgress />
              ) : (
                <p style={{ color: "var(--primary-color)" }}>
                  Loading the dataset into indexedDB completed. Dataset can now
                  be opened in SynViewer.
                </p>
              )}
            </div>
            <button
              className="general-btn"
              onClick={this.props.onClose}
              disabled={!this.props.loadingProgressSimple.writingInDBDone}
            >
              Close
            </button>
          </div>
        </Collapse>
      </div>
    );
  }
}
