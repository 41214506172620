import React, { Component } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { CircularProgress, Link as MuiLink } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import FiberSmartRecordSharpIcon from "@mui/icons-material/FiberSmartRecordSharp";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import FolderIcon from "@mui/icons-material/Folder";

import "./Breadcrumbs.css";

export default class Header extends Component {
  returnIcon(name) {
    if (name === "syn-viewer") {
      return <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
    } else if (name === "patient-records") {
      return (
        <FormatListBulletedOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      );
    } else if (name === "patient-view") {
      return <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
    } else if (name === "population-analysis") {
      return <LeaderboardIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
    } else if (name === "datasets") {
      return <FolderIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
    } else {
      return <FiberSmartRecordSharpIcon sx={{ mr: 0.5 }} fontSize="inherit" />;
    }
  }

  reconstructHRF(path, index) {
    var pathString = "";
    if (index === 0) {
      pathString = "/syn-viewer/overview-dataset";
    } else if (index === path.length - 1) {
      pathString = this.props.location.pathname;
    } else {
      for (let i = 0; i <= index; i++) {
        var oldPathString = pathString;
        pathString = oldPathString + "/" + path[i];
        if (pathString === "/syn-viewer/patient-records") {
          pathString = "/syn-viewer/patient-records/overview";
        }
      }
    }

    return pathString;
  }

  returnNextPrev() {
    const url = this.props.location.pathname;
    const lastIndex = url.lastIndexOf("/");
    const newUrl = url.substring(0, lastIndex);

    if (newUrl === "/syn-viewer/patient-records/patient-view") {
      return (
        <div className="prev-next-div">
          <div className="click-left-right" onClick={this.props.prevPatient}>
            <span className="arrow-left-span">
              <ArrowBackOutlinedIcon />
            </span>
            prev Patient
          </div>
          <div className="spacer-bread"></div>
          <div className="click-left-right" onClick={this.props.nextPatient}>
            next Patient
            <span className="arrow-right-span">
              <ArrowForwardOutlinedIcon />
            </span>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  returnPatientName() {
    const url = this.props.location.pathname;
    const lastIndex = url.lastIndexOf("/");
    const newUrl = url.substring(0, lastIndex);

    if (
      !this.props.patientInfoVisible &&
      newUrl === "/syn-viewer/patient-records/patient-view"
    ) {
      return (
        <p className="name-bread">
          {this.props.openPatient.replace(/\d/g, "") +
            " (" +
            (this.props.openPatientId + 1) +
            "/" +
            this.props.size +
            ")"}
        </p>
      );
    }
  }

  loadingMessage(message, time) {
    return (
      <div
        className="flex gap-1"
        style={{
          fontSize: "13px",
          fontWeight: 300,
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {time === 0 && (
          <CircularProgress
            size={13}
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              marginTop: "0.4vh",
              marginRight: "5px",
            }}
          />
        )}
        <p>{message}</p>
        {time !== 0 && <p>{(time / 1000).toFixed(1)} s</p>}
      </div>
    );
  }

  constructBreadcrumbs(pathArray) {
    if (
      pathArray[pathArray.length - 1] === "overview" ||
      pathArray[pathArray.length - 2] === "patient-view"
    ) {
      pathArray.pop();
    }
    return pathArray;
  }

  render() {
    var locationArray = this.constructBreadcrumbs(
      this.props.location.pathname.split("/").filter(Boolean)
    );

    const url = this.props.location.pathname;
    const lastIndex = url.lastIndexOf("/");
    const newUrl = url.substring(0, lastIndex);

    const showPatLoading = url.includes(
      "/syn-viewer/patient-records/patient-view/"
    );

    const showPatOverviewLoading = url.includes(
      "/syn-viewer/patient-records/overview"
    );

    return (
      <div className="breadcrumbs-div">
        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "13px" }}>
          {locationArray.map((location, index) => (
            <ReactRouterLink
              to={this.reconstructHRF(locationArray, index)}
              key={"breadcrumps" + location + index}
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                border: "none",
                textDecoration: "none",
                fontFamily: " Spline Sans",
                fontWeight: "380",
              }}
            >
              {this.returnIcon(location)}
              <span>{location === "syn-viewer" ? "" : location}</span>
            </ReactRouterLink>
          ))}
        </Breadcrumbs>
        {showPatLoading &&
          this.loadingMessage(
            this.props.loadingMessagePatView,
            this.props.loadingTimePatientDuration
          )}
        {showPatOverviewLoading &&
          this.loadingMessage(
            this.props.loadingMessagePatOverview,
            this.props.loadingTimePatOverviewDuration
          )}
      </div>
    );
  }
}
