import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/universalStyles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";

const SnackbarInitializer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (variant, text) => {
    enqueueSnackbar(text, {
      variant: variant,
    });
  };

  return <App showSnackbar={showSnackbar} />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
      <SnackbarInitializer />
    </SnackbarProvider>
  </BrowserRouter>
);

reportWebVitals();
