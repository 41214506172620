import React, { Component } from "react";

import axios from "axios";
import { Tabs, Tab, Box, Typography, Button, Paper } from "@mui/material";

import PropTypes from "prop-types";

import db from "../utils/DexieDatabase/db";

import RawTable from "../PatientRecords/RawTable";

export default class DatasetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      openDBFiles: ["Dataset-Info"],
    };
  }

  handleChange = (event, newValue) => {
    //handles the chnage of tabs
    this.setState({ tabValue: newValue });
  };

  getAllObjectStores(dbName, location) {
    if (location === "index") {
      this.getCollectionsOfIndexedDb(dbName);
    } else if (location === "mongo") {
      this.getCollectionsOfMongoDB(dbName);
    }
  }

  getCollectionsOfIndexedDb = (dbName) => {
    const dbAll = new db(dbName);
    dbAll
      .listTablesAndIndexes()
      .then((list) => {
        var tables = ["Dataset-Info"];

        for (let i = 0; i < list.length; i++) {
          tables.push(list[i].name);
        }

        this.setState({ openDBFiles: tables });
      })
      .catch((error) => {
        console.error("Fehler:", error);
      });
  };

  getCollectionsOfMongoDB = async (dbName) => {
    try {
      const response = await axios.get("http://localhost:5001/getCollections", {
        params: {
          dbName: dbName,
        },
      });
      if (response.data.status === "success") {
        this.setState({ openDBFiles: ["Dataset-Info", ...response.data.data] });
      } else {
        console.log("error", response.data.message);
      }
    } catch (error) {
      console.log("error", `Error getting db collections: ${error.message}`);
    }
  };

  componentDidMount() {
    this.getAllObjectStores(
      this.props.detailedDbName,
      this.props.detailedDBLocation
    );
  }

  render() {
    return (
      <Paper sx={{ height: "85vh", marginLeft: "0.5vw", marginRight: "0.5vw" }}>
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            width: "100%",
            marginTop: "10px",
            height: "84vh",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={this.state.tabValue}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              fontFamily: "Spline Sans",
              height: "100%",
            }}
          >
            {this.state.openDBFiles.map((file, index) => (
              <Tab label={file} {...a11yProps(index)} />
            ))}
          </Tabs>
          {this.state.openDBFiles.map((file, index) => (
            <TabPanel
              value={this.state.tabValue}
              index={index}
              sx={{ paddingRight: "10px" }}
            >
              {file !== "Dataset-Info" ? (
                <RawTable
                  openFile={file}
                  dbName={this.props.detailedDbName}
                  location={this.props.detailedDBLocation}
                  style={{
                    height: "80vh",
                    width: "87vw",
                  }}
                />
              ) : (
                <div style={{ width: "89vw" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                    }}
                  >
                    <h1 style={{ fontFamily: "Spline Sans", color: "#4361ee" }}>
                      Dataset: {this.props.detailedDbName}
                    </h1>
                    <div style={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        color="error"
                        size="big"
                        sx={{ marginRight: "10px" }}
                      >
                        Delete
                      </Button>
                      <Button variant="contained" color="success" size="big">
                        Load
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </TabPanel>
          ))}
        </Box>
      </Paper>
    );
  }
}

/*------------------------------------------------------------------------------ tabs functions from MUI------------------------------------------------------------------------------------------------------------- */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
