import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import "./AgeDisStyles.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default class PopAnaDashboardAgeDis extends Component {
  constructor(props) {
    super(props);
    this.state = { dataProcessed: false, ageDis: {} };
  }

  componentDidMount() {
    if (this.props.patientList.length !== 0) {
      this.setState(
        { ageDis: this.calculateAgeDistribution(this.props.patientList) },
        () => {
          this.setState({ dataProcessed: true });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.patientList !== this.props.patientList) {
      this.setState({ dataProcessed: false }, () =>
        this.setState(
          { ageDis: this.calculateAgeDistribution(this.props.patientList) },
          () => {
            this.setState({ dataProcessed: true });
          }
        )
      );
    }
  }

  calculateAgeDistribution(patients) {
    const ageGroups = [
      "0-9",
      "10-19",
      "20-29",
      "30-39",
      "40-49",
      "50-59",
      "60-69",
      "70-79",
      "80+",
    ];

    const distribution = {
      male: Array(ageGroups.length).fill(0),
      female: Array(ageGroups.length).fill(0),
    };

    patients.forEach((patient) => {
      const { Gender, Age } = patient;

      if (Gender !== undefined) {
        let ageGroupIndex;
        if (Age < 10) ageGroupIndex = 0;
        else if (Age.years < 20) ageGroupIndex = 1;
        else if (Age.years < 30) ageGroupIndex = 2;
        else if (Age.years < 40) ageGroupIndex = 3;
        else if (Age.years < 50) ageGroupIndex = 4;
        else if (Age.years < 60) ageGroupIndex = 5;
        else if (Age.years < 70) ageGroupIndex = 6;
        else if (Age.years < 80) ageGroupIndex = 7;
        else ageGroupIndex = 8;

        if (Gender.toLowerCase() === "m") {
          distribution.male[ageGroupIndex]++;
        } else if (Gender.toLowerCase() === "f") {
          distribution.female[ageGroupIndex]++;
        }
      }
    });

    return distribution;
  }

  // Funktion zum Abrufen von CSS-Variablenwerten
  getCSSVariableValue = (variableName) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variableName)
      .trim();
  };

  render() {
    const data = {
      labels: [
        "0-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80+",
      ],
      datasets: [
        {
          label: "Female",
          backgroundColor: this.getCSSVariableValue("--chart-1"),
          borderColor: this.getCSSVariableValue("--chart-1"),
          borderWidth: 1,
          data: this.state.ageDis.female, // Beispielwerte für weiblich
        },
        {
          label: "Male",
          backgroundColor: this.getCSSVariableValue("--chart-2"),
          borderColor: this.getCSSVariableValue("--chart-2"),
          borderWidth: 1,
          data: this.state.ageDis.male, // Beispielwerte für männlich
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false, // Verstecke die eingebaute Legende
        },
        title: {
          display: false, // Verstecke den eingebauten Titel
        },
      },
    };

    if (this.state.dataProcessed) {
      return (
        <div>
          <div className="chart-container">
            <h2 className="chart-title">Age distribution by gender</h2>
            <div className="chart-legend">
              <div style={{ marginRight: "2vw" }}>
                <span
                  style={{
                    backgroundColor: "var(--chart-1)",
                    padding: "4px",
                    marginRight: "10px",
                  }}
                ></span>{" "}
                Female
              </div>
              <div>
                <span
                  style={{
                    backgroundColor: "var(--chart-2)",
                    padding: "4px",
                    marginRight: "10px",
                  }}
                ></span>{" "}
                Male
              </div>
            </div>
          </div>
          <Bar data={data} options={options} />
        </div>
      );
    } else {
      return (
        <div>
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Skeleton variant="rounded" height={300} />
          </Stack>
        </div>
      );
    }
  }
}
