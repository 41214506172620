import React, { Component } from "react";

import db from "../utils/DexieDatabase/db";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CachedIcon from "@mui/icons-material/Cached";

import { styled } from "@mui/material/styles";

import Overlay from "../Overlay/Overlay";
import DataUploadPage from "../DataUpload/DataUploadPage";

import { withRouter } from "../utils/withRouter";
import { CircularProgress, Skeleton } from "@mui/material";

class Datasets extends Component {
  //component to display all saved datasets, load them and show their entries as Table
  constructor(props) {
    super(props);
    this.state = {
      savedDatasets: [],
      tableHead: [],
      isOverlayOpen: false,
      indexOfSavedFiles: 2, // muss angepasst werden falls sich die strucktur der datenbank ändert
      storage: { quota: 0, usage: 0 },
    };
  }

  openOverlay = () => {
    //prevent data uploaded to close while new dataset is loading
    if (!this.state.isOverlayOpen) {
      this.setState({ isOverlayOpen: true });
    } else {
      this.setState({ isOverlayOpen: false });
    }
  };

  mapDataView(data) {
    var displayArray = [];
    for (const key in data) {
      if (key !== "PATIENT") {
        displayArray.push(data[key]);
      }
    }
    return displayArray;
  }

  onClick = (dbName, detailedLocation) => {
    this.props.setDetailedDbName(dbName, detailedLocation);
    this.props.navigate("/syn-viewer/datasets/dataset-details");
  };

  deleteDataset = (dataset) => {
    const dbAll = new db("CSVNames", "names");
    dbAll.deleteEntryById(dataset.id);
    //delete the database

    const delDB = new db(dataset.name);
    delDB
      .deleteDatabase()
      .then(this.props.getAllEntries("CSVNames", "names", "csvFiles"));
  };

  handleClickOnButton = (event) => {
    // Verhindere, dass das Klicken auf den Button das onClick-Ereignis des <tr> auslöst
    event.stopPropagation();
  };

  handleDelete = (event, db) => {
    // Verhindere, dass das Klicken auf den Button das onClick-Ereignis des <tr> auslöst
    event.stopPropagation();
    this.deleteDataset(db);
    this.estimateStorageUsage();
  };

  handleDeleteMongo = (event, db) => {
    // Verhindere, dass das Klicken auf den Button das onClick-Ereignis des <tr> auslöst
    event.stopPropagation();
    this.props.deleteDatabaseAxios(db);
  };

  handleLoad = (event, dbName, savingLocation) => {
    event.stopPropagation();
    this.props.openNewDb(dbName, savingLocation);
  };

  checkIFSize(cell, column) {
    if (column === 3) {
      return Math.round(cell * 10) / 10 + " MB";
    } else {
      return cell;
    }
  }

  prepareData = (allData) => {
    if (allData.length > 0) {
      var tableHead = [];
      for (const key in allData[0]) {
        tableHead.push(key);
      }
    }
    tableHead.push("delete");
    this.setState({
      savedDatasets: allData,
      tableHead: tableHead,
    });
  };

  transformDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Monate sind nullbasiert
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    const formattedDate = `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  estimateStorageUsage() {
    if ("indexedDB" in window) {
      navigator.storage.estimate().then((estimate) => {
        const quotaInGB = estimate.quota / (1024 * 1024 * 1024); // Umrechnung von Bytes in GB
        const usageInGB = estimate.usage / (1024 * 1024 * 1024);
        this.setState({ storage: { quota: quotaInGB, usage: usageInGB } });
      });
    }
  }

  componentDidMount() {
    this.props.getAllEntries("CSVNames", "names", "csvFiles");
    this.estimateStorageUsage();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.csvFiles !== this.props.csvFiles) {
      this.prepareData(this.props.csvFiles.data);
    }

    if (
      prevProps.loadingProgressSimple.writingInDBDone !==
      this.props.loadingProgressSimple.writingInDBDone
    ) {
      if (this.props.loadingProgressSimple.writingInDBDone) {
        this.props.getAllEntries("CSVNames", "names", "csvFiles");
        this.estimateStorageUsage();
      }
    }
  }

  render() {
    return (
      <div className="overflow-auto p-3">
        <Overlay
          isOpen={this.state.isOverlayOpen}
          onClose={() => this.openOverlay()}
          title="Data Import"
          loadingDone={this.props.loadingProgressSimple.writingInDBDone}
        >
          <DataUploadPage
            getSavedDataId={this.props.getSavedDataId}
            getImportedDataset={this.props.getImportedDataset}
            closeOverlay={this.openOverlay}
            getAllEntries={this.props.getAllEntries}
            csvFiles={this.props.csvFiles}
            parseCSVData={this.props.parseCSVData}
            loadingProgressSimple={this.props.loadingProgressSimple}
            creatingDBProgress={this.props.creatingDBProgress}
            onClose={this.openOverlay}
            parseLoadingStatus={this.props.parseLoadingStatus}
            addingDataStatus={this.props.addingDataStatus}
            filesFullLoaded={this.props.filesFullLoaded}
            connectionToMongoDB={this.props.connectionToMongoDB}
          />
        </Overlay>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "5vh",
            marginBottom: "15px",
            alignItems: "center",
            fontWeight: 300,
          }}
        >
          <p>Add new dataset:</p>
          <IconButton onClick={() => this.openOverlay()}>
            <AddCircleIcon sx={{ height: "35px", width: "35px" }} />
          </IconButton>
        </div>
        <section>
          <h4
            style={{
              marginTop: "15px",
              marginLeft: "10vw",
              marginBottom: "10px",
              textAlign: "left",
              fontWeight: "550",
              fontFamily: "Spline Sans",
            }}
          >
            In local indexedDB saved datasets:
          </h4>
          <TableContainer
            component={Paper}
            ref={this.containerRef}
            sx={{
              width: "80vw",
              marginLeft: "10vw",
              maxHeight: "74vh",
              overflowY: "hidden",
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {this.state.tableHead.map((column) => (
                    <StyledTableCell key={column}>
                      {column !== "delete" && column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.savedDatasets.map((row) => (
                  <StyledTableRow
                    key={row.id}
                    className="rowHover"
                    onClick={() => this.onClick(row.name, "index")}
                  >
                    {this.mapDataView(row).map((column, index) => (
                      <StyledTableCell sx={{ fontFamily: "Spline Sans" }}>
                        {index === this.state.indexOfSavedFiles
                          ? column.join(", ")
                          : this.checkIFSize(column, index)}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell
                      sx={{
                        fontFamily: "Spline Sans",
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color={
                          this.props.openDB === row.name ? "success" : "greyBtn"
                        }
                        size="small"
                        onClick={(event) =>
                          this.handleLoad(event, row.name, "index")
                        }
                        sx={{ color: "white" }}
                      >
                        {this.props.openDB === row.name ? "Loaded" : "Load"}
                      </Button>
                      {row.name === "DemoDataset" ? (
                        <IconButton
                          id="DemoDataset"
                          onClick={this.handleClickOnButton}
                        >
                          <CachedIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          id={row.name}
                          onClick={(event) => this.handleDelete(event, row)}
                          disabled={
                            this.props.openDB === row.name ? true : false
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              fontWeight: 300,
              display: "flex",
              height: "3vh",
              justifyContent: "flex-end",
              alignItems: "center",
              fontSize: "15px",
              marginTop: "15px",
            }}
          >
            <p style={{ marginRight: "10px" }}>
              estimated local browser storage:{" "}
            </p>
            <Box sx={{ width: "20vw" }}>
              <LinearProgressWithLabel
                value={
                  (this.state.storage.usage / this.state.storage.quota) * 100
                }
                freeStorage={
                  this.state.storage.quota - this.state.storage.usage
                }
                storage={this.state.storage.quota}
              />
            </Box>
          </div>
        </section>
        <section className="mt-7">
          <div className="flex gap-4 items-center">
            <h4
              style={{
                marginTop: "15px",
                marginLeft: "10vw",
                marginBottom: "10px",
                textAlign: "left",
                fontWeight: "550",
                fontFamily: "Spline Sans",
              }}
            >
              In MongoDB saved datasets:
            </h4>
            {this.props.connectionToMongoDB.trying && (
              <div
                className="flex gap-2 items-center font-light"
                style={{ color: "var(--divider-color)" }}
              >
                <CircularProgress
                  size={20}
                  sx={{ color: "var(--divider-color)" }}
                />
                <p>connecting to MongoDB</p>
              </div>
            )}
            {this.props.connectionToMongoDB.connected &&
              !this.props.mongoDatabases.loaded && (
                <div
                  className="flex gap-2 items-center font-light"
                  style={{ color: "var(--divider-color)" }}
                >
                  <CircularProgress
                    size={20}
                    sx={{ color: "var(--divider-color)" }}
                  />
                  <p>loading databases</p>
                </div>
              )}
            {!this.props.connectionToMongoDB.connected &&
              !this.props.connectionToMongoDB.trying && (
                <p style={{ color: "red" }}>connecting with MongoDB failed</p>
              )}
          </div>
          <TableContainer
            component={Paper}
            sx={{
              width: "80vw",
              marginLeft: "10vw",
              maxHeight: "74vh",
              overflowY: "hidden",
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {this.state.tableHead.map((column) => (
                    <StyledTableCell key={column}>
                      {column !== "delete" && column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!this.props.mongoDatabases.loaded ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Skeleton height={70} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Skeleton height={70} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Skeleton height={70} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Skeleton height={70} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Skeleton height={70} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {this.props.mongoDatabases.data.map((row, i) => (
                    <StyledTableRow
                      key={row.id}
                      className="rowHover"
                      onClick={() => this.onClick(row.dbName, "mongo")}
                    >
                      <StyledTableCell>{i}</StyledTableCell>
                      <StyledTableCell>{row.dbName}</StyledTableCell>
                      <StyledTableCell>
                        {row.collections.join(", ")}
                      </StyledTableCell>
                      <StyledTableCell>{row.sizeMB} MB</StyledTableCell>
                      <StyledTableCell>{row.patientCount}</StyledTableCell>
                      <StyledTableCell>
                        {this.transformDate(row.createdAt)}{" "}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontFamily: "Spline Sans",
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color={
                            this.props.openDB === row.dbName
                              ? "success"
                              : "greyBtn"
                          }
                          size="small"
                          onClick={(event) =>
                            this.handleLoad(event, row.dbName, "mongo")
                          }
                          sx={{ color: "white" }}
                        >
                          {this.props.openDB === row.dbName ? "Loaded" : "Load"}
                        </Button>
                        <IconButton
                          id={row.dbName}
                          onClick={(event) =>
                            this.handleDeleteMongo(event, row.dbName)
                          }
                          disabled={
                            this.props.openDB === row.dbName ? true : false
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </section>
        <div id="spacer" className="h-5"></div>
      </div>
    );
  }
}

export default withRouter(Datasets);

/*--------------------------------------------------------- table function --------------------------------------------------------------------------------------------------------------- */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4169e1",
    color: theme.palette.common.white,
    fontFamily: "Spline Sans",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Spline Sans",
    fontWeight: 350,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.rowHover:hover": {
    backgroundColor: "#aabcf2",
    cursor: "pointer",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 180 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(props.freeStorage * 100) / 100}`} GB free from{" "}
          {`${Math.round(props.storage * 100) / 100}`} GB
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
