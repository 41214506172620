import React, { Component } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  IconButton,
  Switch,
  Chip,
  Collapse,
  Autocomplete,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import FilterSaver from "./FilterSaver";

import "./Filter.css";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includeOArray: [],
      includeOArrayNew: false,
      includeAllDataOfEncounter: false,
      excludeArray: [],
      excludeArrayNew: false,
      isCollapsedI: true,
      isCollapsedE: true,
      changedFromFilterProfile2: false,
      includeReasons: [],
      collapseInReasons: true,
      open: false, // Zustand für das Dropdown-Menü
      valueSelect: "",
      filterOnSliderChange: false,
    };
    this.changeToChangedProfile = this.changeToChangedProfile.bind(this);
    this.changeFromFilterProfile = this.changeFromFilterProfile.bind(this);
  }

  /*-------------------------------------------- handel Collapse ------------------------------------------------------------------------------------------------------------ */

  handleToggleCollapseI = () => {
    this.setState((prevState) => ({
      isCollapsedI: !prevState.isCollapsedI,
    }));
  };

  handleToggleCollapseE = () => {
    this.setState((prevState) => ({
      isCollapsedE: !prevState.isCollapsedE,
    }));
  };

  handleToggleCollapseInput = () => {
    this.setState(
      (prevState) => ({
        collapseInReasons: !prevState.collapseInReasons,
      }),
      () => {
        document.getElementById("include-by-reason").focus();
        if (!this.state.isCollapsedInput)
          setTimeout(() => {
            this.setState({ open: true });
          }, 260);
      }
    );
  };

  onBlur = () => {
    this.setState({ open: false, collapseInReasons: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, collapseInReasons: true });
    document.getElementById("include-by-reason").blur();
  };

  /*-------------------------------------------- add/remove to filter arrays------------------------------------------------------------------------------------------------------------ */

  removeFromFilterArray(filterArray, filterName, item) {
    let oldArray = [...filterArray];
    var index = oldArray.indexOf(item);
    oldArray.splice(index, 1);
    this.setState({ displayDataUploaded: false }, () => {
      this.setState({ [filterName]: oldArray });
    });
  }

  addToFilterArray(value, filterName) {
    if (value === null) {
      return;
    }

    let oldArray = [...this.state[filterName]];
    var isItemInArray = oldArray.find((o) => o.code === value.code);
    if (isItemInArray === undefined) {
      oldArray.push(value);
      this.setState({
        [filterName]: oldArray,
        isCollapsedI: true,
        isCollapsedE: true,
      });
    } else {
      alert("Item is already in filter list! Please try again.");
      return;
    }
  }

  /*-------------------------------------------- include all ------------------------------------------------------------------------------------------------------------ */
  changeCheckbox = () => {
    if (this.state.includeAllDataOfEncounter) {
      this.setState({ includeAllDataOfEncounter: false });
    } else {
      this.setState({ includeAllDataOfEncounter: true });
    }
  };
  /*-------------------------------------------- filter functions ------------------------------------------------------------------------------------------------------------ */

  excludeFilter(data, filterArray) {
    let filterData = {};
    let encountersArray = [];

    //figure out if any filterItem is an encounter
    for (let i = 0; i < filterArray.length; i++) {
      var isEncounter = data["encounters"].data.find(
        (o) => o.CODE === filterArray[i].code
      );

      if (isEncounter !== undefined) {
        for (let index = 0; index < data["encounters"].data.length; index++) {
          if (data["encounters"].data[index].CODE === filterArray[i].code) {
            encountersArray.push(data["encounters"].data[index]);
          }
        }
      }
    }

    //start filtering
    for (const key in data) {
      if (key === "patient") {
        filterData[key] = data[key];
      } else {
        var filteredItems = [];
        for (let i = 0; i < data[key].data.length; i++) {
          var isOk = true;
          //check if data is in encounter that should be filtered out
          var isFromFilteredOutEncounter = encountersArray.find(
            (o) => o.Id === data[key].data[i].ENCOUNTER
          );

          if (isFromFilteredOutEncounter !== undefined) {
            isOk = false;
          } else {
            //check if data shall be filtered out by code
            for (let index = 0; index < filterArray.length; index++) {
              if (data[key].data[i].CODE === filterArray[index].code) {
                isOk = false;
                break;
              }
            }
          }

          if (isOk) {
            filteredItems.push(data[key].data[i]);
          }
        }

        filterData[key] = { typ: key, data: filteredItems };
      }
    }

    return filterData;
  }

  includeOArray(data, filterArray) {
    let filterData = {};
    let encountersArray = [];
    let additionalEncounter = [];

    if (filterArray.length > 0) {
      //figure out if any filterItem is an encounter
      for (let i = 0; i < filterArray.length; i++) {
        var isEncounter = data["encounters"].data.find(
          (o) => o.CODE === filterArray[i].code
        );

        if (isEncounter !== undefined) {
          for (let index = 0; index < data["encounters"].data.length; index++) {
            if (data["encounters"].data[index].CODE === filterArray[i].code) {
              additionalEncounter.push(data["encounters"].data[index]);
              encountersArray.push(data["encounters"].data[index]);
            }
          }
        }
      }

      //start filtering
      for (const key in data) {
        if (key === "patient") {
          filterData[key] = data[key];
        } else if (key === "encounters") {
          filterData[key] = {};
        } else {
          var filteredItems = [];

          for (let i = 0; i < data[key].data.length; i++) {
            var isOk = false;
            var isFromFilteredOutEncounter = undefined;

            if (this.state.includeAllDataOfEncounter) {
              //check if data is in encounter that should be included
              isFromFilteredOutEncounter = encountersArray.find(
                (o) => o.Id === data[key].data[i].ENCOUNTER
              );
            }

            if (
              isFromFilteredOutEncounter !== undefined &&
              this.state.includeAllDataOfEncounter
            ) {
              isOk = true;
            } else {
              for (let index = 0; index < filterArray.length; index++) {
                if (data[key].data[i].CODE === filterArray[index].code) {
                  isOk = true;
                  //add encounter of data to encounterArray
                  var encounterOfData = data["encounters"].data.find(
                    (o) => o.Id === data[key].data[i].ENCOUNTER
                  );

                  additionalEncounter.push(encounterOfData);
                }
              }
            }
            if (isOk) {
              filteredItems.push(data[key].data[i]);
            }
          }
          filterData[key] = { typ: key, data: filteredItems };
        }
      }

      filterData.encounters = { typ: "encounters", data: additionalEncounter };
    } else {
      filterData = data;
    }

    return filterData;
  }

  filterForDate(data, filterArray) {
    let filterData = {};
    for (const key in data) {
      if (key === "patient") {
        filterData[key] = data[key];
      } else {
        var filteredItems = [];

        for (let i = 0; i < data[key].data.length; i++) {
          if (data[key].data[i] !== undefined) {
            var dateOfData;
            if (
              key === "observations" ||
              key === "imaging_studies" ||
              key === "immunizations"
            ) {
              dateOfData = new Date(data[key].data[i].DATE);
            } else {
              dateOfData = new Date(data[key].data[i].START);
            }

            if (
              dateOfData.getFullYear() >= filterArray[0] &&
              dateOfData.getFullYear() <= filterArray[1]
            ) {
              filteredItems.push(data[key].data[i]);
            }
          }
        }

        filterData[key] = { typ: key, data: filteredItems };
      }
    }

    return filterData;
  }

  allLoaded(allLoadedObj) {
    //checks if all entrys of obj are true
    var allLoaded = true;
    for (const key in allLoadedObj) {
      if (!allLoadedObj[key]) {
        allLoaded = false;
      }
    }

    return allLoaded;
  }
  /*-------------------------------------------- filtersaver functions ------------------------------------------------------------------------------------------------------------ */

  prepareFilterSave() {
    //prepare relevant filter info for the FilterSaver Component
    var filterData = {
      includeOArray: this.state.includeOArray,
      includeAllDataOfEncounter: this.state.includeAllDataOfEncounter,
      excludeArray: this.state.excludeArray,
    };

    return filterData;
  }

  changeToChangedProfile(filterData) {
    this.setState(
      { filterProfileLoaded: false, watchForFilterChanges: false },
      () =>
        this.setState(
          {
            includeOArray: filterData.data.includeOArray,
            includeAllDataOfEncounter:
              filterData.data.includeAllDataOfEncounter,
            excludeArray: filterData.data.excludeArray,
            sortBy: filterData.data.sortBy,
          },
          () =>
            this.setState({
              filterProfileLoaded: true,
              watchForFilterChanges: true,
            })
        )
    );
  }

  changeFromFilterProfile() {
    this.setState({ changedFromFilterProfile2: false });
  }

  filterFunction = () => {
    if (this.allLoaded(this.props.allPatDatLoaded)) {
      this.props.setDisplayDataProcessed(false);
      this.props.setPatViewFilter(this.state); //saves state in App component
      const displayedData = this.createDisplayData();
      this.props.updateDisplayedData(
        this.filterForDate(displayedData, this.props.sliderValue)
      );
    }
  };

  initialFilter = () => {
    //filter without silder -> slider reset vor very pat
    //silder value before filter starts -> wrong display data because dates from prev pat used
    if (this.allLoaded(this.props.allPatDatLoaded)) {
      this.props.setDisplayDataProcessed(false);
      this.props.setPatViewFilter(this.state); //saves state in App component
      const displayedData = this.createDisplayData();
      this.props.updateDisplayedData(displayedData);
    }
  };

  createDisplayData = () => {
    return this.excludeFilter(
      this.includeOArray(this.props.openPatientData, this.state.includeOArray),
      this.state.excludeArray
    );
  };
  /*-------------------------------------------- React functions ------------------------------------------------------------------------------------------------------------ */

  componentDidUpdate(prevProps, prevState, snapshot) {
    // update displayedData after excludeArray was changed
    if (prevState.excludeArray !== this.state.excludeArray) {
      this.filterFunction();
    }

    // update displayedData after includeArray was changed
    if (prevState.includeOArray !== this.state.includeOArray) {
      this.filterFunction();
    }

    //triggers new set include if include all data in encounter is activated
    if (
      prevState.includeAllDataOfEncounter !==
      this.state.includeAllDataOfEncounter
    ) {
      this.filterFunction();
    }

    //filters data considering the time
    if (prevProps.sliderValue !== this.props.sliderValue) {
      if (this.state.filterOnSliderChange) {
        this.filterFunction();
      } else {
        this.setState({ filterOnSliderChange: true });
      }
    }

    //tells FilterSaver if Filter settings are changed from the set profile
    if (this.state.watchForFilterChanges) {
      if (
        prevState.excludeArray !== this.state.excludeArray ||
        prevState.includeOArray !== this.state.includeOArray ||
        prevState.includeAllDataOfEncounter !==
          this.state.includeAllDataOfEncounter ||
        prevState.sliderValue !== this.state.sliderValue
      ) {
        this.setState({
          watchForFilterChanges: false,
          changedFromFilterProfile2: true,
        });
      }
    }

    //triggers if the open patient is changed and all data is loaded
    if (prevProps.allPatDatLoaded !== this.props.allPatDatLoaded) {
      if (this.state.filterOnSliderChange) {
        this.setState({ filterOnSliderChange: false });
      }
      this.initialFilter();
    }
  }

  componentDidMount() {
    if (Object.keys(this.props.patViewFilter).length !== 0) {
      this.setState(this.props.patViewFilter); //if patview is closed and reopend sets filter to pervious filter
    } else {
      if (
        this.allLoaded(this.props.allPatDatLoaded) &&
        Object.keys(this.props.allPatDatLoaded).length !== 0
      ) {
        this.initialFilter();
      }
    }
  }

  render() {
    const { isCollapsedI } = this.state;
    const { isCollapsedE } = this.state;
    return (
      <div className="filter-div">
        <div className="filter-head">
          <h3 className="h3-filter">Filter:</h3>
          <FilterSaver
            filterData={this.prepareFilterSave()}
            typ="patientView"
            changeFilterFunction={this.changeToChangedProfile}
            changedFromFilterProfile={this.state.changedFromFilterProfile2}
            changeFromFilterProfile={this.changeFromFilterProfile}
            newSnackbar={this.props.newSnackbar}
          />
        </div>
        <div className="filter-body">
          <div className="include-div">
            <h6
              style={{
                paddingTop: "8px",
                fontSize: "15px",
                fontWeight: "400",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              Include
            </h6>
            <div className="include-div-body">
              {this.state.includeOArray.map((array, i) => (
                <Tooltip
                  title={array.description}
                  arrow
                  key={"tooltipIn" + i + array.code}
                >
                  <Chip
                    label={array.code}
                    onDelete={() =>
                      this.removeFromFilterArray(
                        this.state.includeOArray,
                        "includeOArray",
                        array
                      )
                    }
                    sx={{
                      backgroundColor: "var(--secondary-color)",
                      color: "white",
                      marginRight: "5px",
                      marginBottom: "5px",
                      ".MuiChip-label": { fontSize: "12px" },
                      ".MuiChip-deleteIcon": {
                        color: "rgba(255, 255, 255, 0.829)",
                      },
                    }}
                    key={"chipIn" + i + array.code}
                  />
                </Tooltip>
              ))}
              {!this.state.isCollapsedI ? (
                <></>
              ) : (
                <IconButton
                  aria-label="add"
                  onClick={this.handleToggleCollapseI}
                >
                  <AddCircleIcon />
                </IconButton>
              )}
              <Collapse orientation="horizontal" in={!isCollapsedI}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={null}
                  options={this.props.autocompleteArray}
                  sx={{
                    width: "12vw",
                    ".MuiAutocomplete-input": { padding: 0 },
                    marginTop: "3px",
                  }}
                  onChange={(event, newValue) => {
                    this.addToFilterArray(newValue, "includeOArray");
                  }}
                  name="include"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      label="Include"
                      size="small"
                      sx={{
                        ".MuiInputBase-input": { padding: 0, fontSize: "14px" },
                      }}
                    ></TextField>
                  )}
                />
              </Collapse>
              {!this.state.isCollapsedI ? (
                <IconButton
                  aria-label="add"
                  onClick={this.handleToggleCollapseI}
                  sx={{ height: "30px", width: "30px", marginLeft: "5px" }}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "5px",
                marginLeft: "8px",
              }}
            >
              <div style={{ fontSize: "12px" }}>include by reason:</div>
              <div style={{ display: "flex" }}>
                <Collapse
                  orientation="horizontal"
                  in={this.state.collapseInReasons}
                >
                  <div
                    style={{
                      display: "flex",
                      fontSize: "12px",
                      alignItems: "center",
                      marginTop: "0.2vw",
                    }}
                  >
                    <p style={{ whiteSpace: "nowrap" }}>
                      {this.state.valueSelect === ""
                        ? "none"
                        : this.state.valueSelect}
                    </p>
                    <IconButton
                      aria-label="openInput"
                      onClick={this.handleToggleCollapseInput}
                      style={{ height: "25px", width: "25px" }}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </div>
                </Collapse>
                <Collapse
                  orientation="horizontal"
                  in={!this.state.collapseInReasons}
                >
                  <FormControl fullWidth>
                    <Select
                      id="include-by-reason"
                      value={this.state.valueSelect}
                      open={this.state.open} // Öffne das Dropdown-Menü basierend auf dem Zustand
                      onClose={this.handleClose} // Schließe das Dropdown-Menü, wenn es geschlossen wird
                      onOpen={this.handleOpen}
                      onChange={(event) =>
                        this.handelSelect(event, this.mapFilterOptions())
                      }
                      onBlur={this.onBlur}
                      autoFocus
                      sx={{
                        marginTop: "4px",
                        height: "30px", // Ändere die Mindesthöhe nach Bedarf
                        fontSize: "12px",
                        width: "6.8vw",
                      }}
                    >
                      <MenuItem
                        value={""}
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        <em>None</em>
                      </MenuItem>
                      {this.state.includeReasons.map((item) => (
                        <MenuItem
                          value={item.name}
                          data-item={item.data}
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Collapse>
              </div>
            </div>
            <div className="switch-div">
              <div className="label-switch">include all data of encounter:</div>
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                onChange={this.changeCheckbox}
                checked={this.state.includeAllDataOfEncounter}
              />
            </div>
          </div>
          <div className="exclude-div">
            <div className="include-div-head">
              <h6>Exclude</h6>
            </div>
            <div className="include-div-body">
              {this.state.excludeArray.map((array, i) => (
                <Tooltip
                  title={array.description}
                  arrow
                  key={"tooltipEx" + i + array.code}
                >
                  <Chip
                    label={array.code}
                    onDelete={() =>
                      this.removeFromFilterArray(
                        this.state.excludeArray,
                        "excludeArray",
                        array
                      )
                    }
                    sx={{
                      backgroundColor: "var(--secondary-color)",
                      color: "white",
                      marginRight: "5px",
                      marginBottom: "5px",
                      ".MuiChip-label": { fontSize: "12px" },
                      ".MuiChip-deleteIcon": {
                        color: "rgba(255, 255, 255, 0.829)",
                      },
                    }}
                    key={"chipEx" + i + array.code}
                  />
                </Tooltip>
              ))}
              {!this.state.isCollapsedE ? (
                <></>
              ) : (
                <IconButton
                  aria-label="add"
                  onClick={this.handleToggleCollapseE}
                >
                  <AddCircleIcon />
                </IconButton>
              )}
              <Collapse orientation="horizontal" in={!isCollapsedE}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={null}
                  options={this.props.autocompleteArray}
                  sx={{
                    width: "12vw",
                    ".MuiAutocomplete-input": { padding: 0 },
                    marginTop: "3px",
                  }}
                  onChange={(event, newValue) => {
                    this.addToFilterArray(newValue, "excludeArray");
                  }}
                  name="include"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      label="Include"
                      size="small"
                      sx={{
                        ".MuiInputBase-input": { padding: 0, fontSize: "14px" },
                      }}
                    ></TextField>
                  )}
                />
              </Collapse>
              {!this.state.isCollapsedE ? (
                <IconButton
                  aria-label="add"
                  onClick={this.handleToggleCollapseE}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
