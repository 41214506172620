import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import BasicBreadcrumbs from "./Head/Breadcrumbs";

import Header from "./Head/Header";

import "./SyntheaView.css";

export default class SyntheaView extends Component {
  render() {
    return (
      <div className="synthea-view-main">
        <Header
          location={this.props.location}
          openDB={this.props.openDB}
          nextPat={this.props.nextPatient}
          prevPat={this.props.prevPatient}
          collapsesSave={this.props.collapsesSave}
          openPatient={this.props.openPatient}
          navigate={this.props.navigate}
          openSettings={this.props.openSettings}
          sHeaderDisplay={this.props.sHeaderDisplay}
        />
        <BasicBreadcrumbs
          location={this.props.location}
          patientInfoVisible={this.props.patientInfoVisible}
          openPatientId={this.props.openPatientId}
          size={this.props.size}
          loadingTimePatientDuration={this.props.loadingTimePatientDuration}
          loadingMessagePatView={this.props.loadingMessagePatView}
          loadingTimePatOverviewDuration={
            this.props.loadingTimePatOverviewDuration
          }
          loadingMessagePatOverview={this.props.loadingMessagePatOverview}
        />
        <Outlet />
      </div>
    );
  }
}
