import { cn } from "../utils/cn";
import React from "react";

export const BentoGrid = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "grid md:auto-rows-[9vh] grid-cols-1 md:grid-cols-8 gap-4 w-full mx-auto ",
        className
      )}
    >
      {children}
    </div>
  );
};

export const BentoGridItem = ({
  className,
  title,
  description,
  header,
  icon,
  id,
}: {
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  id?: string;
  dbName?: string;
}) => {
  if (id === "dataset") {
    return (
      <div
        className={cn(
          "cursor-pointer rounded-xl group/bento h-full hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white border border-transparent justify-between  items-center flex",
          className
        )}
      >
        <div className="group-hover/bento:translate-x-2 transition duration-200 mr-6">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {icon}
            <div
              className="ml-2"
              style={{ textAlign: "left", color: "var(--primary-color)" }}
            >
              <div className="font-sans font-bold  dark:text-neutral-200">
                {title}
              </div>
              <div className="font-sans font-normal  text-xs dark:text-neutral-300">
                {description}
              </div>
            </div>
          </div>
        </div>
        {header}
      </div>
    );
  } else {
    return (
      <div
        className={cn(
          "cursor-pointer rounded-xl group/bento h-full hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white border border-transparent justify-between flex flex-col space-y-4",
          className
        )}
      >
        {header}
        <div className="group-hover/bento:translate-x-2 transition duration-200">
          <div style={{ display: "flex", alignItems: "center" }}>
            {icon}
            <div
              className="ml-2"
              style={{ textAlign: "left", color: "var(--primary-color)" }}
            >
              <div className="font-sans font-bold  dark:text-neutral-200 mt-2">
                {title}
              </div>
              <div className="font-sans font-normal  text-xs dark:text-neutral-300">
                {description}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
