// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs-div {
  height: 1vh;
  margin-top: 0.5vh;
  padding-left: 20px;
  padding-right: 2vw;
  display: flex;
  justify-content: space-between;
}

.prev-next-div {
  display: flex;
  justify-content: space-between;
  color: #00000099;
}

.spacer-bread {
  width: 20px;
}

.arrow-left-span {
  margin-right: 10px;
}

.arrow-right-span {
  margin-left: 10px;
}

.click-left-right:hover {
  color: #aabcf2;
  cursor: pointer;
}

.name-bread {
  color: #00000099;
}
`, "",{"version":3,"sources":["webpack://./src/Head/Breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAGlB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".breadcrumbs-div {\n  height: 1vh;\n  margin-top: 0.5vh;\n  padding-left: 20px;\n  display: flex;\n  justify-content: space-between;\n  padding-right: 2vw;\n  display: flex;\n  justify-content: space-between;\n}\n\n.prev-next-div {\n  display: flex;\n  justify-content: space-between;\n  color: #00000099;\n}\n\n.spacer-bread {\n  width: 20px;\n}\n\n.arrow-left-span {\n  margin-right: 10px;\n}\n\n.arrow-right-span {\n  margin-left: 10px;\n}\n\n.click-left-right:hover {\n  color: #aabcf2;\n  cursor: pointer;\n}\n\n.name-bread {\n  color: #00000099;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
