"use client";
import React, { useState } from "react";

import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";

import { cn } from "../utils/cn";

import "./floating-navbar.css";

interface NavItem {
  name: string;
  link?: string;
  linkIntern?: string;
  id?: string;
  typ: string;
  icon?: JSX.Element;
}
export const FloatingNav = ({
  navItems,
  className,
  navigate,
}: {
  navItems: {
    name: NavItem[];
    link?: string;
    linkIntern?: string;
    id?: string;
    typ: string;
    icon?: JSX.Element;
  }[];
  className?: string;
  location: string;
  navigate: (navItem: NavItem) => void;
}) => {
  const { scrollYProgress } = useScroll();

  const [visible, setVisible] = useState(false);

  useMotionValueEvent(scrollYProgress, "change", (current) => {
    // Check if current is not undefined and is a number
    if (typeof current === "number") {
      let direction = current! - scrollYProgress.getPrevious()!;

      if (scrollYProgress.get() < 0.05) {
        setVisible(false);
      } else {
        if (direction < 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    }
  });

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{
          opacity: 1,
          y: -100,
        }}
        animate={{
          y: visible ? 0 : -100,
          opacity: visible ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
        }}
        className={cn(
          "flex w-[100vw]  h-[5vh] fixed   inset-x-0 mx-auto dark:border-white/[0.2] bg-startColor shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] z-[5000] pr-5 pl-5 py-2  items-center justify-center space-x-6",
          className
        )}
      >
        {navItems.map((navItem: any, idx: number) => (
          <div
            key={`link=${idx}`}
            onClick={() => navigate(navItem)}
            className="navItem"
          >
            <span className="hidden sm:block text-sm">
              <span>{navItem.name}</span>
            </span>
          </div>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

/** exampele
 * 
 *  <FloatingNav
            navItems={[
              {
                name: "Data",
                link: "/synthea-viewer/patient-records/patient-view/patient-dataview",
              },
              {
                name: "History",
                link: "/synthea-viewer/patient-records/patient-view/patient-history",
              },
              {
                name: "Timeline",
                link: "/synthea-viewer/patient-records/patient-view/patient-timeline",
              },
              {
                name: "Charts",
                link: "/synthea-viewer/patient-records/patient-view/patient-charts",
              },
            ]}
            patName={
              this.props.openPatientData.patient.data.FIRST +
              " " +
              this.props.openPatientData.patient.data.LAST
            }
            collapseOpen={isCollapsedNav}
            prevPatientApp={this.prevPatientApp}
            nextPatientApp={this.nextPatientApp}
            location={this.props.location}
          />
 */
